const { floor, ceil, abs, round } = Math;

/**
 * For a given `value`, find the next number that is a multiple of `step`,
 * rounded in the specified `direction`.
 */
export function nextMultiple(
  value: number,
  direction: -1 | 1,
  step: number,

  /** How many decimals of precision to use */
  precision = 6,
): number {
  const precisionScale = 10 ** precision;
  const eps = 1 / precisionScale;

  const stepIndexFloat = value / step;
  const stepIndex =
    direction < 0 ? floor(stepIndexFloat) : ceil(stepIndexFloat);
  let stepValue = stepIndex * step;

  // Additionally move the result by one step
  // if it is close to the input value
  if (abs(value - stepValue) < eps) {
    stepValue += direction * step;
  }

  return round(stepValue * precisionScale) / precisionScale;
}
