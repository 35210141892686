import { MapElement } from '@cartken/map-types';

export function mergeAndValidateChanges(...changesList: MapElement[][]) {
  const map = new Map<number, MapElement>();
  for (const change of changesList.flat()) {
    if (change.id < 0 && change.deleted) {
      // if an element is not committed yet and is deleted,
      // we don't save it
      map.delete(change.id);
      continue;
    }
    map.set(change.id, change);
  }
  return [...map.values()];
}
