import { createBoundsFromCenterAndRadius } from '@/utils/geo-tools';
import { min } from '@/utils/min';
import { computed, effect, inject, Signal, signal } from '@angular/core';
import {
  EdgeElement,
  ElementType,
  isEdge,
  MapChangeset,
  MapElement,
} from '@cartken/map-types';
import { computeDistanceBetween, LatLngBounds } from 'spherical-geometry-js';
import { ChangeHistory } from './change-history';
import { remapReferencedIds } from './remap-referenced-ids';
import { computeAltitudeRange } from './altitude-range';
import { MatSnackBar } from '@angular/material/snack-bar';
import { asyncSignal } from '@/utils/async-signal';
import { mergeAndValidateChanges } from './merge-and-validate-changes';

class IdGenerator {
  private startId = -1;

  update(elements: MapElement[] | undefined) {
    if (!elements) {
      this.startId = -1;
      return;
    }
    const minimumId = min(elements.map((e) => e.id)) ?? 0;
    this.startId = Math.min(-1, minimumId - 1);
  }

  next(): number {
    return this.startId--;
  }
}

type LoadedMapElements = {
  mapVersion: number;
  mapElements: Map<number, MapElement>;
  bounds: LatLngBounds;
};

type LoadMapElementsFn = (
  bounds: LatLngBounds,
  mapVersion: number,
) => Promise<LoadedMapElements>;

function shouldRefetchBaseElements(
  mapVersion: number,
  requestedBounds: LatLngBounds,
  previous: LoadedMapElements | undefined,
) {
  if (!previous) {
    return true;
  }

  const radius = computeDistanceBetween(
    requestedBounds.getSouthWest(),
    requestedBounds.getNorthEast(),
  );
  const loadSmallMapElements = radius < 10000;
  if (!loadSmallMapElements) {
    // editor is too zoomed out
    return false;
  }

  if (
    mapVersion === previous?.mapVersion &&
    previous.bounds.contains(requestedBounds.getSouthWest()) &&
    previous.bounds.contains(requestedBounds.getNorthEast())
  ) {
    // map version did not change and bounds did not grow
    return false;
  }
  return true;
}

export class MapElementManager {
  private snackBar = inject(MatSnackBar);
  private elementIdGenerator = new IdGenerator();

  constructor(
    private latestMapVersion: Signal<number>,
    private bounds: Signal<LatLngBounds>,
    private showBaseMap: Signal<boolean>,
    private changeset: Signal<MapChangeset | undefined>,
    private hiddenElementTypes: Signal<Set<ElementType>>,
    private loadMapElementsInBounds: LoadMapElementsFn,
  ) {
    effect(
      () => {
        // when changeset changes, we want to
        // reset the elementIdGenerator and erase history
        const changeset = this.changeset();
        this.elementIdGenerator.update(changeset?.changedMapElements);
        this.history.clear();
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  readonly rebaseTargetVersion = signal<number | undefined>(undefined);
  readonly mapVersionIfNoChangeset = signal<number | undefined>(undefined);

  private baseMapElements = asyncSignal<LoadedMapElements>((prev) =>
    this.fetchBaseElements(
      this.rebaseTargetVersion() ??
        this.changeset()?.basedOnVersion ??
        this.mapVersionIfNoChangeset() ??
        this.latestMapVersion(),
      this.bounds(),
      prev,
    ),
  );

  readonly baseMapElementsVersion = computed(() => {
    const baseMapElements = this.baseMapElements();
    return baseMapElements.status === 'success'
      ? baseMapElements.value.mapVersion
      : undefined;
  });

  readonly loading = computed(
    () => this.baseMapElements().status === 'loading',
  );
  readonly loadingError = computed(() => this.baseMapElements().error);

  readonly mapElementsWithChangeset = computed(() => {
    const baseElements = this.baseMapElements().value?.mapElements;
    const changedMapElements = this.changeset()?.changedMapElements;
    if (!changedMapElements) {
      return baseElements;
    }

    const merged = new Map(baseElements);
    for (const value of changedMapElements) {
      merged.set(value.id, value);
    }
    return merged;
  });

  readonly altitudeRange = computed(() =>
    computeAltitudeRange(this.mapElementsWithChangeset()?.values() ?? []),
  );

  private historyLocked = () => {
    if (this.showBaseMap()) {
      this.snackBar.open("Can't edit while showing the base map.", undefined, {
        duration: 2000,
      });
      return true;
    }
    return false;
  };
  readonly history = new ChangeHistory<MapElement, number>(
    (el) => el.id,
    this.historyLocked,
  );
  private mapElementsWithLocalChanges = computed(() => {
    if (this.showBaseMap()) {
      return (
        this.baseMapElements().value?.mapElements ??
        new Map<number, MapElement>()
      );
    }
    const changedElements = new Map(this.mapElementsWithChangeset());
    for (const [key, value] of this.history.currentChanges()) {
      changedElements.set(key, value);
    }
    return changedElements;
  });

  readonly displayedMapElements = computed(() => {
    const hidden = this.hiddenElementTypes();
    const map = this.mapElementsWithLocalChanges();
    return [...map.values()].filter(
      (el) => !el.deleted && !hidden.has(el.elementType),
    );
  });

  readonly selectedMapElementId = signal<number | undefined>(undefined);
  readonly selectedMapElement = computed(() => {
    const id = this.selectedMapElementId();
    const el = id ? this.mapElementsWithLocalChanges().get(id) : undefined;
    return el?.deleted ? undefined : el;
  });

  readonly hoveredElementId = signal<number | undefined>(undefined);
  readonly hoveredElement = computed(() => {
    const id = this.hoveredElementId();
    const el = id ? this.mapElementsWithLocalChanges().get(id) : undefined;
    return el?.deleted ? undefined : el;
  });

  readonly infrastructure = computed(() =>
    [...this.mapElementsWithLocalChanges().values()].filter(
      (el) => el.elementType === 'Infrastructure',
    ),
  );

  readonly mutexes = computed(() =>
    [...this.mapElementsWithLocalChanges().values()].filter(
      (el) => el.elementType === 'Mutex',
    ),
  );

  private fetchBaseElements(
    mapVersion: number,
    requestedBounds: LatLngBounds,
    previous: LoadedMapElements | undefined,
  ) {
    if (!shouldRefetchBaseElements(mapVersion, requestedBounds, previous)) {
      // do not refetch
      return undefined;
    }
    const loadingRequestBounds = createBoundsFromCenterAndRadius(
      requestedBounds.getCenter(),
      10000,
    );
    return this.loadMapElementsInBounds(loadingRequestBounds, mapVersion);
  }

  importMapElements(mapElements: MapElement[], createNewMapElements: boolean) {
    const idMap = new Map<number, number>();
    for (const mapElement of mapElements) {
      if (createNewMapElements || mapElement.id < 0) {
        const newId = this.elementIdGenerator.next();
        idMap.set(mapElement.id, newId);
        mapElement.id = newId;
      }
    }
    for (const mapElement of mapElements) {
      remapReferencedIds(mapElement, idMap);
    }
    this.history.addChange(mapElements);
  }

  selectElementById(id: number | undefined) {
    this.selectedMapElementId.set(id);
  }

  getMapElements() {
    return this.mapElementsWithLocalChanges();
  }

  getMapElement(id: number) {
    return this.mapElementsWithLocalChanges().get(id);
  }

  generateMapElementId() {
    return this.elementIdGenerator.next();
  }

  connectedEdges(nodeId: number): EdgeElement[] {
    const connectedEdges: EdgeElement[] = [];
    for (const mapElement of this.mapElementsWithLocalChanges().values()) {
      if (
        !mapElement.deleted &&
        isEdge(mapElement) &&
        (mapElement.properties.startNodeId === nodeId ||
          mapElement.properties.endNodeId === nodeId)
      ) {
        connectedEdges.push(structuredClone(mapElement));
      }
    }
    return connectedEdges;
  }

  allChangesVsBaseMap() {
    return mergeAndValidateChanges(this.changeset()?.changedMapElements ?? [], [
      ...this.history.currentChanges().values(),
    ]);
  }
}
