import { Injectable } from '@angular/core';
import { Order } from '../order/order';
import { RobotCommunication } from './robot-communication';
import { Robot } from './backend/robot.dto';

export type RobotInformation = {
  robotState: Robot;
  orders: Order[];
};

@Injectable({
  providedIn: 'root',
})
export class RobotLastStateService {
  private robotsInformation = new Map<string, RobotInformation>();

  addNewRobot(robotCommunication: RobotCommunication) {
    const robotId = robotCommunication.robotId;
    robotCommunication.orders$.subscribe((orders) => {
      const robotInformation = this.robotsInformation.get(robotId);
      if (robotInformation === undefined) {
        return;
      }
      robotInformation.orders = orders;
    });

    robotCommunication.robotState$.subscribe((robotState) => {
      const robotInformation = this.robotsInformation.get(robotId);
      if (robotInformation === undefined) {
        this.robotsInformation.set(robotId, {
          robotState,
          orders: [],
        });
        return;
      }
      robotInformation.robotState = robotState;
    });

    robotCommunication.finalized$.subscribe(() => {
      this.robotsInformation.delete(robotId);
    });
  }

  getRobotInformation(robotId: string): RobotInformation | undefined {
    return this.robotsInformation.get(robotId);
  }
}
