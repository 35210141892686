import { GeoPoint } from '@cartken/map-types';
import { Geometry, PointCoordinates } from '../visualization/geojson-types';

function zOffsetPoint(
  [lng, lat, alt]: GeoPoint,
  zOffset: number,
): PointCoordinates {
  return [lng, lat, (alt ?? 0) + zOffset];
}

function zOffsetLineString<T extends GeoPoint[]>(
  coords: T,
  zOffset: number,
): T {
  return coords.map((c) => zOffsetPoint(c, zOffset)) as T;
}

function zOffsetLineStringArray<T extends GeoPoint[][]>(
  coords: T,
  zOffset: number,
): T {
  return coords.map((c) => zOffsetLineString(c, zOffset)) as T;
}

export function zOffsetGeometry(geometry: Geometry, zOffset: number): Geometry {
  switch (geometry.type) {
    case 'Point':
      return {
        type: 'Point',
        coordinates: zOffsetPoint(geometry.coordinates, zOffset),
      };

    case 'LineString':
      return {
        type: geometry.type,
        coordinates: zOffsetLineString(geometry.coordinates, zOffset),
      };
    case 'MultiPoint':
      return {
        type: geometry.type,
        coordinates: zOffsetLineString(geometry.coordinates, zOffset),
      };
    case 'MultiLineString':
      return {
        type: geometry.type,
        coordinates: zOffsetLineStringArray(geometry.coordinates, zOffset),
      };
    case 'Polygon':
      return {
        type: geometry.type,
        coordinates: zOffsetLineStringArray(geometry.coordinates, zOffset),
      };
    default:
      return geometry;
  }
}
