import { Component, input, output } from '@angular/core';
import { Pole } from '@cartken/map-types';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { produce } from 'immer';

@Component({
  selector: 'app-pole-properties',
  standalone: true,
  templateUrl: './pole-properties.component.html',
  imports: [FormsModule, MatLabel, MatInput, MatFormField],
  host: { style: 'display: contents' },
})
export class PolePropertiesComponent {
  pole = input.required<Pole>();
  poleChange = output<Pole>();

  setRadius(pole: Pole, radius: string) {
    this.poleChange.emit(
      produce(pole, (draft) => {
        draft.properties.radius = parseFloat(radius);
      }),
    );
  }
}
