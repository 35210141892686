import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogContent,
} from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { BackendService } from '../../../app/core/backend.service';
import { MapElement, RobotQueueEdge } from '@cartken/map-types';
import {
  parseId,
  getLatLng,
  parseLatLonString,
  parseLocationName,
} from './location-helpers';
import { getMidPoint, toGoogleLatLng } from '../../../utils/geo-tools';
import * as v from 'valibot';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

export interface ViewLocationDialogData {
  currentMapVersion?: number;
  nearbyQueueLocations: RobotQueueEdge[];
}

@Component({
  selector: 'app-view-location-dialog',
  templateUrl: './view-location-dialog.component.html',
  styleUrls: ['./view-location-dialog.component.sass'],
  standalone: true,
  imports: [
    CdkScrollable,
    MatDialogContent,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatIconButton,
    MatTooltip,
    MatIcon,
    MatButton,
  ],
})
export class ViewLocationDialogComponent implements AfterViewInit {
  @ViewChild('location') locationElement!: ElementRef;
  latLng?: google.maps.LatLng;
  locationAutocomplete!: google.maps.places.Autocomplete;
  nearbyLocations: { displayName: string; latLng: google.maps.LatLng }[];

  constructor(
    public dialogRef: MatDialogRef<
      ViewLocationDialogComponent,
      google.maps.LatLng
    >,
    @Inject(MAT_DIALOG_DATA) public data: ViewLocationDialogData,
    private backendService: BackendService,
  ) {
    const nearbyLocations = data.nearbyQueueLocations.map(
      ({ id, geometry, properties: { displayNames, names } }) => ({
        displayName: displayNames?.[0] ?? names?.[0] ?? String(id),
        latLng: toGoogleLatLng(
          getMidPoint(geometry.coordinates[0], geometry.coordinates[1]),
        ),
      }),
    );
    nearbyLocations.sort((a, b) => a.displayName.localeCompare(b.displayName));
    this.nearbyLocations = nearbyLocations;
  }

  ngAfterViewInit() {
    const options: google.maps.places.AutocompleteOptions = {};

    this.locationAutocomplete = new google.maps.places.Autocomplete(
      this.locationElement.nativeElement,
      options,
    );

    this.locationAutocomplete.addListener('place_changed', () => {
      this.updateGooglePlace();
    });

    fromEvent(this.locationElement.nativeElement, 'keyup')
      .pipe(
        map(() => this.locationElement.nativeElement.value),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(() => this.updatePlace());
  }

  private updatePlace() {
    const str = this.locationElement.nativeElement.value;

    const id = parseId(str);
    if (id) {
      this.backendService
        .get(`/map/history?ids=${id}&version=${this.data.currentMapVersion}`)
        .pipe(map((x) => v.parse(v.array(MapElement), x)))
        .subscribe((data) => {
          if (data[0]) {
            this.latLng = getLatLng(data[0]);
          }
        });
      return;
    }

    const locationLatLong = parseLatLonString(str);
    if (locationLatLong) {
      this.latLng = locationLatLong;
      return;
    }

    const locationName = parseLocationName(str);
    if (locationName) {
      this.backendService
        .get(
          `/map/history?location-names=${locationName}&version=${this.data.currentMapVersion}`,
        )
        .pipe(map((x) => v.parse(v.array(MapElement), x)))
        .subscribe((data) => {
          if (data[0]) {
            this.latLng = getLatLng(data[0]);
          }
        });
    }
  }

  private updateGooglePlace() {
    const googlePlace = this.locationAutocomplete.getPlace();
    const googleLocation = googlePlace?.geometry?.location;
    if (googleLocation) {
      this.latLng = new google.maps.LatLng(
        googleLocation.lat(),
        googleLocation.lng(),
      );
      return;
    }
    this.latLng = undefined;
  }

  goToLocation(latLng?: google.maps.LatLng) {
    if (!latLng && !this.latLng) {
      return;
    }
    this.dialogRef.close(latLng ?? this.latLng);
  }
}
