import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { animationFrameScheduler, Subject, fromEvent, timer } from 'rxjs';
import { repeat, takeUntil } from 'rxjs/operators';

import {
  ManualRobotControl,
  RobotConnectionStats,
} from '@/app/core/robots-service/webrtc/types';
import { RobotCanvas } from './robot-canvas';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { vec2 } from '@tlaukkan/tsm';
import {
  MouseCanvasDriveEventManager,
  MouseDriveEvent,
} from '../common/mouse-canvas-events';
import { RobotSystemStatus } from '@/app/core/robots-service/backend/types';
import { RobotTextLayoutComponent } from './robot-text-layout.component';

export type PathCorridor = {
  action: 'add' | 'reset';
  groundPoint: [number, number];
  width: number;
};

@Component({
  selector: 'robot-video',
  templateUrl: './robot-video.component.html',
  styleUrls: ['./robot-video.component.sass'],
  standalone: true,
  imports: [RobotTextLayoutComponent],
})
export class RobotVideoComponent implements AfterViewInit, OnDestroy {
  @Input()
  batteryPercentage? = 100;

  @Input()
  isCharging? = false;

  @Input()
  active = false;

  @Input()
  manualMouseControl? = false;

  @Input()
  robotStateReport?: string;

  @Input()
  supervisionState?: string;

  @Input()
  operationEmergencyStopActive?: boolean;

  @Input()
  attentionStatusList: RobotSystemStatus[] = [];

  @Input()
  statusStrings?: string[];

  @Input()
  manualControl?: ManualRobotControl;

  @Input()
  robotState?: Robot;

  @Input()
  connectionStats?: RobotConnectionStats;

  @Input()
  robotAttentionReport?: string;

  @Input()
  video?: HTMLVideoElement;

  @Input()
  userClaimReport?: string;

  @Output() mouseDriveEvent = new EventEmitter<MouseDriveEvent>();
  @Output() lastMouseRelativePosition = new EventEmitter<vec2>();

  @Output()
  onStatusClick: EventEmitter<string> = new EventEmitter();

  @ViewChild('canvas') private canvasElement!: ElementRef;

  private readonly destroy$ = new Subject<void>();

  private lastVideoFrameTime = 0;
  private lastRenderingFrameTimeMs = 0;
  private mouseCanvasDriveEvent!: MouseCanvasDriveEventManager;

  private robotCanvas!: RobotCanvas;

  private initHelpers() {
    this.robotCanvas = new RobotCanvas(this.canvasElement.nativeElement);
    this.mouseCanvasDriveEvent = new MouseCanvasDriveEventManager(
      this.canvasElement.nativeElement,
      this.mouseDriveEvent,
    );
  }

  private subscribeToEvents() {
    fromEvent<MouseEvent>(document, 'mousemove')
      .pipe(takeUntil(this.destroy$))
      .subscribe((mouseEvent) => {
        const boundingRect =
          this.canvasElement.nativeElement.getBoundingClientRect();

        this.lastMouseRelativePosition.emit(
          new vec2([
            (mouseEvent.clientX - boundingRect.x) / boundingRect.width,
            (mouseEvent.clientY - boundingRect.y) / boundingRect.height,
          ]),
        );
      });
  }

  private startRenderingLoop() {
    timer(0, animationFrameScheduler)
      .pipe(repeat(), takeUntil(this.destroy$))
      .subscribe(() => {
        const name = `Cart ${this.robotState?.serialNumber}`;
        const attentionStatuses = this.attentionStatusList
          ? [...this.attentionStatusList]
          : [];
        if (this.robotAttentionReport) {
          attentionStatuses.push({
            name,
            message: this.robotAttentionReport,
          });
        }
        this.updateCanvas(this.manualControl);
      });
  }

  ngAfterViewInit() {
    this.initHelpers();
    this.subscribeToEvents();
    this.startRenderingLoop();
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
    this.mouseCanvasDriveEvent.destroy();
  }

  private updateCanvas(manualControl?: ManualRobotControl) {
    if (!this.video) {
      return;
    }

    const durationSinceLastFrame =
      this.video.currentTime - this.lastVideoFrameTime;
    const hasNewVideoFrame = durationSinceLastFrame > 0;
    const currentTimeMs = Date.now();

    // Always render when a new video frame arrived but maintain a minimum framerate of 10fps.
    if (
      !hasNewVideoFrame &&
      currentTimeMs - this.lastRenderingFrameTimeMs < 100
    ) {
      this.lastVideoFrameTime = this.video.currentTime;
      return;
    }

    this.robotCanvas.draw(
      this.active,
      this.manualMouseControl ?? false,
      this.video,
      this.mouseCanvasDriveEvent.mouseMove,
      manualControl,
    );
    this.lastRenderingFrameTimeMs = currentTimeMs;
  }
}
