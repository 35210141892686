import { MapElement } from '@cartken/map-types';

const { min, max, floor, ceil } = Math;

export function computeAltitudeRange(mapElements: Iterable<MapElement>) {
  const slippyTiles = [];
  const localizationMapElements = [];
  const interactiveMapElements = [];
  for (const element of mapElements) {
    if (element.deleted) {
      continue;
    }
    if (element.elementType === 'SlippyTiles') {
      slippyTiles.push(element);
    } else if (element.elementType === 'LocalizationMapTiles') {
      localizationMapElements.push(element);
    } else {
      interactiveMapElements.push(element);
    }
  }

  let minAltitude = Infinity;
  let maxAltitude = -Infinity;
  const updateMinMaxAltitude = (altitude?: number) => {
    if (altitude) {
      minAltitude = min(minAltitude, altitude);
      maxAltitude = max(maxAltitude, altitude);
    }
  };
  for (const m of interactiveMapElements) {
    switch (m.geometry.type) {
      case 'Point':
        updateMinMaxAltitude(m.geometry.coordinates[2]);
        break;
      case 'LineString':
        for (const c of m.geometry.coordinates) {
          updateMinMaxAltitude(c[2]);
        }
        break;
      case 'Polygon':
        for (const p of m.geometry.coordinates) {
          for (const c of p) {
            updateMinMaxAltitude(c[2]);
          }
        }
        break;
    }
  }
  for (const m of localizationMapElements) {
    updateMinMaxAltitude(m.properties.minAltitude);
    updateMinMaxAltitude(m.properties.maxAltitude);
  }
  if (!isFinite(minAltitude)) {
    minAltitude = 0;
  }
  if (!isFinite(maxAltitude)) {
    maxAltitude = minAltitude;
  }
  return {
    min: floor(minAltitude),
    max: ceil(maxAltitude + 1),
  };
}
