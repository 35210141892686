import {
  effect,
  EffectCleanupRegisterFn,
  signal,
  Signal,
  untracked,
} from '@angular/core';

export type Result<T> =
  | { status: 'idle'; value?: never; error?: never }
  | { status: 'loading'; value?: never; error?: never }
  | { status: 'success'; value: T; error?: never }
  | { status: 'error'; value?: never; error: Error };

export function asyncSignal<R>(
  fetcher: (
    previous: R | undefined,
    onCleanup: EffectCleanupRegisterFn,
  ) => Promise<R> | undefined,
): Signal<Result<R>> {
  const result = signal<Result<R>>({ status: 'idle' });
  effect(
    (onCleanup) => {
      const prev = untracked(result);
      const promise = fetcher(prev.value, onCleanup);
      if (!promise) {
        return;
      }
      result.set({ status: 'loading' });
      promise
        .then((value) => result.set({ status: 'success', value }))
        .catch((error) => result.set({ status: 'error', error }));
    },
    {
      allowSignalWrites: true,
    },
  );
  return result;
}
