import {
  recursivelyTraverseNestedArrays,
  nearestPointOnProjectedLine,
  getPickedEditHandle,
  getPickedExistingEditHandle,
  getPickedIntermediateEditHandle,
  NearestPointType,
  getHandlesForPick,
  getUnderlyingFeaturePick,
} from '../visualization/utils';
import {
  ModeProps,
  ClickEvent,
  StartDraggingEvent,
  StopDraggingEvent,
  DraggingEvent,
  GuideFeatureCollection,
  EditHandleFeature,
  Pick,
  Color,
} from '../visualization/types';
import { MapElementManager } from '../map-elements/map-element-manager';
import { InteractiveMode } from '../visualization/interactive-mode';
import { addPosition, replacePosition } from './geometry-manipulation-utils';
import { GeoPoint, MapElement } from '@cartken/map-types';
import { Matrix4 } from '@math.gl/core';
import { WebMercatorViewport } from '@deck.gl/core';
import type { ViewContainerRef } from '@angular/core';
import { PropertiesContainerComponent } from '../properties/properties-container.component';
import {
  getLineColor,
  HIGHLIGHT_COLOR,
} from '../visualization/visualization-styles';
import { produce } from 'immer';
import { generateChange } from '../map-elements/generate-change';
import { ChangePreview } from '../map-elements/change-history';

export type SelectAndEditModeConfig = {
  viewport: WebMercatorViewport;
  modelMatrix: Matrix4;
};

export class SelectAndEditMode extends InteractiveMode<SelectAndEditModeConfig> {
  private changePreview: ChangePreview<MapElement> | undefined;

  constructor(private readonly mapElementManager: MapElementManager) {
    super();
  }

  override shouldRenderSidebar(): boolean {
    return this.mapElementManager.selectedMapElement() !== undefined;
  }

  override renderSidebar(ref: ViewContainerRef) {
    const componentRef = ref.createComponent(PropertiesContainerComponent);
    componentRef.instance.mapElementManager = this.mapElementManager;
    return componentRef;
  }

  override getCursor(): string {
    return this.mapElementManager.hoveredElement() ? 'pointer' : 'grab';
  }

  override getMapElementColor(m: MapElement): Color {
    if (this.mapElementManager.hoveredElementId() === m.id) {
      return HIGHLIGHT_COLOR;
    }
    return getLineColor(
      m,
      this.mapElementManager.selectedMapElementId() === m.id,
    );
  }

  override getGuides(
    props: ModeProps<SelectAndEditModeConfig>,
  ): GuideFeatureCollection {
    const hoveredPick = getUnderlyingFeaturePick(
      props.lastHoverEvent?.picks[0],
      props,
    );
    // don't show intermediate handle if change is in progress
    const intermediateHandles = this.changePreview
      ? []
      : this.generateIntermediateHandles(props);
    return {
      type: 'FeatureCollection',
      features: [
        // order important, we want the intermediate handle
        // below the true handles
        ...intermediateHandles,
        ...getHandlesForPick(hoveredPick),
      ],
    };
  }

  private generateIntermediateHandles(
    props: ModeProps<SelectAndEditModeConfig>,
  ): EditHandleFeature[] {
    const { lastHoverEvent } = props;
    const picks = lastHoverEvent?.picks;
    const screenCoords = lastHoverEvent?.screenCoords;

    // don't show intermediate point when too close to an existing edit handle
    if (!picks?.length || !screenCoords || getPickedExistingEditHandle(picks)) {
      return [];
    }

    const featureAsPick = picks.find((pick) => !pick.isGuide);
    const featureGeometry = featureAsPick?.object?.geometry;
    if (
      !featureGeometry ||
      featureGeometry.type === 'Point' ||
      featureGeometry.type === 'MultiPoint' ||
      this.mapElementManager.selectedMapElementId() !== featureAsPick.object.id
    ) {
      return [];
    }

    const handles: EditHandleFeature[] = [];
    let intermediatePoint: NearestPointType | null | undefined = null;
    let positionIndexPrefix: number[] = [];
    // process all lines of the (single) feature
    recursivelyTraverseNestedArrays(
      featureAsPick.object.geometry.coordinates,
      [],
      (lineString: GeoPoint[], prefix: number[]) => {
        const candidateIntermediatePoint = nearestPointOnProjectedLine(
          lineString,
          screenCoords,
          props.modeConfig.viewport,
          props.modeConfig.modelMatrix,
        );
        if (
          !intermediatePoint ||
          candidateIntermediatePoint.properties.dist <
            intermediatePoint.properties.dist
        ) {
          intermediatePoint = candidateIntermediatePoint;
          positionIndexPrefix = prefix;
        }
      },
    );
    // tack on the lone intermediate point to the set of handles
    if (intermediatePoint) {
      const {
        geometry: { coordinates: position },
        properties: { index },
      } = intermediatePoint;
      handles.push({
        type: 'Feature',
        properties: {
          guideType: 'editHandle',
          editHandleType: 'intermediate',
          featureIndex: featureAsPick.index,
          elementId: featureAsPick.object?.id,
          positionIndexes: [...positionIndexPrefix, index + 1],
        },
        geometry: {
          type: 'Point',
          coordinates: position,
        },
      });
    }
    return handles;
  }

  private createVertexForIntermediateHandle(handle: EditHandleFeature) {
    const { elementId, positionIndexes } = handle.properties;
    if (elementId === undefined) {
      return;
    }
    const element = this.mapElementManager.getMapElement(elementId);
    const coords = handle.geometry.coordinates;
    if (!element || !coords || !positionIndexes) {
      return;
    }
    const newElement = produce(element, (draft) => {
      addPosition(draft.geometry, positionIndexes, coords);
    });
    this.changePreview?.updatePreview([newElement]);
  }

  override onLeftClick(event: ClickEvent, props: ModeProps) {
    const intermediateHandle = getPickedIntermediateEditHandle(event.picks);
    if (intermediateHandle) {
      this.changePreview = this.mapElementManager.history.startChangePreview();
      if (!this.changePreview) {
        return;
      }
      this.createVertexForIntermediateHandle(intermediateHandle);
      this.changePreview.commit();
      this.changePreview = undefined;
    } else {
      const pick = getUnderlyingFeaturePick(event.picks[0], props);
      this.mapElementManager.selectElementById(pick?.object.id);
    }
  }

  private dragEditHandle(
    editHandle: EditHandleFeature,
    event: StopDraggingEvent | DraggingEvent,
  ) {
    const { positionIndexes, elementId } = editHandle.properties;
    if (!this.changePreview || !positionIndexes || !elementId) {
      return;
    }
    const element = this.mapElementManager.getMapElement(elementId);
    if (!element) {
      return;
    }
    const newElement = produce(element, (draft) => {
      replacePosition(draft.geometry, positionIndexes, event.mapCoords);
    });
    this.changePreview.updatePreview([newElement]);
  }

  private dragPointFeature(
    pick: Pick,
    event: StopDraggingEvent | DraggingEvent,
  ) {
    const element = this.mapElementManager.getMapElement(pick.object?.id);
    if (!element) {
      return;
    }
    const newElement = produce(element, (draft) => {
      draft.geometry.coordinates = event.mapCoords;
    });

    generateChange(this.mapElementManager, newElement)
      .then((change) => {
        this.changePreview?.updatePreview([newElement, ...change]);
      })
      .catch(console.error);
  }

  override onDragStart(event: StartDraggingEvent, props: ModeProps) {
    if (event.picks.length > 0) {
      this.changePreview = this.mapElementManager.history.startChangePreview();
    }
    // add a new vertex if the clicked feature is an intermediate edit handle
    // this happens if the user directly drags the intermediate handle
    const intermediateHandle = getPickedIntermediateEditHandle(event.picks);
    if (intermediateHandle) {
      this.createVertexForIntermediateHandle(intermediateHandle);
    }
  }

  override onDrag(event: DraggingEvent, props: ModeProps): void {
    const pick = event.pointerDownPicks?.[0];
    if (!pick) {
      return;
    }
    const editHandle = getPickedEditHandle([pick]);
    if (editHandle) {
      this.dragEditHandle(editHandle, event);
    } else if (pick.object?.geometry?.type === 'Point') {
      this.dragPointFeature(pick, event);
    }
  }

  override onDragEnd(event: StopDraggingEvent, props: ModeProps) {
    const pick = event.pointerDownPicks?.[0];
    if (!pick || !this.changePreview) {
      return;
    }
    const editHandle = getPickedEditHandle([pick]);
    if (editHandle) {
      this.dragEditHandle(editHandle, event);
    } else if (pick.object?.geometry?.type === 'Point') {
      this.dragPointFeature(pick, event);
    }
    this.changePreview.commit();
    this.changePreview = undefined;
  }
}
