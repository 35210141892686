import { DecimalPipe } from '@angular/common';
import { Component, input, signal } from '@angular/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import {
  CrossingType,
  EdgeElement,
  ElementType,
  InfrastructureEdge,
  InfrastructureWaitingEdge,
  MapElement,
  RobotEdge,
  RobotQueueEdge,
} from '@cartken/map-types';
import { PrintDatetime } from '@/app/core/print-datetime/print-datetime.component';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import * as v from 'valibot';
import { MatMiniFabButton } from '@angular/material/button';
import { produce } from 'immer';
import { ChangePreview } from '../map-elements/change-history';
import { MapElementManager } from '../map-elements/map-element-manager';
import { nextMultiple } from '@/utils/next-multiple';

const { max } = Math;

const edgeTypes = [
  {
    displayName: 'Robot Edge',
    elementType: ElementType.ROBOT_EDGE,
    selectable: true,
  },
  {
    displayName: 'Robot Queue Edge',
    elementType: ElementType.ROBOT_QUEUE_EDGE,
    selectable: true,
  },
  {
    displayName: 'Infrastructure Edge',
    elementType: ElementType.INFRASTRUCTURE_EDGE,
    selectable: true,
  },
  {
    displayName: 'Infrastructure Waiting Edge',
    elementType: ElementType.INFRASTRUCTURE_WAITING_EDGE,
    selectable: true,
  },
  {
    displayName: 'Movable Platfrom Edge',
    elementType: ElementType.MOVABLE_PLATFORM_EDGE,
    selectable: true,
  },
  {
    displayName: 'Road Edge',
    elementType: ElementType.ROAD_EDGE,
    selectable: true,
  },
  {
    displayName: 'Cached Road Edge',
    elementType: ElementType.CACHED_ROAD_EDGE,
    selectable: false,
  },
] as const;

const crossingTypes = [
  {
    displayName: 'Crosses Residential Road',
    value: CrossingType.RESIDENTIAL_ROAD,
  },
  {
    displayName: 'Crosses Single Lane Road',
    value: CrossingType.SINGLE_LANE_ROAD,
  },
  {
    displayName: 'Crosses Multi Lane Road',
    value: CrossingType.MULTI_LANE_ROAD,
  },
  { displayName: 'Crosses Bike Path', value: CrossingType.BIKE_PATH },
  { displayName: 'Crosses Driveway', value: CrossingType.DRIVEWAY },
  { displayName: 'Ascends/Descends Curb', value: CrossingType.CURB },
] as const;

type BlockedFor = {
  months?: number;
  days?: number;
  hours?: number;
};

@Component({
  selector: 'app-edge-properties',
  standalone: true,
  templateUrl: './edge-properties.component.html',
  styleUrls: ['./edge-properties.component.sass'],
  imports: [
    DecimalPipe,
    FormsModule,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatMiniFabButton,
    MatOption,
    MatSelect,
    MatSlideToggle,
    PrintDatetime,
  ],
})
export class EdgePropertiesComponent {
  mapElementManager = input.required<MapElementManager>();
  edge = input.required<EdgeElement>();
  edgeTypes = edgeTypes;
  crossingTypes = crossingTypes;
  blockedFor = signal<BlockedFor>({});

  robotQueueEdgeDisplayNameTextInput = '';
  robotQueueEdgeNameTextInput = '';

  private changePreview: ChangePreview<MapElement> | undefined;

  startPreview() {
    if (!this.changePreview) {
      this.changePreview =
        this.mapElementManager().history.startChangePreview();
    }
  }

  addChange(mapElement: EdgeElement) {
    if (this.changePreview) {
      this.changePreview.updatePreview([mapElement]);
    } else {
      this.mapElementManager().history.addChange([mapElement]);
    }
  }

  commit() {
    this.changePreview?.commit();
    this.changePreview = undefined;
  }

  changeElementType(edge: EdgeElement, elementType: string) {
    // Changing the elementType is destructive, some properties
    // should not exist anymore when switching, which is why we re-parse it here.
    const newEdge = v.parse(EdgeElement, { ...edge, elementType });
    this.addChange(newEdge);
  }

  setUnsupervisedAutonomy(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    allowUnsupervisedAutonomy: boolean,
  ) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.allowUnsupervisedAutonomy = allowUnsupervisedAutonomy;
      }),
    );
  }

  setRequiresHazardLights(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    requiresHazardLights: boolean,
  ) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.requiresHazardLights = requiresHazardLights;
      }),
    );
  }

  setOneway(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    oneway: boolean,
  ) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.oneway = oneway;
      }),
    );
  }

  updateBlockage(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    blocked: boolean,
  ) {
    this.addChange(
      produce(edge, (draft) => {
        if (blocked) {
          draft.properties.blockedAt = new Date();
        } else {
          delete draft.properties.blockedAt;
          delete draft.properties.blockedUntil;
        }
      }),
    );
  }

  setBlockedHours(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    hours: string,
  ) {
    this.blockedFor.update((p) => ({ ...p, hours: parseInt(hours) }));
    this.updateBlockedUntil(edge);
  }

  setBlockedDays(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    days: string,
  ) {
    this.blockedFor.update((p) => ({ ...p, days: parseInt(days) }));
    this.updateBlockedUntil(edge);
  }

  setBlockedMonths(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    months: string,
  ) {
    this.blockedFor.update((p) => ({ ...p, months: parseInt(months) }));
    this.updateBlockedUntil(edge);
  }

  updateBlockedUntil(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
  ) {
    const blockedAt = edge.properties.blockedAt ?? new Date();
    const blockedFor = this.blockedFor();
    this.addChange(
      produce(edge, (draft) => {
        if (blockedFor.months || blockedFor.days || blockedFor.hours) {
          const futureDate = new Date(blockedAt);
          futureDate.setMonth(futureDate.getMonth() + (blockedFor.months ?? 0));
          futureDate.setDate(futureDate.getDate() + (blockedFor.days ?? 0));
          futureDate.setHours(futureDate.getHours() + (blockedFor.hours ?? 0));
          draft.properties.blockedUntil = futureDate;
        } else {
          delete draft.properties.blockedUntil;
        }
      }),
    );
  }

  setSlowDownFactor(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    slowDownFactor: string,
  ) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.slowDownFactor = parseFloat(slowDownFactor);
      }),
    );
  }

  setMaxSpeed(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    maxSpeed: string,
  ) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.maxSpeed = parseFloat(maxSpeed);
      }),
    );
  }

  setStartWidth(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    startWidth: string,
  ) {
    this.startPreview();
    const startWidthNumber = parseFloat(startWidth);
    if (Number.isFinite(startWidthNumber)) {
      this.addChange(
        produce(edge, (draft) => {
          draft.properties.startWidth = max(0, startWidthNumber);
        }),
      );
    }
  }

  setStartWidthWithWheel(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    event: WheelEvent,
  ) {
    event.preventDefault();
    this.startPreview();
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.startWidth = max(
          0,
          nextMultiple(
            edge.properties.startWidth ?? 0,
            event.deltaY < 0 ? 1 : -1,
            0.1,
          ),
        );
      }),
    );
  }

  setEndWidth(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    endWidth: string,
  ) {
    this.startPreview();
    const endWidthNumber = parseFloat(endWidth);
    if (Number.isFinite(endWidthNumber)) {
      this.addChange(
        produce(edge, (draft) => {
          draft.properties.endWidth = max(0, endWidthNumber);
        }),
      );
    }
  }

  setEndWidthWithWheel(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    event: WheelEvent,
  ) {
    event.preventDefault();
    this.startPreview();
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.endWidth = max(
          0,
          nextMultiple(
            edge.properties.endWidth ?? 0,
            event.deltaY < 0 ? 1 : -1,
            0.1,
          ),
        );
      }),
    );
  }

  setCrossing(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    enabled: boolean,
  ) {
    this.addChange(
      produce(edge, (draft) => {
        if (enabled) {
          draft.properties.crossing = {
            crosses: CrossingType.DRIVEWAY,
            buttonPressRequired: false,
            trafficLightIds: [],
          };
        } else {
          delete draft.properties.crossing;
        }
      }),
    );
  }

  setCrossingType(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    crosses: CrossingType,
  ) {
    this.addChange(
      produce(edge, (draft) => {
        if (draft.properties.crossing) {
          draft.properties.crossing.crosses = crosses;
        }
      }),
    );
  }

  setButtonPressRequired(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    buttonPressRequired: boolean,
  ) {
    this.addChange(
      produce(edge, (draft) => {
        if (draft.properties.crossing) {
          draft.properties.crossing.buttonPressRequired = buttonPressRequired;
        }
      }),
    );
  }

  setMutexIds(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    mutexIds: number[],
  ) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.mutexIds = mutexIds;
      }),
    );
  }

  setInfrastructureId(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    infrastructureId: number,
  ) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.infrastructureId = infrastructureId;
      }),
    );
  }

  setInfrastructureLocationName(
    edge: RobotEdge | InfrastructureEdge | InfrastructureWaitingEdge,
    infrastructureLocationName: string,
  ) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.infrastructureLocationName =
          infrastructureLocationName;
      }),
    );
  }

  addQueueSlotPriority(edge: RobotQueueEdge) {
    const lastQueueSlotPriority =
      edge.properties.queueSlotPriorities?.at(-1) ?? 0;
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.queueSlotPriorities.push(lastQueueSlotPriority);
      }),
    );
  }

  setQueueSlotPriority(
    edge: RobotQueueEdge,
    index: number,
    priorityString: string,
  ) {
    const priority = parseFloat(priorityString);
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.queueSlotPriorities[index] = priority;
      }),
    );
  }

  removeQueueSlotPriority(edge: RobotQueueEdge, index: number) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.queueSlotPriorities.splice(index, 1);
      }),
    );
  }

  addQueueEdgeName(edge: RobotQueueEdge) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.names.push(this.robotQueueEdgeNameTextInput);
      }),
    );
  }

  removeQueueEdgeName(edge: RobotQueueEdge, index: number) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.names.splice(index, 1);
      }),
    );
  }

  addQueueEdgeDisplayName(edge: RobotQueueEdge) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.displayNames.push(
          this.robotQueueEdgeDisplayNameTextInput,
        );
      }),
    );
  }

  removeQueueEdgeDisplayName(edge: RobotQueueEdge, index: number) {
    this.addChange(
      produce(edge, (draft) => {
        draft.properties.displayNames.splice(index, 1);
      }),
    );
  }
}
