import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  input,
  output,
  viewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HandoverLocation } from '@cartken/map-types';
import { produce } from 'immer';

@Component({
  selector: 'app-handover-location-properties',
  standalone: true,
  templateUrl: './handover-location-properties.component.html',
  imports: [FormsModule, MatFormField, MatInput, MatLabel, MatSpinner],
  host: { style: 'display: contents' },
})
export class HandoverLocationPropertiesComponent implements AfterViewInit {
  private snackBar = inject(MatSnackBar);
  handoverLocation = input.required<HandoverLocation>();
  handoverLocationChange = output<HandoverLocation>();
  addressRef = viewChild<ElementRef<HTMLInputElement>>('address');

  lookingUpAddress = false;

  ngAfterViewInit(): void {
    const addressEl = this.addressRef()?.nativeElement;
    if (!addressEl) {
      throw new Error(`No address input element found.`);
    }
    const pickupAutocomplete = new google.maps.places.Autocomplete(addressEl);
    pickupAutocomplete.addListener('place_changed', () => {
      this.updateHandoverLocation(
        this.handoverLocation(),
        pickupAutocomplete.getPlace(),
      );
    });
  }

  private updateHandoverLocation(
    handoverLocation: HandoverLocation,
    place: google.maps.places.PlaceResult,
  ) {
    if (!place.address_components) {
      this.snackBar.open('Did not find address', '', {
        duration: 2000,
      });
      return;
    }
    const streetName = place.address_components.find((component) =>
      component.types.includes('route'),
    )?.long_name;
    const streetNumber = place.address_components.find((component) =>
      component.types.includes('street_number'),
    )?.long_name;
    const subpremise = place.address_components.find((component) =>
      component.types.includes('subpremise'),
    )?.long_name;

    if (!streetName || !streetNumber) {
      this.snackBar.open('Address does not contain street number or name', '', {
        duration: 2000,
      });
      return;
    }

    this.handoverLocationChange.emit(
      produce(handoverLocation, (draft) => {
        draft.properties.streetName = streetName;
        draft.properties.streetNumber = streetNumber;
        draft.properties.subpremise = subpremise;
      }),
    );
  }
}
