import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { Robot, UserClaim } from '@/app/core/robots-service/backend/robot.dto';
import { lastValueFrom } from 'rxjs';
import { BackendService } from '@/app/core/backend.service';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

export type ClaimRobotDialogData = {
  robot: Robot;
};

@Component({
  selector: 'app-claim-robot-dialog',
  templateUrl: './claim-robot-dialog.component.html',
  styleUrls: ['./claim-robot-dialog.component.sass'],
  standalone: true,
  imports: [
    CdkScrollable,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    DatePipe,
  ],
})
export class ClaimRobotDialogComponent {
  userClaim?: UserClaim;
  isClaimed: boolean;
  robotId: string;

  claimedByInput?: string;
  reasonInput?: string;
  forHowLongInput?: number;
  untilInput?: Date;
  errorService: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ClaimRobotDialogData,
    public dialogRef: MatDialogRef<void, void>,
    private readonly backendService: BackendService,
  ) {
    this.userClaim = data.robot.userClaim;
    this.isClaimed = Boolean(
      data.robot.userClaim?.claimedBy &&
        (!data.robot.userClaim.claimedUntil ||
          new Date(data.robot.userClaim.claimedUntil) > new Date()),
    );
    this.robotId = data.robot.id;
  }

  async unclaimRobot() {
    await this.unclaimRobotImpl();
  }

  async claimRobot() {
    if (!this.claimedByInput) {
      return;
    }
    await this.claimRobotImpl(
      this.claimedByInput,
      this.reasonInput,
      this.untilInput,
    );
  }

  updateClaimedUntilInput() {
    if (!this.forHowLongInput) {
      return;
    }
    this.untilInput = new Date(
      Date.now() + this.forHowLongInput * 60 * 60 * 1000,
    );
  }

  private async claimRobotImpl(
    claimedBy: string,
    reason?: string,
    claimedUntil?: Date,
  ): Promise<void> {
    console.log(claimedBy, reason, claimedUntil);
    await lastValueFrom(
      this.backendService.post(`/robots/${this.robotId}/claim`, {
        reason,
        claimedUntil,
        claimedBy,
      }),
    );
  }

  private async unclaimRobotImpl(): Promise<void> {
    await lastValueFrom(
      this.backendService.post(`/robots/${this.robotId}/unclaim`, {}),
    );
  }
}
