<mat-card>
  <mat-card-title>Reachability</mat-card-title><br />
  <mat-card-content class="container">
    <mat-slide-toggle
      color="primary"
      [checked]="autonomyOnly()"
      (change)="autonomyOnly.set($event.checked)"
    >
      Autonomy Only
    </mat-slide-toggle>

    <mat-form-field appearance="fill">
      <mat-label>Max Robot Distance [m]</mat-label>
      <input
        matInput
        type="number"
        placeholder="1500"
        min="1"
        step="100"
        [value]="maxDistanceMeters()"
        (change)="maxDistanceMeters.set($any($event.target).valueAsNumber)"
      />
    </mat-form-field>
  </mat-card-content>
</mat-card>
