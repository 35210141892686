import {
  ClickEvent,
  GuideFeatureCollection,
  ModeProps,
  NonInteractiveFeature,
  NonInteractiveFeatureCollection,
} from '../../map-editor/visualization/types';
import { InteractiveMode } from '../../map-editor/visualization/interactive-mode';
import {
  getHandlesForPick,
  getUnderlyingFeaturePick,
} from '../../map-editor/visualization/utils';
import { BlockageManager } from './blockage-manager';
import { GlobalPose } from '../robots-service/webrtc/types';
import { Geometry } from '../../map-editor/visualization/geojson-types';
import { computeOffset, LatLngLike } from 'spherical-geometry-js';
import { lineString, multiLineString } from '@turf/helpers';

export class BlockageUpdateMode extends InteractiveMode {
  constructor(
    private toggleMapElementsManager: BlockageManager,

    private robotPose: GlobalPose,
  ) {
    super();
  }

  override getGuides(props: ModeProps): GuideFeatureCollection {
    const hoveredPick = getUnderlyingFeaturePick(
      props.lastHoverEvent?.picks[0],
      props,
    );
    return {
      type: 'FeatureCollection',
      features: [...getHandlesForPick(hoveredPick)],
    };
  }

  setRobotPose(robotPose: GlobalPose) {
    this.robotPose = robotPose;
  }

  createArrow(
    start: LatLngLike,
    arrowLength: number,
    headLength: number,
    heading: number,
    altitude: number,
  ) {
    const to = computeOffset(start, arrowLength, heading);

    const left = computeOffset(to, headLength, heading + 145);
    const right = computeOffset(to, headLength, heading - 145);
    const arrow = [
      [
        [left.lng(), left.lat(), altitude],
        [to.lng(), to.lat(), altitude],
        [right.lng(), right.lat(), altitude],
      ],
    ];
    const arrowFeature = multiLineString(arrow);
    const robotPoseFeature = lineString([
      [this.robotPose.longitude, this.robotPose.latitude, altitude],
      [to.lng(), to.lat(), altitude],
    ]);
    return [arrowFeature, robotPoseFeature];
  }

  override getNonInteractiveFeatures(
    props: ModeProps,
  ): NonInteractiveFeatureCollection {
    const altitude = this.robotPose.altitude ?? 0;
    const arrowLength = 0.5;
    const headLength = 0.35;
    const heading = this.robotPose.heading ?? 0;
    const [arrowFeature, poseFeature] = this.createArrow(
      this.robotPose,
      arrowLength,
      headLength,
      heading,
      altitude,
    );

    const changedMapElements: NonInteractiveFeature[] = [];
    changedMapElements.push({
      type: 'Feature',
      geometry: arrowFeature.geometry as Geometry,
      properties: {
        lineColor: [0, 255, 255, 255],
        lineWidth: 18,
        fillColor: [0, 0, 0, 0],
      },
    });

    changedMapElements.push({
      type: 'Feature',
      geometry: poseFeature.geometry as Geometry,
      properties: {
        lineColor: [0, 255, 255, 255],
        lineWidth: 18,
        fillColor: [0, 0, 0, 0],
      },
    });

    return {
      type: 'FeatureCollection',
      features: changedMapElements,
    };
  }

  override onLeftClick(event: ClickEvent, props: ModeProps) {
    const pick = getUnderlyingFeaturePick(event.picks[0], props);
    const mapElement = pick?.object;
    if (mapElement) {
      this.toggleMapElementsManager.toggleBlockedProperty(mapElement);
    }
  }
}
