<app-toolbar> </app-toolbar>
<div class="container">
  @if (googleMap !== undefined) {
    <mat-tab-group class="sidebar">
      <mat-tab label="Recordings">
        <ng-template matTabContent>
          <map-recordings
            class="recordings-list"
            (onShowTrajectory)="showGeometry($event)"
          />
        </ng-template>
      </mat-tab>
      <mat-tab label="Robots">
        <ng-template matTabContent>
          <mapping-robots
            class="recordings-list"
            (onShowTrajectory)="showGeometry($event)"
            (onSetOverlay)="showOverlay($event)"
          />
        </ng-template>
      </mat-tab>
      <mat-tab label="Automation">
        <ng-template matTabContent>
          <mapping-automation
            class="recordings-list"
            (onShowGeometry)="showGeometry($event)"
          />
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  }
  <mat-divider vertical />
  <map [mapOptions]="googleMapOptions" (mapReady)="onGoogleMap($event)"></map>
</div>
