<app-toolbar>
  <ng-content ngProjectAs="menu">
    <button mat-menu-item [routerLink]="''">Home</button>
  </ng-content>
</app-toolbar>

<div class="tab-selectors">
  <div
    class="tab-chip"
    [class.tab-chip-selected]="
      selectedOperatorStatusSet$.value.has('DisplayFocusedViewOperators')
    "
    (click)="toggleDisplayOption('DisplayFocusedViewOperators')"
  >
    Focus {{ focusedUserCount$ | async }}
  </div>
  <div
    class="tab-chip"
    [class.tab-chip-selected]="
      selectedOperatorStatusSet$.value.has('DisplaySupervisionViewOperators')
    "
    (click)="toggleDisplayOption('DisplaySupervisionViewOperators')"
  >
    Supervision {{ supervisionUserCount$ | async }} ({{
      supervisionAllocation$ | async | number: '1.0-1'
    }}:1)
  </div>
  <div
    class="tab-chip"
    [class.tab-chip-selected]="
      selectedOperatorStatusSet$.value.has('DisplayMaintenanceViewOperators')
    "
    (click)="toggleDisplayOption('DisplayMaintenanceViewOperators')"
  >
    Maintenance {{ maintenanceUserCount$ | async }}
  </div>
  <div
    class="tab-chip"
    [class.tab-chip-selected]="
      selectedOperatorStatusSet$.value.has('DisplayClockedInOperators')
    "
    (click)="toggleDisplayOption('DisplayClockedInOperators')"
  >
    Clocked In {{ clockedInUserCount$ | async }}
  </div>
  <div
    class="tab-chip"
    [class.tab-chip-selected]="
      selectedOperatorStatusSet$.value.has('DisplayOfflineOperators')
    "
    (click)="toggleDisplayOption('DisplayOfflineOperators')"
  >
    Offline
  </div>
</div>

<div class="page-center">
  <div class="central-container">
    <div class="users-grid">
      @for (user of selectedUsers$ | async; track user.userId) {
        <app-robot-operator-info-card class="user-grid-item" [user]="user" />
      }
    </div>
  </div>
</div>
