import {
  EdgeElement,
  ElementType,
  GeoPoint,
  isEdge,
  LineStringGeometry,
  LocalizationMapTiles,
  MapElement,
  PointGeometry,
  PolygonGeometry,
  SlippyTiles,
} from '@cartken/map-types';
import { Observable } from 'rxjs';
import { getMeanPoint, getMidPoint } from '@/utils/geo-tools';
import {
  generateBlockedEdgePoints,
  generateCorridorPolygon,
  generateOnewayArrows,
} from '../map-elements/edge';
import {
  LayerName,
  getIcon,
  slippyTilesStyles,
  getCorridorColor,
} from './visualization-styles';
import { Layer, LayerContext } from '@deck.gl/core';
import { IconLayer, SolidPolygonLayer, TextLayer } from '@deck.gl/layers';
import { InteractiveGeoJsonLayer } from './interactive-geojson-layer';
import { InteractiveMode } from './interactive-mode';
import { BaseMap, MapStyle } from './base-map';
import { MapboxBaseMap } from './mapbox-base-map';
import { createTileLayer, getBounds, isEdgeBlocked } from './utils';
import { LatLngBounds } from 'spherical-geometry-js';
import { hasAtLeastTwoElements, isDefined } from '@/utils/typeGuards';
import { TileLayer } from '@deck.gl/geo-layers';
import { LocalizationMapTileLayer } from './localization-map/localization-map-tile-layer';
import { LocalizationTileset } from './localization-map/localization-tileset';
import { ZoomBoundedLayer } from './zoom-bounded-layer';
import { IconMapping } from '@deck.gl/layers/dist/icon-layer/icon-manager';
import { createIconAtlas } from './icon-atlas';
import { Matrix4 } from '@math.gl/core';
import { PickingInfoWithAltitude } from './localization-map/localization-map-tile-level-layer';
import { Operation } from '@/app/operations/operation';
import { WritableSignal } from '@angular/core';

export interface MapElementMouseEvent {
  mapElementId: number;
  vertex?: number;
}

export interface MapElementGeometryChangeEvent {
  mapElementId: number;
  geometry: PointGeometry | LineStringGeometry | PolygonGeometry;
}

const altitudeFlatteningFactor = 1e-4;

const ZOOM_SMALL_ELEMENTS = 12;
const ZOOM_SLIPPY_TILES = 16;
const ZOOM_LOCALIZATION_MAP = 18;
const ZOOM_QUEUE_LABELS = 18;

const { floor } = Math;

export class VisualizationManager {
  private readonly map: BaseMap;
  private layerStyles = structuredClone(slippyTilesStyles);
  private flattenAltitudes = true;
  private minDisplayAltitude = 0;
  private maxDisplayAltitude = 1000;
  private altitudeOffset = 0;
  private operations: Operation[] = [];
  private interactiveMapElements: MapElement[] = [];
  private displayMapElements: MapElement[] = [];
  private localizationMapElements: LocalizationMapTiles[] = [];
  private slippyTileMapElements: SlippyTiles[] = [];

  readonly boundsChanged$: Observable<LatLngBounds>;

  readonly icons = createIconAtlas();

  constructor(
    private hoveredElementId: WritableSignal<number | undefined>,
    private selectedElementId: WritableSignal<number | undefined>,
    private viewOperationRegion: (region: Operation) => void,
    private mode: () => InteractiveMode,
    mapContainerElement: HTMLElement,
  ) {
    this.map = new MapboxBaseMap(mapContainerElement);
    this.map.setProps({
      getCursor: () => this.mode().getCursor(),
    });
    this.boundsChanged$ = this.map.boundsObservable();
  }

  private assumeAltitude([x, y, z]: GeoPoint): [number, number, number] {
    return [x, y, z || this.minDisplayAltitude];
  }

  private assumeAltitudes(point: GeoPoint[]) {
    return point.map((p) => this.assumeAltitude(p));
  }

  private altitudeFactor() {
    return this.flattenAltitudes ? altitudeFlatteningFactor : 1;
  }

  private transformAltitude(alt: number) {
    return this.altitudeFactor() * (alt - this.altitudeOffset);
  }

  private altitudeTransformMatrix() {
    const altitudeFactor = this.altitudeFactor();
    return new Matrix4()
      .scale([1, 1, altitudeFactor])
      .translate([0, 0, -this.altitudeOffset]);
  }

  private createInteractiveGeoJsonLayer() {
    return new InteractiveGeoJsonLayer({
      id: 'interactive-geojson-layer',
      mode: this.mode(),
      data: {
        type: 'FeatureCollection',
        features: this.displayMapElements,
      },
      assumeAltitude: (point) => this.assumeAltitude(point),
      modelMatrix: this.altitudeTransformMatrix(),
      mapCoordGetter: (coords, context) =>
        this.surfaceCoordinates(coords, context),
      filled: false,
      getLineWidth: (f: MapElement) => (f.elementType === 'Node' ? 8 : 4),
      getIcon,
      getIconSize: 20,
      pointType: 'circle+icon',
      getPointRadius: 4,
      lineJointRounded: true,
      opacity: this.mode().getGlobalElementsOpacity(),
      enableMapPanning: (enablePanning) => {
        this.map.enableDragPanning(enablePanning);
      },
      onHover: (pickingInfo) => {
        const hoveredObjectId =
          pickingInfo.object?.id ?? pickingInfo.object?.properties?.elementId;

        this.hoveredElementId.set(hoveredObjectId);
      },
      updateTriggers: {
        all: [this.hoveredElementId(), this.selectedElementId(), this.mode()],
      },
    });
  }

  private createCorridorPolygons(mapElement: EdgeElement) {
    const features = [];
    const props = mapElement.properties;
    const startWidth = 'startWidth' in props ? (props.startWidth ?? 0) : 0;
    const endWidth = 'endWidth' in props ? (props.endWidth ?? 0) : 0;
    if (
      props &&
      mapElement.geometry.type === 'LineString' &&
      (startWidth > 0 || endWidth > 0)
    ) {
      const polygon = generateCorridorPolygon(
        mapElement.geometry.coordinates,
        startWidth,
        endWidth,
      );
      features.push({ polygon, color: getCorridorColor(mapElement) });
    }

    return features;
  }

  private createCorridorPolygonsLayer() {
    const polygons = this.displayMapElements
      .filter(isEdge)
      .flatMap((m) => this.createCorridorPolygons(m));

    return new SolidPolygonLayer<(typeof polygons)[0]>({
      id: 'corridor-polygons',
      data: polygons,
      getFillColor: (f) => f.color,
      getPolygon: (f) => this.assumeAltitudes(f.polygon),
      modelMatrix: this.altitudeTransformMatrix(),
      parameters: {
        // removes ugly artifacts where polygons overlap
        depthWriteEnabled: false,
      },
    });
  }

  private createDerivedLegendLayers(): Layer[] {
    const robotQueueEdges = this.displayMapElements.filter(
      (m) => m.elementType === ElementType.ROBOT_QUEUE_EDGE,
    );

    const ICON_MAPPING: IconMapping = {
      marker: {
        x: 0,
        y: 0,
        width: 48,
        height: 48,
        mask: true,
        anchorY: 48,
        anchorX: 24,
      },
    };
    const getPosition = ({
      geometry: { coordinates },
    }: EdgeElement): [number, number, number] => {
      const { length } = coordinates;
      const midIndex = length / 2;
      if (length % 2 === 0) {
        const startPoint = coordinates[midIndex - 1];
        const endPoint = coordinates[midIndex];
        return this.assumeAltitude(getMidPoint(startPoint, endPoint));
      } else {
        return this.assumeAltitude(coordinates[floor(midIndex)]);
      }
    };

    return [
      new ZoomBoundedLayer({
        id: 'zoom-bounds-queue-name-layer',
        minZoom: ZOOM_QUEUE_LABELS,
        renderLayers: () => [
          new TextLayer({
            getSize: 12,
            id: 'queue-name-layer',
            data: robotQueueEdges,
            getPosition,
            getText: (d) => d?.properties?.names?.join('\n'),
            getAngle: 0,
            getTextAnchor: 'start',
            getAlignmentBaseline: 'bottom',
            getPixelOffset: [10, 0],
            background: true,
            getBackgroundColor: [255, 255, 255, 150],
            modelMatrix: this.altitudeTransformMatrix(),
          }),
        ],
      }),
      new IconLayer({
        id: 'queue-marker-layer',
        data: robotQueueEdges,
        iconAtlas: 'assets/baseline_location_on_black_24dp.png',
        iconMapping: ICON_MAPPING,
        getIcon: () => 'marker',
        sizeScale: 15,
        getPosition,
        modelMatrix: this.altitudeTransformMatrix(),
      }),
    ];
  }

  private createOneWayArrowLayer() {
    const arrowElements = this.displayMapElements
      .filter(
        ({ elementType, properties, geometry }) =>
          elementType === 'RobotQueueEdge' ||
          elementType === 'RoadEdge' ||
          elementType === 'CachedRoadEdge' ||
          (geometry.type === 'LineString' &&
            properties &&
            'oneway' in properties &&
            properties.oneway),
      )
      .flatMap((element) =>
        generateOnewayArrows(
          this.assumeAltitudes(element.geometry.coordinates as GeoPoint[]),
        ).map((arrow) => ({
          ...arrow,
          color: this.mode().getMapElementColor(element),
        })),
      );

    return new IconLayer<(typeof arrowElements)[number]>({
      id: 'arrows',
      data: arrowElements,
      getPosition: (a) => a.position,
      getColor: (a) => a.color,
      getIcon: () => 'chevron',
      getAngle: (a) => -a.heading,
      billboard: false,
      getSize: 1,
      sizeUnits: 'meters',
      sizeMinPixels: 12,
      sizeMaxPixels: 32,
      modelMatrix: this.altitudeTransformMatrix(),
      iconAtlas: this.icons.iconAtlas as any,
      iconMapping: this.icons.iconMapping,
      opacity: this.mode().getGlobalElementsOpacity(),
    });
  }

  private createBlockedEdgesLayer() {
    const blockedEdges = this.displayMapElements
      .filter((e): e is EdgeElement => isEdgeBlocked(e))
      .flatMap((e) =>
        generateBlockedEdgePoints(this.assumeAltitudes(e.geometry.coordinates)),
      );

    return new IconLayer<(typeof blockedEdges)[number]>({
      id: 'blocked-edges',
      data: blockedEdges,
      getPosition: (a) => a,
      getIcon: () => 'forbiddenDirection',
      billboard: true,
      getSize: 2,
      sizeUnits: 'meters',
      sizeMinPixels: 10,
      sizeMaxPixels: 64,
      modelMatrix: new Matrix4()
        // shift up a litle so the icon doesn't intersect
        // the ground as much
        .translate([0, 0, 0.5])
        .multiplyRight(this.altitudeTransformMatrix()),
      iconAtlas: this.icons.iconAtlas as any,
      iconMapping: this.icons.iconMapping,
    });
  }

  private createSlippyTilesLayers() {
    const minZoom = ZOOM_SLIPPY_TILES;
    const maxZoom = ZOOM_LOCALIZATION_MAP;
    const layers: Layer[] = [];
    for (const tiles of this.slippyTileMapElements) {
      const baseUrl = tiles.properties.tilesBaseUrl;
      const name = tiles.properties.name.toLowerCase();
      const opacity = this.layerStyles[name as LayerName]?.opacity ?? 0;
      layers.push(
        createTileLayer(
          `${baseUrl}/{z}/{x}/{y}.png`,
          baseUrl,
          minZoom,
          maxZoom,
          256,
          opacity,
          tiles.geometry.coordinates[0],
        ),
      );
    }
    return new ZoomBoundedLayer({
      id: `slippy-tiles-zoom-bounds`,
      minZoom,
      maxZoom,
      renderLayers: () => layers,
    });
  }

  private createLocalizationMapLayer(mapElement: LocalizationMapTiles): Layer {
    const {
      tilesBaseUrl,
      tilesOriginLatitude,
      tilesOriginLongitude,
      tilesSize,
      minAltitude = -Infinity,
      maxAltitude = Infinity,
    } = mapElement.properties;

    const altitudeScale = this.flattenAltitudes ? altitudeFlatteningFactor : 1;
    const urlPath = tilesBaseUrl.split('/').at(-1);
    const bounds = getBounds(mapElement.geometry.coordinates[0]);
    bounds[0] = tilesOriginLongitude;
    bounds[1] = tilesOriginLatitude;

    return new TileLayer({
      id: `localization-map-${urlPath}`,
      TilesetClass: LocalizationTileset,
      data: tilesBaseUrl,
      minZoom: ZOOM_LOCALIZATION_MAP,
      tileSize: tilesSize,
      maxRequests: 6, // Concurrent requests to make loading faster.
      extent: bounds,
      pickable: true,
      maxCacheSize: 100,
      modelMatrix: this.altitudeTransformMatrix(),
      zRange: [
        this.transformAltitude(minAltitude),
        this.transformAltitude(maxAltitude),
      ],
      renderSubLayers: (props) => {
        return new LocalizationMapTileLayer(props, {
          tileIndex: [props.tile.index.x, props.tile.index.y],
          data: mapElement.properties,
          minDisplayAltitude: this.minDisplayAltitude,
          maxDisplayAltitude: this.maxDisplayAltitude,
          layerOpacities: this.getLayerOpacities(),
        });
      },
      onTileUnload: (tile) => {
        console.log('Unloading', tile.index.x, tile.index.y);
      },
      onTileError() {
        // ignore tile loading errors
      },
      updateTriggers: {
        renderSubLayers: [
          this.minDisplayAltitude,
          this.maxDisplayAltitude,
          altitudeScale,
          this.altitudeOffset,
        ],
      },
    });
  }

  private createOperationRegionsLayer(): Layer {
    return new IconLayer<Operation>({
      id: 'operation-regions',
      data: this.operations,
      getPosition: (el) =>
        getMeanPoint(el.operationRegion?.coordinates.flat() ?? []),
      billboard: true,
      getSize: 18,
      sizeUnits: 'pixels',
      getColor: [255, 0, 255, 255],
      iconAtlas: 'assets/location.svg',
      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: 24,
          height: 24,
          anchorX: 12,
          anchorY: 24,
          mask: true,
        },
      },
      getIcon: () => 'marker',
      pickable: true,
      autoHighlight: true,
      highlightColor: [0, 255, 255, 255],
    });
  }

  private createLocalizationMapLayers(): Layer[] {
    return this.localizationMapElements
      .filter(isDefined)
      .map((m) => this.createLocalizationMapLayer(m));
  }

  private surfaceCoordinates(
    screenCoords: [number, number],
    context: LayerContext,
  ): GeoPoint {
    const pick: PickingInfoWithAltitude | undefined | null =
      context.deck?.pickObject({
        x: screenCoords[0],
        y: screenCoords[1],
        unproject3D: true,
        layerIds: ['localization-map'],
      });

    const heightMapOffset = 0.1;

    if (
      pick?.altitude !== undefined &&
      pick.coordinate &&
      hasAtLeastTwoElements(pick.coordinate)
    ) {
      const [lng, lat] = pick.coordinate;
      return [lng, lat, pick.altitude + heightMapOffset];
    }

    const [lng, lat] = context.viewport.unproject(screenCoords);
    return [lng, lat];
  }

  getLatLngBounds(): LatLngBounds {
    return this.map.getBounds();
  }

  getZoom(): number {
    return this.map.getZoom();
  }

  fitBounds(bounds: LatLngBounds) {
    this.map.fitBounds(bounds);
  }

  enableTerrain(enabled: boolean) {
    this.map.enableTerrain(enabled);
  }

  enableAltitudeFlattening(enabled: boolean) {
    this.map.enableTerrain(false);
    this.flattenAltitudes = enabled;
    this.updateDisplayMapElements();
  }

  setDisplayAltitudeRange(minAltitude: number, maxAltitude: number) {
    this.minDisplayAltitude = minAltitude;
    this.maxDisplayAltitude = maxAltitude;
    this.altitudeOffset = minAltitude;
    this.updateDisplayMapElements();
  }

  setMapStyle(tileStyle: MapStyle) {
    this.map.setMapStyle(tileStyle);
  }

  setOperationRegions(regions: Operation[]) {
    this.operations = regions.filter((r) => !r.deleted && r.operationRegion);
    this.rerenderMapElements();
  }

  setMapElements(mapElements: MapElement[]) {
    const slippyTiles = [];
    const localizationMapElements = [];
    const interactiveMapElements = [];
    for (const element of mapElements) {
      if (element.elementType === ElementType.SLIPPY_TILES) {
        slippyTiles.push(element);
      } else if (element.elementType === ElementType.LOCALIZATION_MAP_TILES) {
        localizationMapElements.push(element);
      } else {
        interactiveMapElements.push(element);
      }
    }

    this.slippyTileMapElements = slippyTiles;
    this.slippyTileMapElements.sort((a, b) => {
      const z1 =
        this.layerStyles[a.properties.name.toLowerCase() as LayerName]
          ?.zIndex ?? 0;
      const z2 =
        this.layerStyles[b.properties.name.toLowerCase() as LayerName]
          ?.zIndex ?? 0;
      return z1 - z2;
    });

    this.localizationMapElements = localizationMapElements;
    this.interactiveMapElements = interactiveMapElements;
    this.updateDisplayMapElements();
  }

  rerenderMapElements() {
    const layers: Layer[] = [
      new ZoomBoundedLayer({
        id: 'zoom-bounds-operation-region-centers',
        maxZoom: ZOOM_SMALL_ELEMENTS,
        autoHighlight: true,
        // TODO: show pointer cursor when hovering
        onClick: (pickingInfo) => {
          const region: Operation = pickingInfo.object;
          this.viewOperationRegion(region);
        },
        renderLayers: () => [this.createOperationRegionsLayer()],
      }),
      this.createSlippyTilesLayers(),
      ...this.createLocalizationMapLayers(),
      new ZoomBoundedLayer({
        id: 'zoom-bounds-small-elements',
        minZoom: ZOOM_SMALL_ELEMENTS,
        renderLayers: () => [
          this.createCorridorPolygonsLayer(),
          this.createOneWayArrowLayer(),
          this.createInteractiveGeoJsonLayer(),
          this.createBlockedEdgesLayer(),
          ...this.createDerivedLegendLayers(),
        ],
      }),
    ];
    this.map.setProps({ layers });
  }

  setLayerOpacities(layerOpacities: Record<LayerName, number>) {
    let changed = false;
    for (const [layerName, opacity] of Object.entries(layerOpacities)) {
      const prev = this.layerStyles[layerName as LayerName].opacity;
      this.layerStyles[layerName as LayerName].opacity = opacity;
      changed ||= prev !== opacity;
    }
    if (changed) {
      this.rerenderMapElements();
    }
  }

  getLayerOpacities(): Record<LayerName, number> {
    return Object.fromEntries(
      Object.entries(this.layerStyles).map(([layerName, { opacity }]) => [
        layerName,
        opacity,
      ]),
    ) as Record<LayerName, number>;
  }

  private isWithinDisplayAltitudeRange(m: MapElement): boolean {
    const coordWithinDisplayAltitudeRange = (coord: GeoPoint) =>
      !coord[2] ||
      (coord[2] <= this.maxDisplayAltitude &&
        coord[2] >= this.minDisplayAltitude);

    switch (m.geometry.type) {
      case 'Point':
        return coordWithinDisplayAltitudeRange(m.geometry.coordinates);
      case 'LineString':
        return m.geometry.coordinates.some((c) =>
          coordWithinDisplayAltitudeRange(c),
        );
      case 'Polygon':
        return m.geometry.coordinates[0].some((c) =>
          coordWithinDisplayAltitudeRange(c),
        );
      default:
        return false;
    }
  }

  private updateDisplayMapElements() {
    this.displayMapElements = this.interactiveMapElements.filter((m) =>
      this.isWithinDisplayAltitudeRange(m),
    );

    this.rerenderMapElements();
  }
}
