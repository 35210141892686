<app-toolbar>
  <ng-content ngProjectAs="menu">
    <button mat-menu-item [routerLink]="''">
      <mat-icon>home</mat-icon>
      Home
    </button>
  </ng-content>
</app-toolbar>

<div class="orders-container mat-elevation-z8">
  <div class="top-row">
    <div class="filter">
      <mat-form-field appearance="fill">
        <mat-label>Text Search</mat-label>
        <input
          matInput
          #textSearchInput
          placeholder='"Some Street" -ExcludeThis'
          value=""
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Time Zone</mat-label>
        <mat-select
          [(value)]="timeZone"
          (selectionChange)="onTimeZoneChanged($event)"
        >
          @for (timeZone of timeZones; track timeZone) {
            <mat-option [value]="timeZone">
              {{ timeZone }}
            </mat-option>
          }
          <mat-option value=""> </mat-option>
        </mat-select>
      </mat-form-field>
      <selection-dropbox
        class="menu-item"
        label="Operation"
        [options]="operations"
        (selectedChange)="operationIdChanged($event)"
      />
      <mat-form-field appearance="fill">
        <mat-label>Order Status</mat-label>
        <mat-select
          [(value)]="status"
          (selectionChange)="orderStatusChanged($event)"
        >
          <mat-option value="active">Active</mat-option>
          <mat-option value="final">Final</mat-option>
          @for (status of orderStatuses; track status) {
            <mat-option [value]="status">
              {{ status }}
            </mat-option>
          }
          <mat-option value=""> </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Start Date</mat-label>
        <input
          matInput
          [matDatepicker]="afterPicker"
          (dateChange)="onStartDateChanged($event.value)"
        />
        <mat-datepicker-toggle matSuffix [for]="afterPicker" />
        <mat-datepicker #afterPicker />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>End Date</mat-label>
        <input
          matInput
          [matDatepicker]="beforePicker"
          (dateChange)="onEndDateChanged($event.value)"
        />
        <mat-datepicker-toggle matSuffix [for]="beforePicker" />
        <mat-datepicker #beforePicker />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Test Orders</mat-label>
        <mat-select
          [(value)]="testOrderFilter"
          (selectionChange)="testOrderFilteringChanged()"
        >
          <mat-option value=""> Include </mat-option>
          <mat-option value="exclude"> Exclude </mat-option>
          <mat-option value="only"> Only </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="buttons">
      <button
        mat-raised-button
        [disabled]="resultsLength > 50000"
        (click)="exportOrders()"
      >
        Export To .csv
      </button>
      <button
        mat-raised-button
        (click)="showStats()"
        [disabled]="resultsLength > 50000"
      >
        Compute Statistics
      </button>
    </div>
    <div>
      <button
        mat-mini-fab
        color="primary"
        aria-label="refresh-button"
        style="margin: 10px"
        (click)="refresh()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

  @if (isLoadingResults || isGeneratingStats) {
    <div class="orders-loading-shade">
      <mat-spinner />
    </div>
  }

  <div class="orders-table-container">
    <table
      mat-table
      [dataSource]="data"
      class="orders-table"
      matTableExporter
      #exporter="matTableExporter"
    >
      <ng-container matColumnDef="operationId">
        <th mat-header-cell *matHeaderCellDef>Operation Id</th>
        <td mat-cell *matCellDef="let row">
          {{ row.operationId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="orderId">
        <th mat-header-cell *matHeaderCellDef>Ext. Order Id</th>
        <td mat-cell *matCellDef="let row">
          {{ row.externalId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>Created</th>
        <td mat-cell *matCellDef="let row">
          {{ row.localTime }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">{{ row.status }}</td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef>Duration</th>
        <td mat-cell *matCellDef="let row">
          {{ row.orderDuration | duration }}
        </td>
      </ng-container>

      <ng-container matColumnDef="testOrder">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          @if (row.testOrder) {
            <mat-icon>build</mat-icon>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="robotId">
        <th mat-header-cell *matHeaderCellDef>Robot</th>
        <td mat-cell *matCellDef="let row">
          {{ row.assignedRobotDisplayName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pickups">
        <th mat-header-cell *matHeaderCellDef>Pickup(s)</th>
        <td mat-cell *matCellDef="let row">
          @for (pickup of row.pickups; track pickup) {
            <div>
              {{ pickup }}
            </div>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="dropoffs">
        <th mat-header-cell *matHeaderCellDef>Dropoff(s)</th>
        <td mat-cell *matCellDef="let row">
          @for (dropoff of row.dropoffs; track dropoff) {
            <div>
              {{ dropoff }}
            </div>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="waiting-for-robot">
        <th mat-header-cell *matHeaderCellDef>in Waiting for Free Robot</th>
        <td mat-cell *matCellDef="let row">
          {{ row.inWaitingForRobot | duration }}
        </td>
      </ng-container>

      <ng-container matColumnDef="driving-to-pickup">
        <th mat-header-cell *matHeaderCellDef>in Driving to Pickup</th>
        <td mat-cell *matCellDef="let row">
          {{ row.inDrivingToPickup | duration }}
        </td>
      </ng-container>

      <ng-container matColumnDef="waiting-for-pickup">
        <th mat-header-cell *matHeaderCellDef>in Waiting for Pickup</th>
        <td mat-cell *matCellDef="let row">
          {{ row.inWaitingForPickup | duration }}
        </td>
      </ng-container>
      <ng-container matColumnDef="driving-to-dropoff">
        <th mat-header-cell *matHeaderCellDef>in Driving To Dropoff</th>
        <td mat-cell *matCellDef="let row">
          {{ row.inDrivingToDropoff | duration }}
        </td>
      </ng-container>
      <ng-container matColumnDef="waiting-for-dropoff">
        <th mat-header-cell *matHeaderCellDef>in Waiting for Dropoff</th>
        <td mat-cell *matCellDef="let row">
          {{ row.inWaitingForDropoff | duration }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="element-row"
        (click)="showOrderDetails(row)"
      ></tr>
    </table>
  </div>

  <mat-paginator
    #paginator
    [length]="resultsLength"
    [pageSizeOptions]="pageSizeOptions"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
