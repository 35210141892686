@let pole_ = pole();

<mat-form-field appearance="fill">
  <mat-label>Radius</mat-label>
  <input
    matInput
    type="number"
    placeholder="0"
    min="0"
    step="0.1"
    [value]="pole_.properties.radius"
    (change)="setRadius(pole_, $any($event.target).value)"
  />
</mat-form-field>
