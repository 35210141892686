import {
  Component,
  ElementRef,
  Signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { SelectOperationButtonComponent } from '@/app/core/select-operation-button/select-operation-button.component';
import { MatFormField } from '@angular/material/form-field';
import { VisualizationManager } from '../../visualization/visualization-manager';
import { GeoPoint } from '@cartken/map-types';
import { MatInput } from '@angular/material/input';

function parseLatLngAltString(latLonString: string): GeoPoint | undefined {
  const latLngAltString = latLonString.split(/, ?/);
  if (latLngAltString.length !== 2 && latLngAltString.length !== 3) {
    return undefined;
  }
  const [lat, lng, alt] = latLngAltString.map(Number.parseFloat);
  return [lng, lat, alt];
}

function getLocationFromPlaceOrLatLng(
  place: google.maps.places.PlaceResult,
  locationElement: ElementRef,
): GeoPoint | undefined {
  const latLonAlt = parseLatLngAltString(locationElement.nativeElement.value);
  if (latLonAlt) {
    return latLonAlt;
  }
  if (!place.geometry?.location) {
    return undefined;
  }
  const point = place.geometry.location;
  return [point.lng(), point.lat()];
}

@Component({
  standalone: true,
  templateUrl: './routing-mode-sidebar.component.html',
  styleUrl: './routing-mode-sidebar.component.sass',
  imports: [
    MatCard,
    MatCardTitle,
    MatCardContent,
    SelectOperationButtonComponent,
    MatInput,
    MatFormField,
  ],
})
export class RoutingModeSidebarComponent {
  visualizationManager!: VisualizationManager;
  limitToOperationId!: WritableSignal<string | undefined>;
  fromLocation!: WritableSignal<GeoPoint | undefined>;
  toLocation!: WritableSignal<GeoPoint | undefined>;
  routeInfo!: Signal<string | undefined>;

  formatLatLngAltString(point: GeoPoint | undefined) {
    if (!point) {
      return '';
    }
    const [lng, lat, alt] = point;
    return alt !== undefined ? `${lat}, ${lng}, ${alt}` : `${lat}, ${lng}`;
  }

  @ViewChild('fromLocationElement') set setFromLocation(
    fromLocation: ElementRef,
  ) {
    if (fromLocation) {
      // initially setter gets called with undefined
      this.setFromLocationElement(fromLocation);
    }
  }

  @ViewChild('toLocationElement') set setToLocation(toLocation: ElementRef) {
    if (toLocation) {
      // initially setter gets called with undefined
      this.setToLocationElement(toLocation);
    }
  }

  setFromLocationElement(fromLocationElement: ElementRef) {
    const bounds = this.visualizationManager.getLatLngBounds();
    const options: google.maps.places.AutocompleteOptions = {
      bounds: new google.maps.LatLngBounds(
        { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
        { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
      ),
    };
    const fromAutocomplete = new google.maps.places.Autocomplete(
      fromLocationElement.nativeElement,
      options,
    );

    fromAutocomplete.addListener('place_changed', () => {
      this.fromLocation.set(
        getLocationFromPlaceOrLatLng(
          fromAutocomplete.getPlace(),
          fromLocationElement,
        ),
      );
    });
  }

  setToLocationElement(toLocationElement: ElementRef) {
    const bounds = this.visualizationManager.getLatLngBounds();
    const options: google.maps.places.AutocompleteOptions = {
      bounds: new google.maps.LatLngBounds(
        { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
        { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
      ),
    };
    const toAutocomplete = new google.maps.places.Autocomplete(
      toLocationElement.nativeElement,
      options,
    );

    toAutocomplete.addListener('place_changed', () => {
      this.toLocation.set(
        getLocationFromPlaceOrLatLng(
          toAutocomplete.getPlace(),
          toLocationElement,
        ),
      );
    });
  }
}
