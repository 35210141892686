<div class="automation-settings">
  <div class="region-row">
    <mat-form-field appearance="fill" class="region-dropdown">
      <mat-label>Select operation region</mat-label>
      <mat-select
        [(value)]="selectedOperationId"
        cdkFocusInitial
        (selectionChange)="setOperationRegion()"
      >
        @for (region of operationRegions; track region.id) {
          <mat-option [value]="region.properties.operationId">
            {{ region.properties.operationId }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  @if (selectedOperationId) {
    <div class="upsert-row">
      <button mat-raised-button color="primary" (click)="upsertAcquisitions()">
        Request {{ numDesiredAcquisitionsOnUpsert }} acquisition(s)
      </button>
      <mat-slider
        class="upsert-slider"
        min="0"
        max="5"
        step="1"
        showTickMarks
        discrete
      >
        <input matSliderThumb [(value)]="numDesiredAcquisitionsOnUpsert" />
      </mat-slider>
    </div>
  }
</div>
