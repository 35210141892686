import { DecimalPipe } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { OperationRegion, PolygonGeometry } from '@cartken/map-types';
import { FormsModule } from '@angular/forms';
import { computeArea, LatLng } from 'spherical-geometry-js';
import { SelectOperationButtonComponent } from '@/app/core/select-operation-button/select-operation-button.component';
import { produce } from 'immer';

@Component({
  selector: 'app-operation-region-properties',
  standalone: true,
  templateUrl: './operation-region-properties.component.html',
  imports: [DecimalPipe, FormsModule, SelectOperationButtonComponent],
  host: { style: 'display: contents' },
})
export class OperationRegionPropertiesComponent {
  operationRegion = input.required<OperationRegion>();
  operationRegionChange = output<OperationRegion>();

  getRegionPolygonArea(operationRegionGeometry: PolygonGeometry) {
    const polygon = operationRegionGeometry.coordinates[0];
    if (!polygon) {
      return 0;
    }
    const path = polygon.map(([lng, lat]) => new LatLng(lat, lng));
    return computeArea(path);
  }

  setOperationRegionId(
    operationRegion: OperationRegion,
    operationId: string | undefined,
  ) {
    if (operationId === undefined) {
      return;
    }
    this.operationRegionChange.emit(
      produce(operationRegion, (draft) => {
        draft.properties.operationId = operationId;
      }),
    );
  }
}
