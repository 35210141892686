<app-toolbar>
  <ng-content ngProjectAs="menu">
    <button mat-menu-item (click)="createOperation()">Create Operation</button>
  </ng-content>
</app-toolbar>

<div class="container">
  <table
    mat-table
    [dataSource]="operations"
    multiTemplateDataRows
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Operation</th>
      <td mat-cell *matCellDef="let operation">
        {{ operation.id }}
      </td>
    </ng-container>
    <ng-container matColumnDef="accessGroups">
      <th mat-header-cell *matHeaderCellDef>Access Groups</th>
      <td mat-cell *matCellDef="let operation">
        <mat-chip-listbox>
          @for (accessGroup of operation.accessGroups; track accessGroup) {
            <mat-chip>{{ accessGroup }}</mat-chip>
          }
        </mat-chip-listbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="operation-type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let operation">
        {{ operation.operationType }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action-buttons">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let operation">
        <div class="action-buttons-container">
          <button
            mat-mini-fab
            color="primary"
            aria-label="Go to operation"
            [routerLink]="'/operations/' + operation.id"
          >
            <mat-icon>launch</mat-icon>
          </button>

          <button
            mat-mini-fab
            color="primary"
            aria-label="Edit operation"
            (click)="editOperation(operation)"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let operation; columns: columnsToDisplay"
      class="element-row"
    ></tr>
  </table>
</div>
