type LoadTextFileReturn =
  | {
      status: 'success';
      text: string;
    }
  | {
      status: 'no-file-selected';
    };

export function loadTextFile() {
  return new Promise<LoadTextFileReturn>((resolve, reject) => {
    const element = document.createElement('input');
    element.setAttribute('type', 'file');
    element.onchange = () => {
      const file = element.files?.item(0);
      if (!file) {
        resolve({ status: 'no-file-selected' });
        return;
      }
      reader.readAsText(file);
    };
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target?.result;
      if (typeof text !== 'string') {
        reject(new Error(`File reader did not produce a string result.`));
        return;
      }
      resolve({ status: 'success', text });
    };
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  });
}
