<div class="screen-container">
  <app-deadman-switch-overlay class="deadman-switch-overlay" />
  <app-toolbar class="full-screen-top-bar">
    <ng-content ngProjectAs="menu">
      <button mat-menu-item [routerLink]="''">Home</button>
      <button mat-menu-item (click)="openSupervisionSettings()">Setting</button>
    </ng-content>
  </app-toolbar>
  @if (!robotCommunicationInFocus) {
    <div class="robot-control-grid">
      @for (
        slot of robotSlots$ | async;
        track trackByIndex(i, slot);
        let i = $index
      ) {
        @if (slot.slotType === 'taken') {
          <app-mini-slot-robot-supervision
            [slotIndex]="i"
            [robotCommunication]="slot.robotCommunication"
            (onFocusChange)="focusRobotView(slot.robotCommunication)"
          />
        } @else {
          <robot-placeholder
            class="robot-item"
            [isEnabled]="slot.slotType === 'empty'"
            [slotId]="i"
            [lastUpdateTime]="
              slot.slotType === 'empty' ? slot.lastRequestTime : undefined
            "
          />
        }
      }
    </div>
  } @else {
    <app-focus-robot-supervision
      class="focused-robot-supervision"
      [robotCommunication]="robotCommunicationInFocus"
      (onFocusChange)="unfocusRobotView()"
    />
  }
  <div
    class="ping-indicator"
    [class.ping-indicator-scale]="((pingCount$ | async) ?? 0) % 2 === 0"
  ></div>
</div>
