@let modality = dataViewerService.selectedDataViewerTab$ | async;

@if (modality) {
  <div class="img-grid">
    @for (item of items; track item.id) {
      <app-grid-img-item
        (click)="itemClick.emit(item)"
        (pickClicked)="pickItem.emit(item)"
        [isSelected]="item.id === selectedItemId"
        [isPicked]="isItemPicked(item)"
        [pickable]="pickable()"
        [itemType]="modality"
        [item]="item"
      />
    }
  </div>
  @if (canFetchMore) {
    <div class="next-page-button" (click)="nextPage.emit()">Get more</div>
  }
}
