import { PAGE_SIZE } from '@/app/ml-platform/ml-data.service';
import { PageFetcher } from './page-fetcher';

export class ConsistentRandomPageFetcher<
  T extends { randomOrderingValue: number },
> implements PageFetcher<T>
{
  private initialRandomOffset: number;
  private nextRandomOffset: number;
  private loopedAround: boolean = false;

  constructor(
    private fetch: (
      loopedAround: boolean,
      initialRandomOffset: number,
      nextRandomOffset: number,
    ) => Promise<T[]>,
  ) {
    this.initialRandomOffset = Math.random();
    this.nextRandomOffset = this.initialRandomOffset;
  }

  private loopAround() {
    this.loopedAround = true;
    this.nextRandomOffset = 0;
  }

  async step(): Promise<T[]> {
    let entries = await this.fetch(
      this.loopedAround,
      this.initialRandomOffset,
      this.nextRandomOffset,
    );
    if (entries.length !== 0) {
      this.nextRandomOffset = entries[entries.length - 1].randomOrderingValue;
    }

    if (!this.loopedAround && entries.length < PAGE_SIZE) {
      this.loopAround();
      const additionalItems = await this.fetch(
        this.loopedAround,
        this.initialRandomOffset,
        this.nextRandomOffset,
      );
      entries = entries.concat(
        additionalItems.slice(0, PAGE_SIZE - entries.length),
      );
    }

    if (entries.length !== 0) {
      this.nextRandomOffset = entries[entries.length - 1].randomOrderingValue;
    }

    return entries;
  }
}
