import {
  CompositeLayer,
  FilterContext,
  Layer,
  LayersList,
} from '@deck.gl/core';

const { NEGATIVE_INFINITY, POSITIVE_INFINITY } = Number;

export type GetSubLayerProps = (sublayerProps?: {
  id?: string;
  updateTriggers?: Record<string, any>;
  [propName: string]: any;
}) => any;

export type ZoomBoundedLayerProps = {
  minZoom?: number;
  maxZoom?: number;
  renderLayers(getSubLayerProps: GetSubLayerProps): Layer | null | LayersList;
};

export class ZoomBoundedLayer extends CompositeLayer<ZoomBoundedLayerProps> {
  static override layerName = 'ZoomBoundedLayer';

  override renderLayers(): Layer | null | LayersList {
    return this.props.renderLayers(this.getSubLayerProps.bind(this));
  }

  override filterSubLayer({ viewport }: FilterContext): boolean {
    return (
      viewport.zoom >= (this.props.minZoom ?? NEGATIVE_INFINITY) &&
      viewport.zoom <= (this.props.maxZoom ?? POSITIVE_INFINITY)
    );
  }
}
