import { EdgeElement, GeoPoint } from '@cartken/map-types';
import {
  computeLength,
  computeOffset,
  computeHeading,
} from 'spherical-geometry-js';
import { getMidPoint } from '@/utils/geo-tools';

export function updateEdgeLength(edge: EdgeElement) {
  edge.properties.length = computeLength(edge.geometry.coordinates);
}

export function generateCorridorPolygon(
  edgeCoordinates: GeoPoint[],
  startWidth: number,
  endWidth: number,
): GeoPoint[] {
  if (edgeCoordinates.length < 2) {
    return [];
  }
  const widthDifference = endWidth - startWidth;
  const leftSide: GeoPoint[] = [];
  const rightSide: GeoPoint[] = [];

  for (let i = 1; i < edgeCoordinates.length; ++i) {
    const t0 = (i - 1) / (edgeCoordinates.length - 1);
    const t1 = i / (edgeCoordinates.length - 1);
    const fromWidth = (startWidth + t0 * widthDifference) / 2.0;
    const toWidth = (startWidth + t1 * widthDifference) / 2.0;
    const fromP = edgeCoordinates[i - 1]!;
    const toP = edgeCoordinates[i]!;
    const fromPAltitude = fromP[2];
    const toPAltitude = toP[2];
    const heading = computeHeading(fromP, toP);

    const leftP1 = computeOffset(fromP, fromWidth, heading + 90);
    const rightP1 = computeOffset(fromP, fromWidth, heading - 90);
    if (fromPAltitude !== undefined) {
      leftSide.push([leftP1.lng(), leftP1.lat(), fromPAltitude]);
      rightSide.push([rightP1.lng(), rightP1.lat(), fromPAltitude]);
    } else {
      leftSide.push([leftP1.lng(), leftP1.lat()]);
      rightSide.push([rightP1.lng(), rightP1.lat()]);
    }

    const leftP2 = computeOffset(toP, toWidth, heading + 90);
    const rightP2 = computeOffset(toP, toWidth, heading - 90);
    if (toPAltitude !== undefined) {
      leftSide.push([leftP2.lng(), leftP2.lat(), toPAltitude]);
      rightSide.push([rightP2.lng(), rightP2.lat(), toPAltitude]);
    } else {
      leftSide.push([leftP2.lng(), leftP2.lat()]);
      rightSide.push([rightP2.lng(), rightP2.lat()]);
    }
  }
  leftSide.push(...rightSide.reverse());
  if (leftSide[0]) {
    // close the polygon
    leftSide.push(leftSide[0]);
  }
  return leftSide;
}

export function generateBlockedEdgePoints(
  edgeCoordinates: [number, number, number][],
): [number, number, number][] {
  if (edgeCoordinates.length < 2) {
    return [];
  }
  const points = [];

  for (let i = 1; i < edgeCoordinates.length; ++i) {
    const from = edgeCoordinates[i - 1]!;
    const to = edgeCoordinates[i]!;
    const position = getMidPoint(from, to) as [number, number, number];
    points.push(position);
  }
  return points;
}

export function generateOnewayArrows(
  edgeCoordinates: [number, number, number][],
) {
  if (edgeCoordinates.length < 2) {
    return [];
  }
  const points = [];

  for (let i = 1; i < edgeCoordinates.length; ++i) {
    const from = edgeCoordinates[i - 1]!;
    const to = edgeCoordinates[i]!;
    const position = getMidPoint(from, to) as [number, number, number];
    const heading = computeHeading(from, to);
    points.push({ position, heading });
  }
  return points;
}
