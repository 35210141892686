@if (!selectedFrame) {
  <div class="collection-selection-title">Collection Info</div>
} @else {
  <div class="collection-selection-title">Frame Info</div>
}
<mat-divider />

<app-selected-item
  [metadataItem]="selectedFrame ?? collection"
  [selectedItem]="selectedFrame ?? collection"
  [previewImage]="selectedFrame?.url"
/>

@if (!selectedFrame) {
  <mat-divider />

  <div class="collection-selection-title">Datasets</div>
  <mat-divider />

  @if (datasetSyncConfigs && datasetSyncConfigs.length > 0) {
    <div class="collection-dataset-list-container">
      @for (datasetSyncConfig of datasetSyncConfigs; track datasetSyncConfig) {
        <div class="collection-selection-title">
          {{ datasetSyncConfig.datasetName }}

          <mat-icon
            class="delete-dataset-button"
            (click)="deleteCollectionFromDataset(datasetSyncConfig.id)"
            >clear</mat-icon
          >
        </div>
      }
    </div>
    <mat-divider />
  }

  <div class="collection-dataset-input-padding">
    <input
      matInput
      placeholder="Dataset name"
      [value]="datasetName"
      (input)="updateDatasetName($any($event.target).value)"
      class="collection-add-dataset-input"
    />

    <selection-dropbox
      class="collection-add-dataset-type-input"
      label="Label types"
      [options]="labelNames"
      [(selected)]="selectedLabelName"
      (selectedChange)="updateInput()"
      [customInput]="true"
    />
  </div>

  <button
    mat-icon-button
    (click)="addCollectionToDataset()"
    [disabled]="!isDataComplete"
  >
    <mat-icon>add</mat-icon>
  </button>

  <mat-divider />

  <div class="collection-selection-title">Additional Properties</div>

  <mat-divider />

  <div>
    <mat-slide-toggle
      class="mat-slide-toggle-margin"
      [checked]="collection.locked"
      (change)="setCollectionLocked($event.checked)"
      >Locked</mat-slide-toggle
    >
  </div>
}
