import { EdgeElement, ElementType } from '@cartken/map-types';
import { toGoogleLatLng } from '../../../utils/geo-tools';
import { MapElementManager } from './map-element-manager';

export async function computeDrivingDirections(
  edge: EdgeElement,
  mapElementManager: Pick<MapElementManager, 'getMapElement'>,
) {
  const directionsService = new google.maps.DirectionsService();
  const startNode = mapElementManager.getMapElement(
    edge.properties.startNodeId,
  );
  const endNode = mapElementManager.getMapElement(edge.properties.endNodeId);
  if (
    startNode?.elementType !== ElementType.NODE ||
    endNode?.elementType !== ElementType.NODE
  ) {
    // prettier-ignore
    throw new Error(`Unsupported edge nodes in computeDrivingDirections, got ${startNode?.elementType} and ${endNode?.elementType}.`);
  }
  const fromLocation = toGoogleLatLng(startNode.geometry.coordinates);
  const toLocation = toGoogleLatLng(endNode.geometry.coordinates);
  const routingRequest: google.maps.DirectionsRequest = {
    origin: fromLocation,
    destination: toLocation,
    travelMode: google.maps.TravelMode.DRIVING,
    provideRouteAlternatives: false,
  };

  const directions = {
    distance: 0,
    duration: 0,
    path: [] as google.maps.LatLng[],
  };

  await new Promise<void>((resolve, _) => {
    directionsService.route(routingRequest, (result, status) => {
      if (result && status === google.maps.DirectionsStatus.OK) {
        const route = result.routes[0]?.legs[0];
        if (!route || !route.distance || !route.duration) {
          return;
        }
        directions.distance = route.distance.value;
        directions.duration = route.duration.value;
        directions.path.push(fromLocation);
        for (const step of route.steps) {
          for (const location of step.path) {
            directions.path.push(location);
          }
        }
        directions.path.push(toLocation);
      }
      resolve();
    });
  });
  return directions;
}
