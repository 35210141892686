import { Component, Input } from '@angular/core';

export type MetadataInfo = {
  label: string;
  value: string;
};

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.sass'],
  standalone: true,
  imports: [],
})
export class MetadataComponent {
  @Input()
  set item(item: Record<string, unknown> | undefined) {
    if (!item) {
      this.data = [];
      return;
    }
    this.data = Object.entries(item).map(
      ([label, value]): MetadataInfo => ({
        label,
        value: JSON.stringify(value),
      }),
    );
  }
  data: MetadataInfo[] = [];
}
