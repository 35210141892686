import { computed, signal, WritableSignal } from '@angular/core';

const { max, min } = Math;

/**
 * While dragging elements around, or changing edge width etc., users
 * want to see changes immediately, but commit to history only after the
 * "edit session" is done. For example, onDragEnd.
 */
export class ChangePreview<T> {
  constructor(
    private previewSet: WritableSignal<Map<ChangePreview<T>, T[]>>,
    private addChange: (changes: T[]) => void,
  ) {
    previewSet.update((p) => {
      p.set(this, []);
      return p;
    });
  }

  updatePreview(changes: T[]) {
    if (!this.previewSet().has(this)) {
      console.warn('Tried to updatePreview which does not exist anymore.');
      return;
    }
    this.previewSet.update((m) => {
      m.set(this, changes);
      return m;
    });
  }

  commit() {
    const changes = this.previewSet().get(this);
    if (!changes) {
      console.warn('Commited a change to history which did not exist anymore.');
      return;
    }
    this.previewSet.update((m) => {
      m.delete(this);
      return m;
    });
    this.addChange(changes);
  }

  discard() {
    if (!this.previewSet().has(this)) {
      // it was already discarded or committed
      return;
    }
    this.previewSet.update((m) => {
      m.delete(this);
      return m;
    });
  }
}

export class ChangeHistory<T, K> {
  private changeStack = signal<T[][]>([], { equal: () => false });
  private previewSet = signal(new Map<ChangePreview<T>, T[]>(), {
    equal: () => false,
  });
  private currentChangeIndex = signal(-1);

  readonly numChanges = computed(() => {
    return this.currentChangeIndex() + 1;
  });

  readonly hasChanges = computed(() => {
    return this.currentChangeIndex() >= 0;
  });

  readonly undoAvailable = computed(() => {
    return this.currentChangeIndex() >= 0;
  });

  readonly redoAvailable = computed(() => {
    return this.currentChangeIndex() < this.changeStack().length - 1;
  });

  constructor(
    private key: (item: T) => K,
    private isLocked: () => boolean,
  ) {}

  addChange(changes: T[]) {
    if (this.isLocked()) {
      return;
    }
    if (changes.length === 0) {
      return;
    }
    const i = this.currentChangeIndex() + 1;
    this.changeStack.update((p) => {
      p.splice(i, Infinity, changes);
      return p;
    });
    this.currentChangeIndex.set(i);
  }

  startChangePreview() {
    if (this.isLocked()) {
      return;
    }
    return new ChangePreview(this.previewSet, (change) =>
      this.addChange(change),
    );
  }

  undo() {
    this.currentChangeIndex.update((p) => max(-1, p - 1));
  }

  redo() {
    this.currentChangeIndex.update((p) =>
      min(this.changeStack().length - 1, p + 1),
    );
  }

  currentChanges() {
    const changes = new Map<K, T>();
    const stack = this.changeStack();
    const preview = this.previewSet();
    const maxIndex = this.currentChangeIndex();

    for (let i = 0; i <= maxIndex; ++i) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const change = stack[i]!;
      for (const item of change) {
        changes.set(this.key(item), item);
      }
    }

    for (const change of preview.values()) {
      for (const item of change) {
        changes.set(this.key(item), item);
      }
    }

    return changes;
  }

  clear() {
    this.currentChangeIndex.set(-1);
    this.changeStack.set([]);
  }
}
