import { Component, input, computed, output } from '@angular/core';
import { DataItem } from '../data-viewer/data-viewer.service';
import { PickIconComponent } from './pick-icon.component';
import { DataViewerTabName } from '../data-viewer/data-viewer-state.service';

@Component({
  selector: 'app-grid-img-item',
  templateUrl: './grid-img-item.component.html',
  styleUrls: ['./grid-img-item.component.sass'],
  standalone: true,
  imports: [PickIconComponent],
})
export class GridImgItemComponent<T extends DataItem> {
  item = input.required<T>();
  itemType = input.required<DataViewerTabName>();
  isSelected = input(false);
  isPicked = input(false);
  pickable = input(false);
  pickClicked = output<T>();

  url = computed(() => {
    const item = this.item();
    if ('url' in item) {
      return item.url;
    } else {
      return item.tileUrl;
    }
  });
}
