import { Observable, combineLatest, of } from 'rxjs';
import { Robot } from '../backend/robot.dto';
import { map, takeUntil } from 'rxjs/operators';
import { Finalizable } from '@/utils/finalizable';

export class EnabledArrivedButton extends Finalizable {
  readonly enabledArrivedButton$: Observable<boolean>;

  constructor(
    isInControl$: Observable<boolean>,
    robotState$: Observable<Robot>,
    isAlive$: Observable<boolean>,
  ) {
    super();
    this.enabledArrivedButton$ =
      combineLatest([isInControl$, robotState$, isAlive$]).pipe(
        takeUntil(this.finalized$),
        map(([inControl, robot, isAlive]) => {
          return !robot.arrivedAtStop && (inControl || !isAlive);
        }),
      ) ?? of(false);
  }

  protected async onFinalize(): Promise<void> {
    // fullfil interface
  }
}
