<mat-card>
  <mat-card-title>Route Testing</mat-card-title>
  <mat-card-content class="container">
    <app-select-operation-button
      [selectedOperationId]="limitToOperationId()"
      (selectedOperationChange)="limitToOperationId.set($event?.id)"
    />
    <mat-form-field>
      <input
        #fromLocationElement
        matInput
        placeholder="From (left click)"
        [value]="formatLatLngAltString(fromLocation())"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        #toLocationElement
        matInput
        placeholder="To (right click)"
        [value]="formatLatLngAltString(toLocation())"
      />
    </mat-form-field>
    <span>{{ routeInfo() }}</span>
  </mat-card-content>
</mat-card>
