import { Component, input, output } from '@angular/core';
import { AprilTag } from '@cartken/map-types';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-april-tag-properties',
  standalone: true,
  templateUrl: './april-tag-properties.component.html',
  imports: [FormsModule, MatLabel, MatInput, MatFormField],
  host: { style: 'display: contents' },
})
export class AprilTagPropertiesComponent {
  aprilTag = input.required<AprilTag>();
  aprilTagChange = output<AprilTag>();

  setId(aprilTag: AprilTag, id: string) {
    this.aprilTagChange.emit({
      ...aprilTag,
      id: parseInt(id),
    });
  }
}
