import type {
  ComponentRef,
  EffectCleanupRegisterFn,
  ViewContainerRef,
} from '@angular/core';
import type {
  ClickEvent,
  PointerMoveEvent,
  StartDraggingEvent,
  StopDraggingEvent,
  DraggingEvent,
  Tooltip,
  ModeProps,
  GuideFeatureCollection,
  TentativeFeature,
  NonInteractiveFeatureCollection,
  Color,
} from './types';
import { getLineColor } from './visualization-styles';
import { MapElement } from '@cartken/map-types';

const DEFAULT_GUIDES: GuideFeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};
export const DEFAULT_NON_INTERACTIVE_FEATURES: NonInteractiveFeatureCollection =
  {
    type: 'FeatureCollection',
    features: [],
  };
const DEFAULT_TOOLTIPS: Tooltip[] = [];

export class InteractiveMode<
  C extends Record<string, unknown> = Record<string, unknown>,
> {
  setActive(active: boolean, subMode?: string) {
    // pass
  }

  shouldRenderSidebar(): boolean {
    return false;
  }

  renderSidebar(
    ref: ViewContainerRef,
    onCleanup: EffectCleanupRegisterFn,
  ): ComponentRef<unknown> | undefined {
    return undefined;
  }

  // Return features that can be used as a guide for editing the data
  getGuides(props: ModeProps<C>): GuideFeatureCollection {
    return DEFAULT_GUIDES;
  }

  getNonInteractiveFeatures(
    props: ModeProps<C>,
  ): NonInteractiveFeatureCollection {
    return DEFAULT_NON_INTERACTIVE_FEATURES;
  }

  getTooltips(props: ModeProps<C>): Tooltip[] {
    return DEFAULT_TOOLTIPS;
  }

  createTentativeFeature(props: ModeProps<C>): TentativeFeature | undefined {
    return undefined;
  }

  getMapElementColor(m: MapElement): Color {
    return getLineColor(m, false);
  }

  getGlobalElementsOpacity(): number {
    return 1;
  }

  getCursor(): string {
    return 'not-allowed';
  }

  // Called when the left mouse button went down and up without dragging regardless of whether something was picked
  onLeftClick(event: ClickEvent, props: ModeProps<C>): void {
    // pass
  }
  // Called when the right mouse button went down and up without dragging regardless of whether something was picked
  onRightClick(event: ClickEvent, props: ModeProps<C>): void {
    // pass
  }
  // Called when the pointer moved, regardless of whether the pointer is down, up, and whether something was picked
  onHover(event: PointerMoveEvent, props: ModeProps<C>): void {
    // pass
  }
  // Called when the pointer went down on something rendered by this layer and the pointer started to move
  onDragStart(event: StartDraggingEvent, props: ModeProps<C>): void {
    // pass
  }
  // Called when the pointer went down on something rendered by this layer, the pointer moved, and now the pointer is up
  onDragEnd(event: StopDraggingEvent, props: ModeProps<C>): void {
    // pass
  }
  // Called when the pointer went down and is moving, regardless of whether something was picked
  onDrag(event: DraggingEvent, props: ModeProps<C>): void {
    // pass
  }
  // Called when any key went up.
  onKeyUp(event: KeyboardEvent, props: ModeProps<C>): void {
    // pass
  }
}
