import { RoutesService } from '@/app/core/route-service';
import {
  RouteDto,
  RouteVertexDto,
} from '@/app/core/robots-service/backend/types';
import {
  DEFAULT_NON_INTERACTIVE_FEATURES,
  InteractiveMode,
} from '../../visualization/interactive-mode';
import { VisualizationManager } from '../../visualization/visualization-manager';
import {
  ClickEvent,
  ModeProps,
  NonInteractiveFeatureCollection,
} from '../../visualization/types';
import { firstValueFrom } from 'rxjs';
import { GeoPoint } from '@cartken/map-types';
import { hasAtLeastTwoElements } from '@/utils/typeGuards';
import { effect, signal, ViewContainerRef } from '@angular/core';
import { RoutingModeSidebarComponent } from './routing-mode-sidebar.component';

export class RoutingMode extends InteractiveMode {
  routeInfo = signal<string | undefined>(undefined);
  limitToOperationId = signal<string | undefined>(undefined);
  fromLocation = signal<GeoPoint | undefined>(undefined);
  toLocation = signal<GeoPoint | undefined>(undefined);

  private routeVisualization: NonInteractiveFeatureCollection =
    DEFAULT_NON_INTERACTIVE_FEATURES;

  constructor(
    private readonly visualizationManager: VisualizationManager,
    private readonly routesService: RoutesService,
  ) {
    super();
    effect(() => {
      this.updateRoute(this.fromLocation(), this.toLocation());
    });
  }

  override shouldRenderSidebar(): boolean {
    return true;
  }

  override renderSidebar(ref: ViewContainerRef) {
    const componentRef = ref.createComponent(RoutingModeSidebarComponent);

    componentRef.instance.visualizationManager = this.visualizationManager;
    componentRef.instance.limitToOperationId = this.limitToOperationId;
    componentRef.instance.fromLocation = this.fromLocation;
    componentRef.instance.toLocation = this.toLocation;
    componentRef.instance.routeInfo = this.routeInfo;

    return componentRef;
  }

  override getCursor(): string {
    return 'crosshair';
  }

  override getGlobalElementsOpacity(): number {
    return 0.1;
  }

  override getNonInteractiveFeatures(
    props: ModeProps,
  ): NonInteractiveFeatureCollection {
    return this.routeVisualization;
  }

  private getRouteInfo(route: RouteDto): string {
    const distance =
      route.distance >= 1000
        ? (route.distance / 1000).toFixed(1) + 'km'
        : route.distance.toFixed(0) + 'm';
    const duration =
      route.duration >= 60
        ? (route.duration / 60).toFixed(0) + 'min'
        : route.duration.toFixed(0) + 'sec';
    return `Distance: ${distance}, Duration: ${duration}`;
  }

  private createRouteVisualization(route: RouteVertexDto[]) {
    const coordinates = route.map((point): GeoPoint => {
      return [point.longitude, point.latitude, (point.altitude ?? 0) + 0.001];
    });
    if (!hasAtLeastTwoElements(coordinates)) {
      // prettier-ignore
      throw new Error(`Route has less than two elements, got ${coordinates}`)
    }
    this.routeVisualization = {
      type: 'FeatureCollection',
      features: [
        {
          geometry: {
            type: 'LineString',
            coordinates,
          },
          properties: {
            lineColor: [0x7f, 0x7f, 0xff, 0xff],
            lineWidth: 7,
          },
        },
      ],
    };
  }

  async updateRoute(
    fromLocation: GeoPoint | undefined,
    toLocation: GeoPoint | undefined,
  ) {
    if (!fromLocation || !toLocation) {
      this.routeVisualization = DEFAULT_NON_INTERACTIVE_FEATURES;
      this.visualizationManager.rerenderMapElements();
      return;
    }
    try {
      const routeDto = await firstValueFrom(
        this.routesService.getRoute(
          fromLocation,
          toLocation,
          this.limitToOperationId(),
        ),
      );

      if (!routeDto?.geometry) {
        this.routeInfo.set('Routing failed');
        return;
      }
      this.routeInfo.set(this.getRouteInfo(routeDto));
      this.createRouteVisualization(routeDto.geometry);
      this.visualizationManager.rerenderMapElements();
    } catch (e) {
      this.routeInfo.set(`Routing failed: ${e}`);
    }
  }

  override onLeftClick({ mapCoords }: ClickEvent, props: ModeProps): void {
    this.fromLocation.set(mapCoords);
  }

  override onRightClick({ mapCoords }: ClickEvent, props: ModeProps): void {
    this.toLocation.set(mapCoords);
  }
}
