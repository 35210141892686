import { combineLatest, takeUntil } from 'rxjs';
import { UserSessionService } from '@/app/core/user-session/user-session.service';
import { RobotActionRequestManager } from '@/app/core/robots-service/local-logic/action-request-manager';
import { BackendStatePolling } from './backend-state-polling';
import { Finalizable } from '@/utils/finalizable';
import { Robot } from './robot.dto';
import { VoidAsyncFunction } from '@/utils/type-utils';

const MIN_DISTANCE_UNSUPERVISED_TO_SHOW_SWAP_REQUEST = 10;
const MIN_DISTANCE_TO_CROSSING_TO_SHOW_SWAP_REQUEST = 10;

const SWAP_ACTION_ID_TYPE = 'swap-robot-action';

const REPEAT_AFTER_MILLIS = 10 * 1000;

function isCloseToCrossing(robot: Robot): boolean {
  if (!robot.routeMatch?.currentOrUpcomingCrossingId) {
    return false;
  }
  if (robot.routeMatch?.onCrossing) {
    return true;
  }
  const remainingDistanceToCrossing = robot.routeMatch?.distanceToCrossing;
  return (
    remainingDistanceToCrossing !== undefined &&
    remainingDistanceToCrossing < MIN_DISTANCE_TO_CROSSING_TO_SHOW_SWAP_REQUEST
  );
}

function isUnsupervisedAutonomyAvailable(robot: Robot): boolean {
  return Boolean(
    robot.unsupervisedAutonomyState?.available &&
      (robot.unsupervisedAutonomyState?.remainingDistance ?? -1) >
        MIN_DISTANCE_UNSUPERVISED_TO_SHOW_SWAP_REQUEST,
  );
}

interface RobotSwapRequestData {
  message: string;
  countDownStartMillis: number;
}

function getRobotSwapRequestDataForRobotState(
  robotState: Robot,
): RobotSwapRequestData {
  if (robotState.arrivedAtStop) {
    return {
      message: 'Parked robot will be skipped in',
      countDownStartMillis: 5 * 1000,
    };
  }
  if (isUnsupervisedAutonomyAvailable(robotState)) {
    return {
      message: 'Activating unsupervised autonomy. Will be skipped in',
      countDownStartMillis: 5 * 1000,
    };
  }

  return {
    message: 'Park robot safely for more urgent robot. Will be skipped in',
    countDownStartMillis: 10 * 1000,
  };
}

export class RobotSwapRequestsHandling extends Finalizable {
  private swapRobotId: string | null = null;
  private debounceUntilMillis: number | null = null;

  constructor(
    currentRobotId: string,
    userSessionService: UserSessionService,
    backendStatePolling: BackendStatePolling,
    robotActionRequestManager: RobotActionRequestManager,
    finalize: VoidAsyncFunction,
  ) {
    super();
    combineLatest([
      userSessionService.robotSwapRequests$,
      backendStatePolling.robotState$,
    ])
      .pipe(takeUntil(this.finalized$))
      .subscribe(([robotSwapRequests, robotState]) => {
        if (this.debounceUntilMillis && this.debounceUntilMillis > Date.now()) {
          return;
        }

        if (isCloseToCrossing(robotState)) {
          robotActionRequestManager.removeNotification(SWAP_ACTION_ID_TYPE);
          return;
        }

        const swapRobotId =
          robotSwapRequests.find(
            (swap) => swap.currentRobotId === currentRobotId,
          )?.newRobotId ?? null;

        if (this.swapRobotId === swapRobotId) {
          return;
        }

        if (this.swapRobotId !== null) {
          return;
        }
        this.swapRobotId = swapRobotId;

        const swapRequestNotificationData =
          getRobotSwapRequestDataForRobotState(robotState);

        robotActionRequestManager.notify({
          actionIdType: SWAP_ACTION_ID_TYPE,
          actionDescription: swapRequestNotificationData.message,
          actionButton: 'Keep robot',
          expiresAt: new Date(
            Date.now() + swapRequestNotificationData.countDownStartMillis,
          ),
          onExpire: async () => {
            const swapRobotId = this.swapRobotId;
            if (!swapRobotId) {
              return;
            }
            userSessionService.applyRobotSwapRequest(
              currentRobotId,
              swapRobotId,
            );
            await finalize();
          },
          onClick: async () => {
            this.swapRobotId = null;
            this.debounceUntilMillis = Date.now() + REPEAT_AFTER_MILLIS;
          },
        });
      });
  }

  protected override async onFinalize(): Promise<void> {
    // nothing to do
  }
}
