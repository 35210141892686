@let handoverLocation_ = handoverLocation();

<mat-form-field appearance="fill">
  <mat-label>Lookup Address</mat-label>
  <input
    #address
    matInput
    type="string"
    placeholder="400 Main Street #200, New York"
  />
  @if (lookingUpAddress) {
    <mat-spinner
      matSuffix
      [diameter]="18"
      style="float: right; margin-left: 8px"
    />
  }
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>Street Name</mat-label>
  <input
    matInput
    readonly
    [value]="handoverLocation_.properties.streetName ?? ''"
  />
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>Street Number</mat-label>
  <input
    matInput
    readonly
    [value]="handoverLocation_.properties.streetNumber ?? ''"
  />
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>Unit/Suite/Apt</mat-label>
  <input
    matInput
    readonly
    [value]="handoverLocation_.properties.subpremise ?? ''"
  />
</mat-form-field>
