import { CachedRoadEdge, ElementType, MapElement } from '@cartken/map-types';
import { MapElementManager } from './map-element-manager';
import { computeDrivingDirections } from './computeDrivingDirections';

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function computeCachedRoadEdges(
  mapElementManager: MapElementManager,
  progressCallback: (progress: number) => void,
) {
  // get total number of cached road edges to compute
  const cachedEdgesToCompute = [
    ...mapElementManager.getMapElements().values(),
  ].filter(
    (edge: MapElement): edge is CachedRoadEdge =>
      edge.elementType === ElementType.CACHED_ROAD_EDGE &&
      edge.properties.estimatedDuration === -1,
  );
  const numberOfCachedEdgesToCompute = cachedEdgesToCompute.length;
  console.log(`computing ${numberOfCachedEdgesToCompute} cached road edges`);
  let numberOfCachedEdgesComputed = 0;
  for (const edge of cachedEdgesToCompute) {
    const props = edge.properties;
    computeDrivingDirections(edge, mapElementManager).then((directions) => {
      props.estimatedDuration = directions.duration;
      props.length = directions.distance;
    });
    await delay(1000); // to avoid api rate limiter
    numberOfCachedEdgesComputed++;
    progressCallback(
      (numberOfCachedEdgesComputed / numberOfCachedEdgesToCompute) * 100,
    );
  }
}
