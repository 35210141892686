@let allInfrastructure = mapElementManager.infrastructure();
<!-- use @for to remount elements when selected element changes. Importantly, this resets inputs. -->
@for (
  selectedMapElement of [mapElementManager.selectedMapElement()];
  track selectedMapElement?.id
) {
  @if (selectedMapElement) {
    <mat-card-title>{{ selectedMapElement.elementType }}</mat-card-title>
    <mat-card-subtitle>id: {{ selectedMapElement.id }} </mat-card-subtitle>
    <mat-card-content>
      @if (isEdge(selectedMapElement)) {
        <app-edge-properties
          [edge]="selectedMapElement"
          [mapElementManager]="mapElementManager"
        />
      } @else if (selectedMapElement.elementType === 'OperationRegion') {
        <app-operation-region-properties
          [operationRegion]="selectedMapElement"
          (operationRegionChange)="addChange($event)"
        />
      } @else if (selectedMapElement.elementType === 'AprilTag') {
        <app-april-tag-properties
          [aprilTag]="selectedMapElement"
          (aprilTagChange)="addChange($event)"
        />
      } @else if (selectedMapElement.elementType === 'Infrastructure') {
        <app-infrastructure-properties
          [infrastructure]="selectedMapElement"
          [allInfrastructure]="allInfrastructure"
          (infrastructureChange)="addChange($event)"
        />
      } @else if (selectedMapElement.elementType === 'HandoverLocation') {
        <app-handover-location-properties
          [handoverLocation]="selectedMapElement"
          (handoverLocationChange)="addChange($event)"
        />
      } @else if (selectedMapElement.elementType === 'Pole') {
        <app-pole-properties
          [pole]="selectedMapElement"
          (poleChange)="addChange($event)"
        />
      }
    </mat-card-content>
  }
}
