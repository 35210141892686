import type { MapElementManager } from '../../map-elements/map-element-manager';
import {
  ReachableEdge,
  computeAllReachableEdges,
} from './reachable-edges-algorithms';
import { ElementType, isNode } from '@cartken/map-types';
import {
  DEFAULT_NON_INTERACTIVE_FEATURES,
  InteractiveMode,
} from '../../visualization/interactive-mode';
import {
  ClickEvent,
  ModeProps,
  NonInteractiveFeatureCollection,
} from '../../visualization/types';
import { zOffsetGeometry } from '../utils';
import type { VisualizationManager } from '../../visualization/visualization-manager';
import { effect, signal, ViewContainerRef } from '@angular/core';
import { ReachableEdgesSidebarComponent } from './reachable-edges-sidebar.component';

export class ReachableEdgesMode extends InteractiveMode {
  private reachableEdgeVisualizations: NonInteractiveFeatureCollection =
    DEFAULT_NON_INTERACTIVE_FEATURES;

  private maxDistanceMeters = signal(1500);
  private autonomyOnly = signal(false);
  private startNodeId = signal<number | undefined>(undefined);

  constructor(
    private readonly mapElementManager: MapElementManager,
    private readonly visualizationManager: VisualizationManager,
  ) {
    super();
    effect(() => {
      this.updateReachableEdges(
        this.startNodeId(),
        this.autonomyOnly(),
        this.maxDistanceMeters(),
      );
    });
  }

  override getCursor(): string {
    return this.mapElementManager.hoveredElement()?.elementType === 'Node'
      ? 'pointer'
      : 'grab';
  }

  override shouldRenderSidebar(): boolean {
    return true;
  }

  override renderSidebar(ref: ViewContainerRef) {
    const componentRef = ref.createComponent(ReachableEdgesSidebarComponent);
    componentRef.instance.autonomyOnly = this.autonomyOnly;
    componentRef.instance.maxDistanceMeters = this.maxDistanceMeters;

    return componentRef;
  }

  override setActive(active: boolean, subMode?: string | undefined): void {
    if (active) {
      this.startNodeId.set(undefined);
    }
  }

  override getNonInteractiveFeatures(
    props: ModeProps,
  ): NonInteractiveFeatureCollection {
    return this.reachableEdgeVisualizations;
  }

  override onLeftClick(event: ClickEvent, props: ModeProps) {
    const node = event.picks[0]?.object;
    if (!isNode(node)) {
      this.startNodeId.set(undefined);
      return;
    }
    this.startNodeId.set(node.id);
  }

  private updateReachableEdges(
    startNodeId: number | undefined,
    autonomyOnly: boolean,
    maxDistanceMeters: number,
  ) {
    if (startNodeId === undefined) {
      this.reachableEdgeVisualizations = DEFAULT_NON_INTERACTIVE_FEATURES;
      this.visualizationManager.rerenderMapElements();
      return;
    }
    const robotNodeGraph = new Map<number, Array<number>>(); // <nodeId: [RobotEdgeIds]>
    for (const [_, edge] of this.mapElementManager.getMapElements()) {
      if (
        edge.deleted === true ||
        (edge.elementType !== ElementType.INFRASTRUCTURE_EDGE &&
          edge.elementType !== ElementType.INFRASTRUCTURE_WAITING_EDGE &&
          edge.elementType !== ElementType.MOVABLE_PLATFORM_EDGE &&
          edge.elementType !== ElementType.ROBOT_EDGE &&
          edge.elementType !== ElementType.ROBOT_QUEUE_EDGE)
      ) {
        continue;
      }
      const p = edge.properties;
      robotNodeGraph.set(p.startNodeId, [
        ...(robotNodeGraph.get(p.startNodeId) ?? []),
        edge.id,
      ]);

      robotNodeGraph.set(p.endNodeId, [
        ...(robotNodeGraph.get(p.endNodeId) ?? []),
        edge.id,
      ]);
    }

    const reachableEdges = computeAllReachableEdges(
      startNodeId,
      this.mapElementManager.getMapElements(),
      robotNodeGraph,
      maxDistanceMeters,
      autonomyOnly,
    );

    this.reachableEdgeVisualizations = {
      type: 'FeatureCollection',
      features: [],
    };
    for (const reachableEdge of reachableEdges) {
      this.createReachableEdgeVisualization(reachableEdge);
    }
    this.visualizationManager.rerenderMapElements();
  }

  private createReachableEdgeVisualization(reachableEdge: ReachableEdge) {
    const normalizedDistance =
      reachableEdge.distance / this.maxDistanceMeters();
    this.reachableEdgeVisualizations.features.push({
      type: 'Feature',
      geometry: zOffsetGeometry(
        { type: 'LineString', coordinates: reachableEdge.coordinates },
        0.001,
      ),
      properties: {
        lineColor: [
          0,
          255 * (1 - normalizedDistance),
          255 * normalizedDistance,
          255,
        ],
        lineWidth: 9,
      },
    });
  }
}
