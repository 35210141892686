import { ElementType, MapElement } from '@cartken/map-types';
import { Subject } from 'rxjs';
import { isEdgeBlocked } from '../../map-editor/visualization/utils';

export interface BlockageUpdate {
  mapElementId: number;
  isBlocked: boolean;
  blockedUntil: Date | undefined;
}

export class BlockageManager {
  private changedMapElementIds = new Map<number, boolean>();
  private _onChange$ = new Subject<void>();
  readonly onChange$ = this._onChange$.asObservable();

  toggleBlockedProperty(mapElement: MapElement) {
    const mapElementId = mapElement.id;
    if (this.changedMapElementIds.has(mapElementId)) {
      this.changedMapElementIds.delete(mapElementId);
    } else {
      const isBlocked = isEdgeBlocked(mapElement);
      this.changedMapElementIds.set(mapElementId, !isBlocked);
    }
    this._onChange$.next();
  }

  reset() {
    this.changedMapElementIds.clear();
    this._onChange$.next();
  }

  applyChanges(mapElements: MapElement[]): MapElement[] {
    return mapElements.map((mapElement) => {
      const clone = structuredClone(mapElement);
      if (
        clone.elementType !== ElementType.ROBOT_EDGE ||
        !this.changedMapElementIds.has(clone.id)
      ) {
        return clone;
      }
      const props = clone.properties;

      if (!props) {
        return clone;
      }
      delete props.blockedUntil;
      if (isEdgeBlocked(mapElement)) {
        delete props.blockedAt;
      } else {
        props.blockedAt = new Date();
      }

      return clone;
    });
  }

  getChanges(): BlockageUpdate[] {
    return Array.from(this.changedMapElementIds.entries()).map(
      ([mapElementId, isBlocked]) => {
        return <BlockageUpdate>{ mapElementId, isBlocked };
      },
    );
  }
}
