import { Component, inject, input, output } from '@angular/core';
import { Infrastructure, InfrastructureType } from '@cartken/map-types';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CreateCustomFieldMapElementDialogComponent } from '../dialogs/create-custom-field-map-element-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { MatOption } from '@angular/material/core';
import { KeyValuePipe } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatMiniFabButton } from '@angular/material/button';
import { produce } from 'immer';

@Component({
  selector: 'app-infrastructure-properties',
  standalone: true,
  templateUrl: './infrastructure-properties.component.html',
  styleUrls: ['./infrastructure-properties.component.sass'],
  imports: [
    FormsModule,
    KeyValuePipe,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatMiniFabButton,
    MatOption,
    MatSelect,
  ],
})
export class InfrastructurePropertiesComponent {
  infrastructure = input.required<Infrastructure>();
  allInfrastructure = input.required<Infrastructure[]>();
  infrastructureChange = output<Infrastructure>();
  dialog = inject(MatDialog);

  infrastructureTypes = Object.values(InfrastructureType);

  deleteCustomFieldItem(infra: Infrastructure, deletedField: string) {
    this.infrastructureChange.emit(
      produce(infra, (draft) => {
        delete draft.properties.customFields[deletedField];
      }),
    );
  }

  async addCustomFieldItem(infra: Infrastructure) {
    const result = await lastValueFrom(
      this.dialog
        .open(CreateCustomFieldMapElementDialogComponent)
        .afterClosed(),
    );
    if (!result) {
      return;
    }
    const { key, value } = result;
    this.infrastructureChange.emit(
      produce(infra, (draft) => {
        draft.properties.customFields[key] = value;
      }),
    );
  }

  setExternalInfrastructureId(
    infra: Infrastructure,
    externalInfrastructureId: string,
  ) {
    this.infrastructureChange.emit(
      produce(infra, (draft) => {
        draft.properties.externalInfrastructureId = externalInfrastructureId;
      }),
    );
  }

  setInfrastructureType(
    infra: Infrastructure,
    infrastructureType: InfrastructureType,
  ) {
    this.infrastructureChange.emit(
      produce(infra, (draft) => {
        draft.properties.infrastructureType = infrastructureType;
      }),
    );
  }

  setParentInfrastructureId(
    infra: Infrastructure,
    parentInfrastructureId: number | undefined,
  ) {
    this.infrastructureChange.emit(
      produce(infra, (draft) => {
        draft.properties.parentInfrastructureId = parentInfrastructureId;
      }),
    );
  }
}
