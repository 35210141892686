import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  CollectionDto,
  DatasetSyncConfigDto,
  FrameDto,
  LabelTypeDto,
} from '../ml-data-types';
import { SelectedItemComponent } from '../shared/selected-item.component';
import { MlDataService } from '../ml-data.service';
import { MlActionService } from '../ml-action.service';
import { ErrorService } from '@/app/core/error-system/error.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialog,
  ConfirmationDialogData,
} from '@/app/core/confirmation-dialog/confirmation-dialog.component';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';

import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { SelectionDropboxComponent } from '@/app/core/selection-dropbox/selection-dropbox.component';
import { MatIconButton } from '@angular/material/button';
import { MatSlideToggle } from '@angular/material/slide-toggle';

const addCollectionErrorMessage = 'Failed to add collection to dataset';
const deleteCollectionErrorMessage = 'Failed to delete collection from dataset';

@Component({
  selector: 'app-collection-selection',
  templateUrl: './collection-selection.component.html',
  styleUrls: ['./collection-selection.component.sass'],
  standalone: true,
  imports: [
    MatDivider,
    SelectedItemComponent,
    MatIcon,
    MatInput,
    SelectionDropboxComponent,
    MatIconButton,
    MatSlideToggle,
  ],
})
export class CollectionSelectionComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  collection!: CollectionDto;

  @Input()
  selectedFrame?: FrameDto;

  private _destroy$ = new Subject<void>();

  datasetSyncConfigs?: DatasetSyncConfigDto[];
  labelNames: string[] = [];
  selectedLabelName?: string;
  datasetName: string = '';

  isDataComplete = false;

  private labels: LabelTypeDto[] = [];

  constructor(
    private readonly mlDataService: MlDataService,
    private readonly mlActionService: MlActionService,
    private readonly errorService: ErrorService,
    private readonly dialog: MatDialog,
  ) {}

  async ngOnInit() {
    this.mlActionService.collectionUpdated$
      .pipe(takeUntil(this._destroy$))
      .subscribe(async () => {
        if (this.collection) {
          const allCollections = await this.mlDataService.getCollections();
          this.collection = allCollections.filter(
            (collection) => collection.id == this.collection.id,
          )[0];
        }
      });
  }

  async ngOnDestroy() {
    this._destroy$.next();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const currentCollection: CollectionDto | undefined =
      changes['collection']?.currentValue;
    const previousCollection: CollectionDto | undefined =
      changes['collection']?.previousValue;

    if (currentCollection && currentCollection?.id !== previousCollection?.id) {
      await this.fetchDataSyncConfigs();
    }
  }

  updateInput() {
    this.isDataComplete =
      this.selectedLabelName !== undefined && this.datasetName !== '';
  }

  updateDatasetName(datasetName: string) {
    this.datasetName = datasetName;
    this.updateInput();
  }

  async addCollectionToDataset() {
    try {
      if (!this.selectedLabelName) {
        return;
      }
      const labelTypeId = this.labels.find(
        (label) => label.name === this.selectedLabelName,
      );
      if (!labelTypeId) {
        await this.mlActionService.addLabelType(this.selectedLabelName);
        await this.fetchDataSyncConfigs();
        await this.addCollectionToDataset();
        return;
      }
      const createSegmentAiSyncConfig = await firstValueFrom(
        this.dialog
          .open<ConfirmationDialog, ConfirmationDialogData>(
            ConfirmationDialog,
            {
              data: {
                message: `Are you sure you want to add collection to dataset?`,
              },
            },
          )
          .afterClosed(),
      );

      if (!createSegmentAiSyncConfig) {
        return;
      }

      await this.mlActionService.createSegmentsAiSyncConfig({
        datasetName: this.datasetName!,
        collectionId: this.collection.id,
        labelTypeId: this.labels.find(
          (label) => label.name === this.selectedLabelName,
        )!.id,
      });
      this.updateDatasetName('');
      await this.fetchDataSyncConfigs();
      this.errorService.dismissError(addCollectionErrorMessage);
    } catch (e) {
      this.errorService.reportError(addCollectionErrorMessage);
      console.error(addCollectionErrorMessage, e);
    }
  }

  async deleteCollectionFromDataset(datasetConfigId: number) {
    try {
      const deleteSegmentAiSyncConfig = await firstValueFrom(
        this.dialog
          .open<ConfirmationDialog, ConfirmationDialogData>(
            ConfirmationDialog,
            {
              data: {
                message: `Are you sure you want to remove collection from dataset?`,
              },
            },
          )
          .afterClosed(),
      );

      if (!deleteSegmentAiSyncConfig) {
        return;
      }

      await this.mlActionService.deleteSegmentsAiSyncConfig(datasetConfigId);
      await this.fetchDataSyncConfigs();
      this.errorService.dismissError(deleteCollectionErrorMessage);
    } catch (e) {
      this.errorService.reportError(deleteCollectionErrorMessage);
      console.error(deleteCollectionErrorMessage, e);
    }
  }

  private async fetchDataSyncConfigs() {
    this.labels = await this.mlDataService.getLabelTypes();
    this.labelNames = this.labels.map((label) => label.name);

    this.datasetSyncConfigs =
      await this.mlDataService.getSegmentsAiSyncConfigByCollectionId(
        this.collection.id,
      );
  }

  async setCollectionLocked(isLocked: boolean) {
    if (isLocked) {
      await this.mlActionService.addLockToCollection(this.collection.id);
    } else {
      await this.mlActionService.deleteLockFromCollection(this.collection.id);
    }
  }
}
