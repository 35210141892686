import {
  Component,
  EventEmitter,
  inject,
  input,
  Input,
  Output,
} from '@angular/core';
import {
  DataItem,
  DataViewerService,
} from '../data-viewer/data-viewer.service';

import { GridImgItemComponent } from './grid-img-item.component';
import { CAsyncPipe } from '@/utils/c-async-pipe';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.sass'],
  standalone: true,
  imports: [GridImgItemComponent, CAsyncPipe],
})
export class DataGridComponent<T extends DataItem> {
  dataViewerService = inject(DataViewerService);

  @Input()
  items: T[] = [];

  @Input()
  selectedItemId?: number;

  @Input()
  canFetchMore: boolean | undefined = true;

  pickable = input(false);

  @Output()
  itemClick = new EventEmitter<T>();

  @Output()
  pickItem = new EventEmitter<T>();

  @Output()
  nextPage = new EventEmitter<void>();

  isItemPicked(item: T) {
    return this.dataViewerService.isItemPicked(item.id);
  }

  constructor() {}
}
