<div class="canvas-container">
  <action-notification
    style="display: contents"
    [robotCommunication]="robotCommunication"
  />
  @if (
    (robotCommunication?.connected$ | async) &&
    (robotCommunication?.isOverlayMapEnabled$ | async)
  ) {
    <mini-map
      class="mini-map-container"
      [robotCommunication]="robotCommunication"
      [shouldRotate]="true"
      [shouldFollow]="true"
      [isCustomGpsClickEnabled]="false"
      [isDirectionsClickEnabled]="false"
      [shouldShowMapProviderSelection]="false"
      [shouldShowMiniMapActions]="false"
      [shouldShowZoomControl]="false"
      [shouldUIButtonsBeEnables]="false"
      [shouldShowPrecisePositionWarning]="false"
      [refreshTrigger]="
        activeControl && (robotCommunication?.connected$ | async)
      "
    />
  }

  <robot-video
    [active]="activeControl"
    [manualMouseControl]="robotCommunication?.isManualMouseControl$ | async"
    [robotStateReport]="robotStateReport$ | async"
    [userClaimReport]="userClaimReport$ | async"
    [attentionStatusList]="attentionStatusList"
    [statusStrings]="statusStrings$ | async"
    [manualControl]="manualControl$ | async"
    [robotState]="robotCommunication?.robotState$ | async"
    [connectionStats]="robotCommunication?.stats$ | async"
    [robotAttentionReport]="robotCommunication?.robotAttentionReport$ | async"
    [video]="robotCommunication?.videoElement"
    [isCharging]="isBatteryCharging$ | async"
    [batteryPercentage]="batteryPercentage$ | async"
    [supervisionState]="supervisionState$ | async"
    [operationEmergencyStopActive]="operationEmergencyStopActive$ | async"
    (mouseDriveEvent)="updateManualControl($event)"
    (lastMouseRelativePosition)="updateLastMouseRelativePosition($event)"
    (onStatusClick)="hideStatus($event)"
    class="robot-video"
  />
  <div class="status-bar">
    @if (robotCommunication?.robotState$ | async; as robotState) {
      @if (robotState.issues?.length) {
        <app-robot-issues-widget
          [issues]="robotState.issues ?? []"
          style="display: contents"
        />
      }
    }

    @if (robotCommunication?.isServingOrder$ | async) {
      <mat-icon class="order-icon">shopping_basket</mat-icon>
    }

    <div class="order-info">
      <order-overlay-info [order]="robotCommunication?.orders$ | async" />
    </div>

    <app-robot-blocking
      [blockedByCurrentUserForMillis]="
        robotCommunication?.isBlockedForMillis$ | async
      "
    />

    @if (microphoneEnabled$ | async) {
      <mat-icon class="order-icon">mic</mat-icon>
    }
    <app-unsupervised-autonomy-interaction
      [state]="robotCommunication?.unsupervisedAutonomyState$ | async"
    />
  </div>
  <div class="action-bar">
    @if (routeCorridorConfirmationState$ | async; as state) {
      <app-corridor-confirmation
        [state]="state"
        (confirmRouteCorridorEdgeId)="confirmRouteCorridorEdgeId($event)"
        (revokeRouteCorridorConfirmation)="
          revokeRouteCorridorConfirmation($event)
        "
      />
    }

    @if (
      robotCommunication?.routeToEndDistance$ | async;
      as distanceToEndOfRoute
    ) {
      <app-arrive-at-route-end
        [distanceToEndOfRoute]="distanceToEndOfRoute"
        (arrive)="arriveAtStop()"
      />
    }

    @if (robotCommunication?.robotState$ | async; as robotState) {
      <app-infrastructure-transaction
        [robotState]="robotState"
        (abortInfrastructureTransaction)="abortInfrastructureTransaction()"
      />
    }
  </div>
  @if (overlayIcon) {
    <div class="icon-overlay-container">
      <mat-icon class="icon-overlay">{{ overlayIcon }}</mat-icon>
    </div>
  }
  @if (isConnecting$ | async) {
    <div class="robot-loading">
      <mat-spinner />
    </div>
  }
</div>
