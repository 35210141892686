<app-toolbar style="height: fit-content">
  <ng-content ngProjectAs="menu">
    <button mat-menu-item [routerLink]="'/'">Home</button>
  </ng-content>
</app-toolbar>
<div class="page-inner-container">
  <app-left-sidebar (selectCollection)="setCollection($event)" />
  @if (selectedCollection) {
    <app-collection-grid
      [collection]="selectedCollection"
      [selectedFrameId]="selectedFrame?.id"
      (itemClick)="selectFrame($event)"
    />
    <app-collection-selection
      class="data-viewer-column"
      [collection]="selectedCollection"
      [selectedFrame]="selectedFrame"
    />
  } @else {
    <app-data-viewer />
    @if (dataViewerService.selectedItemId$ | async) {
      <app-data-viewer-selection />
    }
  }
</div>
