import { MapElement } from '@cartken/map-types';
import { Color } from './types';
import { isEdgeBlocked } from './utils';

export enum LayerName {
  COLOR = 'color',
  STEEPNESS = 'steepness',
  SEMANTIC = 'semantic',
  DIFF = 'diff',
}

export interface SlippyTilesStyle {
  opacity: number;
  zIndex: number;
}

export type SlippyTilesStyleMap = Record<LayerName, SlippyTilesStyle>;

export const slippyTilesStyles: SlippyTilesStyleMap = {
  color: { opacity: 0.0, zIndex: 1 },
  steepness: { opacity: 1.0, zIndex: 2 },
  semantic: { opacity: 0.2, zIndex: 3 },
  diff: { opacity: 0.0, zIndex: 4 },
};

const UNREACHABLE_COLOR: Color = [0xff, 0, 0, 0xff];
const SELECTED_COLOR: Color = [0, 200, 200, 0xff];
const NODE_COLOR: Color = [0, 0xff, 0, 0xff];
const OPERATION_REGION_COLOR: Color = [0xff, 0, 0xff, 0xff];
const BLOCKED_EDGE_COLOR: Color = [0xb7, 0, 0, 0xff];
const CROSSING_COLOR: Color = [0xff, 0x66, 0, 0xff];
const CROSSING_BUTTON_PRESS_REQUIRED_COLOR: Color = [0xcc, 0x00, 0xcc, 0xff];
const ROAD_EDGE_COLOR: Color = [0xff, 0, 0xff, 0xff];
const CACHED_ROAD_EDGE_COLOR: Color = [0xff, 0xbb, 0xff, 0xff];
const ROBOT_EDGE_QUEUE_COLOR: Color = [0xdd, 0x33, 0, 0xff];
const UNSUPERVISED_AUTONOMY_COLOR: Color = [0, 0, 0xff, 0.2 * 0xff];
const DEFAULT_COLOR: Color = [0xdd, 0xdd, 0, 0xff];
const POINT_FEATURE_COLOR: Color = [0, 0, 0, 0xff];

export const HIGHLIGHT_COLOR: Color = [0, 0xff, 0xff, 0xff];
export const HIGHLIGHT_DELETE_COLOR: Color = [0xff, 0, 0, 0xff];

export function getLineColor(m: MapElement, isSelected: boolean): Color {
  if (isSelected) {
    return SELECTED_COLOR;
  }
  if (m.elementType === 'Node') {
    return NODE_COLOR;
  }
  if (m.elementType === 'OperationRegion') {
    return OPERATION_REGION_COLOR;
  }
  if (isEdgeBlocked(m)) {
    return BLOCKED_EDGE_COLOR;
  }
  if (m.properties && 'crossing' in m.properties && m.properties.crossing) {
    if (m.properties.crossing.buttonPressRequired) {
      return CROSSING_BUTTON_PRESS_REQUIRED_COLOR;
    } else {
      return CROSSING_COLOR;
    }
  }
  if (m.elementType === 'CachedRoadEdge') {
    return CACHED_ROAD_EDGE_COLOR;
  }
  if (m.elementType === 'RoadEdge') {
    return ROAD_EDGE_COLOR;
  }
  if (m.elementType === 'RobotQueueEdge') {
    return ROBOT_EDGE_QUEUE_COLOR;
  }
  if (
    m.elementType === 'AprilTag' ||
    m.elementType === 'HandoverLocation' ||
    m.elementType === 'Mutex' ||
    m.elementType === 'Infrastructure'
  ) {
    return POINT_FEATURE_COLOR;
  }

  if (
    m.properties &&
    'allowUnsupervisedAutonomy' in m.properties &&
    m.properties.allowUnsupervisedAutonomy
  ) {
    return UNSUPERVISED_AUTONOMY_COLOR;
  }

  return DEFAULT_COLOR;
}

export function getCorridorColor(m: MapElement): Color {
  if (
    m.properties &&
    'allowUnsupervisedAutonomy' in m.properties &&
    m.properties.allowUnsupervisedAutonomy
  ) {
    return [0, 0, 255, 0.2 * 255];
  }
  return [0, 0, 0, 0.2 * 255];
}

const commonIcon = {
  width: 20,
  height: 20,
  anchorX: 10,
  anchorY: 10,
  mask: true,
};

export function getIcon(m: MapElement) {
  switch (m.elementType) {
    case 'AprilTag':
      return {
        ...commonIcon,
        id: m.elementType,
        url: 'assets/april-tag.svg',
      };
    case 'HandoverLocation':
      return {
        ...commonIcon,
        id: m.elementType,
        url: 'assets/handover-location.svg',
      };
    case 'Mutex':
      return {
        ...commonIcon,
        id: m.elementType,
        url: 'assets/mutex.svg',
      };
    case 'Infrastructure':
      return {
        ...commonIcon,
        id: m.elementType,
        url: 'assets/infra.svg',
      };
    case 'TrafficLight':
      return {
        ...commonIcon,
        id: m.elementType,
        mask: false,
        url: 'assets/traffic-light.svg',
      };
    default:
      return undefined;
  }
}
