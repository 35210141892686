import {
  EdgeElement,
  ElementType,
  GeoPoint,
  MapElement,
  NodeElement,
  RobotEdgeProperties,
  isEdge,
} from '@cartken/map-types';
import { MapElementManager } from '../map-elements/map-element-manager';
import {
  ClickEvent,
  GuideFeatureCollection,
  ModeProps,
  NonInteractiveFeatureCollection,
  Pick,
} from '../visualization/types';
import {
  getHandlesForPick,
  getUnderlyingFeaturePick,
} from '../visualization/utils';
import { InteractiveMode } from '../visualization/interactive-mode';
import { computeLength } from 'spherical-geometry-js';
import { hasAtLeastTwoElements } from '@/utils/typeGuards';
import type { ViewContainerRef } from '@angular/core';
import { PropertiesContainerComponent } from '../properties/properties-container.component';

export class SplitEdgeMode extends InteractiveMode {
  constructor(private readonly mapElementManager: MapElementManager) {
    super();
  }

  override getCursor(): string {
    const el = this.mapElementManager.hoveredElement();
    return el && isEdge(el) ? 'pointer' : 'grab';
  }

  override shouldRenderSidebar(): boolean {
    return this.mapElementManager.selectedMapElement() !== undefined;
  }

  override renderSidebar(ref: ViewContainerRef) {
    const componentRef = ref.createComponent(PropertiesContainerComponent);
    componentRef.instance.mapElementManager = this.mapElementManager;
    return componentRef;
  }

  override getGuides(props: ModeProps): GuideFeatureCollection {
    const guides: GuideFeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    const hoveredPick = props.lastHoverEvent?.picks[0];
    const underlyingFeaturePick = getUnderlyingFeaturePick(hoveredPick, props);

    if (underlyingFeaturePick && isEdge(underlyingFeaturePick.object)) {
      guides.features.push(...getHandlesForPick(underlyingFeaturePick));
    }

    return guides;
  }

  private pickIsEdgeVertex(hoveredPick: Pick, props: ModeProps) {
    const underlyingFeaturePick = getUnderlyingFeaturePick(hoveredPick, props);

    return (
      hoveredPick &&
      hoveredPick.isGuide &&
      underlyingFeaturePick &&
      isEdge(underlyingFeaturePick.object)
    );
  }

  override getNonInteractiveFeatures(
    props: ModeProps,
  ): NonInteractiveFeatureCollection {
    const hoveredPick = props.lastHoverEvent?.picks[0];
    if (!hoveredPick || !this.pickIsEdgeVertex(hoveredPick, props)) {
      return {
        type: 'FeatureCollection',
        features: [],
      };
    }

    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: hoveredPick.object.geometry,
          properties: {
            fillColor: [0, 0xff, 0, 0xff],
            lineColor: [0, 0xff, 0, 0xff],
            lineWidth: 5,
            radius: 7,
          },
        },
      ],
    };
  }

  override onLeftClick(event: ClickEvent, props: ModeProps) {
    const pick = event.picks[0];
    const mapElementPick = getUnderlyingFeaturePick(pick, props);

    if (mapElementPick?.object) {
      this.onMapElementClick(
        mapElementPick.object,
        pick?.object?.properties?.positionIndexes?.[0],
      );
    }
  }

  private onMapElementClick(mapElement: MapElement, vertex?: number) {
    if (isEdge(mapElement) && vertex !== undefined) {
      this.splitEdge(mapElement, vertex);
    }
  }

  private splitEdge(
    edge: EdgeElement,
    edgeVertexIndex: number,
  ): NodeElement | undefined {
    const coords = edge.geometry.coordinates;
    const splitCoords = coords[edgeVertexIndex];
    if (!splitCoords?.length) {
      return;
    }
    const deletedEdge = structuredClone(edge);
    deletedEdge.deleted = true;

    // Replace existing edge with new node and edges.
    const newNode = this.createNode(splitCoords);
    const edge1 = this.createEdge(coords.slice(0, edgeVertexIndex + 1), {
      ...structuredClone(edge.properties),
      endNodeId: newNode.id,
    });
    const edge2 = this.createEdge(
      coords.slice(edgeVertexIndex, coords.length),
      {
        ...structuredClone(edge.properties),
        startNodeId: newNode.id,
      },
    );
    this.mapElementManager.history.addChange([
      newNode,
      edge1,
      edge2,
      deletedEdge,
    ]);
    return newNode;
  }

  private createNode(coordinates: GeoPoint): NodeElement {
    return {
      id: this.mapElementManager.generateMapElementId(),
      version: this.mapElementManager.baseMapElementsVersion() ?? 0,
      elementType: ElementType.NODE,
      geometry: {
        type: 'Point',
        coordinates,
      },
    };
  }

  private createEdge(
    coordinates: GeoPoint[],
    properties: Omit<RobotEdgeProperties, 'length'>,
  ): EdgeElement {
    if (!hasAtLeastTwoElements(coordinates)) {
      // prettier-ignore
      throw new Error(`Could not create an edge, edge does not have at least two coordinates. Got ${coordinates}`);
    }
    return {
      id: this.mapElementManager.generateMapElementId(),
      version: this.mapElementManager.baseMapElementsVersion() ?? 0,
      elementType: ElementType.ROBOT_EDGE,
      properties: {
        ...properties,
        length: computeLength(coordinates),
      },
      geometry: {
        type: 'LineString',
        coordinates,
      },
    };
  }
}
