import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export function provideIcons() {
  return {
    provide: MatIconRegistry,
    useFactory: (
      httpClient: HttpClient,
      sanitizer: DomSanitizer,
      document: Document,
      errorHandler: ErrorHandler,
    ) => {
      const iconRegistry = new MatIconRegistry(
        httpClient,
        sanitizer,
        document,
        errorHandler,
      );
      iconRegistry.addSvgIcon(
        'handover-location',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/handover-location.svg',
        ),
      );
      iconRegistry.addSvgIcon(
        'pole',
        sanitizer.bypassSecurityTrustResourceUrl('assets/pole.svg'),
      );
      iconRegistry.addSvgIcon(
        'traffic-light',
        sanitizer.bypassSecurityTrustResourceUrl('assets/traffic-light.svg'),
      );
      iconRegistry.addSvgIcon(
        'april-tag',
        sanitizer.bypassSecurityTrustResourceUrl('assets/april-tag.svg'),
      );
      iconRegistry.addSvgIcon(
        'operation-region',
        sanitizer.bypassSecurityTrustResourceUrl('assets/region.svg'),
      );
      iconRegistry.addSvgIcon(
        'edge',
        sanitizer.bypassSecurityTrustResourceUrl('assets/edge.svg'),
      );
      iconRegistry.addSvgIcon(
        'mutex',
        sanitizer.bypassSecurityTrustResourceUrl('assets/mutex.svg'),
      );
      return iconRegistry;
    },
    deps: [HttpClient, DomSanitizer, DOCUMENT, ErrorHandler],
  };
}
