@let region = operationRegion();

<div>
  area [sq m]:
  {{ getRegionPolygonArea(region.geometry) | number: '1.0-0' }}
</div>
<app-select-operation-button
  [selectedOperationId]="region.properties.operationId"
  (selectedOperationChange)="setOperationRegionId(region, $event?.id)"
/>
