<img
  class="img-placeholder"
  [class.is-selected-img-item]="isSelected()"
  [src]="url()"
  draggable="false"
/>
@if (pickable()) {
  <app-pick-icon
    (click)="pickClicked.emit(item()); $event.stopPropagation()"
    [isPicked]="isPicked()"
    class="grid-img-picked"
  />
}
@if (itemType() === 'frames') {
  <div class="item-type-marker">F</div>
}
@if (itemType() === 'snippets') {
  <div class="item-type-marker">S</div>
}
