@let infrastructure_ = infrastructure();

<mat-form-field appearance="fill">
  <mat-label>External ID</mat-label>
  <input
    matInput
    type="text"
    placeholder=""
    [value]="infrastructure_.properties.externalInfrastructureId ?? ''"
    (change)="
      setExternalInfrastructureId(infrastructure_, $any($event.target).value)
    "
  />
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>Infrastructure Type</mat-label>
  <mat-select
    [value]="infrastructure_.properties.infrastructureType"
    (selectionChange)="setInfrastructureType(infrastructure_, $event.value)"
  >
    @for (infrastructureType of infrastructureTypes; track infrastructureType) {
      <mat-option [value]="infrastructureType">
        {{ infrastructureType }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>Parent Infrastructure ID</mat-label>
  <mat-select
    [value]="infrastructure_.properties.parentInfrastructureId"
    (selectionChange)="setParentInfrastructureId(infrastructure_, $event.value)"
  >
    <mat-option [value]="undefined"> - </mat-option>

    @for (parentCandidate of allInfrastructure(); track parentCandidate.id) {
      @if (parentCandidate.id !== infrastructure_.id) {
        <mat-option [value]="parentCandidate.id">
          {{ parentCandidate.properties.infrastructureType }}:
          {{ parentCandidate.id }}
        </mat-option>
      }
    }
  </mat-select>
</mat-form-field>
<div class="custom-fields">
  @for (
    item of infrastructure_.properties.customFields | keyvalue;
    track item.key
  ) {
    <div class="custom-field-item">
      <span class="custom-field-key">{{ item.key }}</span> :
      <span class="custom-field-value">{{ item.value }}</span>
      <span class="custom-field-delete-button">
        <mat-icon
          (click)="deleteCustomFieldItem(infrastructure_, $any(item.key))"
        >
          delete
        </mat-icon>
      </span>
    </div>
  }
  <button
    mat-mini-fab
    color="primary"
    (click)="addCustomFieldItem(infrastructure_)"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
