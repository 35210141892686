import {
  CompositeLayer,
  CompositeLayerProps,
  DefaultProps,
  Layer,
  LayersList,
  UpdateParameters,
} from '@deck.gl/core';
import { LocalizationMapTilesProperties } from '@cartken/map-types';
import { HeightMap, Texture } from './utils';
import { LocalizationMapTileLevelLayer } from './localization-map-tile-level-layer';
import { LayerName } from '../visualization-styles';
import { loadTileInWorker } from './loadTile';

type NamedTexture = { layerName: LayerName; image: Texture };

export type TileLevelData = {
  heightMap: HeightMap;
  textureLayers: NamedTexture[];
  cellSize: number;
};

const defaultProps: DefaultProps<LocalizationMapTileLayerProps> = {
  tileIndex: { type: 'array', value: [0, 0], compare: true },
  data: {
    type: 'object',
    value: undefined,
    compare: -1,
  },
  layerOpacities: {
    type: 'object',
    value: { color: 0, diff: 0, semantic: 0, steepness: 0 },
    compare: -1,
  },
  minDisplayAltitude: { type: 'number', value: 0.0 },
  maxDisplayAltitude: { type: 'number', value: Infinity },
};

export type LocalizationMapTileLayerProps = _LocalizationMapTileLayerProps &
  CompositeLayerProps;

type _LocalizationMapTileLayerProps = {
  tileIndex: [number, number];
  data: LocalizationMapTilesProperties | undefined;
  layerOpacities: Record<LayerName, number>;
  minDisplayAltitude?: number;
  maxDisplayAltitude?: number;
};

export type LatLngHeight = [number, number, number];

/**
 * Returns true if any opacity changed from, or to, zero.
 */
function layerVisibilityChanged(
  a: Record<string, number>,
  b: Record<string, number>,
) {
  for (const key of Object.keys(a)) {
    if ((a[key] === 0) !== (b[key] === 0)) {
      return true;
    }
  }
  return false;
}

export class LocalizationMapTileLayer<
  ExtraPropsT extends Record<string, unknown> = Record<string, unknown>,
> extends CompositeLayer<
  ExtraPropsT & Required<_LocalizationMapTileLayerProps>
> {
  static override defaultProps = defaultProps;
  static override layerName = 'LocalizationMapTileLayer';
  declare state: {
    tileLevels?: TileLevelData[];
    tileOrigin?: [number, number, number];
  };

  override updateState({
    props,
    oldProps,
    changeFlags,
  }: UpdateParameters<this>): void {
    const shouldReload =
      props.tileIndex[0] !== oldProps.tileIndex?.[0] ||
      props.tileIndex[1] !== oldProps.tileIndex?.[1] ||
      layerVisibilityChanged(props.layerOpacities, oldProps.layerOpacities) ||
      changeFlags.dataChanged;

    if (shouldReload) {
      this.loadTile(); // is not awaited, cannot throw
    }

    const shouldUpdateVisualization =
      props.maxDisplayAltitude !== oldProps.maxDisplayAltitude ||
      props.minDisplayAltitude !== oldProps.minDisplayAltitude ||
      props.layerOpacities !== oldProps.layerOpacities;

    if (shouldUpdateVisualization) {
      this.setNeedsUpdate();
    }
  }

  private async loadTile() {
    const { data, tileIndex, layerOpacities } = this.props;
    if (!data) {
      return;
    }
    const {
      tilesBaseUrl,
      layerNames,
      tilesOriginLatitude,
      tilesOriginLongitude,
      tilesOriginAltitude,
      tilesSize,
    } = data;
    try {
      const result = await loadTileInWorker(
        {
          tilesBaseUrl,
          layerNames,
          tilesOriginLatitude,
          tilesOriginLongitude,
          tilesOriginAltitude,
          tilesSize,
        },
        tileIndex,
        layerOpacities,
      );
      this.setState(result);
    } catch (e) {
      console.error('Error loading localization map tile data', e);
    }
  }

  override renderLayers(): Layer | LayersList | null {
    const { layerOpacities, minDisplayAltitude, maxDisplayAltitude } =
      this.props;
    const { tileLevels, tileOrigin } = this.state;

    if (!tileLevels || !tileOrigin) {
      return null;
    }

    return tileLevels.map((heightTileData, i) => {
      const textureOpacities = heightTileData.textureLayers.map(
        ({ layerName }) => layerOpacities[layerName],
      );

      return new LocalizationMapTileLevelLayer(
        this.getSubLayerProps({
          id: i.toString(),
        }),
        {
          data: heightTileData.heightMap,
          textures: heightTileData.textureLayers.map((t) => t.image),
          textureOpacities,
          tileOrigin,
          cellSize: heightTileData.cellSize,
          minDisplayAltitude,
          maxDisplayAltitude,
        },
      );
    });
  }
}
