import { Injectable } from '@angular/core';
import * as v from 'valibot';
import { MlBackendService } from '../core/ml-backend.service';
import { firstValueFrom, map } from 'rxjs';
import { pageToLimitOffset } from './page-to-limit-offset';
import {
  CollectionDto,
  DatasetSyncConfigDto,
  FrameDto,
  LabelTypeDto,
  SnippetDto,
} from './ml-data-types';
import { vParsePretty } from '@/utils/valibot-parse-pretty';
import { ErrorService } from '@/app/core/error-system/error.service';

export const PAGE_SIZE = 52;
export const SORTING_ORDER = ['newest', 'oldest', 'random'] as const;
export type DataSortingOrder = (typeof SORTING_ORDER)[number];

@Injectable()
export class MlDataService {
  constructor(
    private mlBackendService: MlBackendService,
    private errorService: ErrorService,
  ) {}

  getFrames(
    page?: number,
    searchString?: string,
    storingOrder: DataSortingOrder = 'newest',
  ): Promise<FrameDto[]> {
    const { limit, offset } = pageToLimitOffset(page, PAGE_SIZE);
    const baseUrl = `/frames?limit=${limit}&offset=${offset}&sorting-order=${storingOrder}`;
    const url = searchString
      ? `${baseUrl}&search-string=${searchString}`
      : baseUrl;

    return firstValueFrom(
      this.mlBackendService.get(url).pipe(
        map((x) => vParsePretty(v.array(FrameDto), x)),
        this.errorService.handleStreamErrors('Could not fetch frames.'),
      ),
    );
  }

  getFrameById(frameId: number): Promise<FrameDto> {
    return firstValueFrom(
      this.mlBackendService
        .get(`/frames?search-string=frames.id=${frameId}`)
        .pipe(
          map((x) => vParsePretty(v.array(FrameDto), x)),
          map(([frame]) => {
            if (!frame) {
              throw new Error(`Frame with id=${frameId} does not exist.`);
            }
            return frame;
          }),
          this.errorService.handleStreamErrors(
            `Frame with id=${frameId} does not exist.`,
          ),
        ),
    );
  }

  getAllSnippetFrames(snippetId: number): Promise<FrameDto[]> {
    return firstValueFrom(
      this.mlBackendService
        .get(`/frames?search-string=frames.snippet_id=${snippetId}`)
        .pipe(
          map((x) => vParsePretty(v.array(FrameDto), x)),
          this.errorService.handleStreamErrors(
            'Could not fetch snippet frames.',
          ),
        ),
    );
  }

  getSnippets(
    page?: number,
    searchString?: string,
    storingOrder: DataSortingOrder = 'newest',
  ): Promise<SnippetDto[]> {
    const { limit, offset } = pageToLimitOffset(page, PAGE_SIZE);
    const baseUrl = `/snippets?limit=${limit}&offset=${offset}&sorting-order=${storingOrder}`;
    const url = searchString
      ? `${baseUrl}&snippet-search-string=${searchString}`
      : baseUrl;

    return firstValueFrom(
      this.mlBackendService.get(url).pipe(
        map((x) => vParsePretty(v.array(SnippetDto), x)),
        this.errorService.handleStreamErrors('Could not fetch snippets.'),
      ),
    );
  }

  getSnippetById(snippetId: number): Promise<SnippetDto> {
    return firstValueFrom(
      this.mlBackendService
        .get(`/snippets?snippet-search-string=snippets.id=${snippetId}`)
        .pipe(
          map((x) => vParsePretty(v.array(SnippetDto), x)),
          map(([snippet]) => {
            if (!snippet) {
              throw new Error(`Snippet with id=${snippetId} does not exist.`);
            }
            return snippet;
          }),
          this.errorService.handleStreamErrors(
            `Snippet with id=${snippetId} does not exist.`,
          ),
        ),
    );
  }

  async getCollections(page?: number): Promise<CollectionDto[]> {
    const { limit, offset } = pageToLimitOffset(page, PAGE_SIZE);
    const url = `/collections?limit=${limit}&offset=${offset}`;

    const collections = await firstValueFrom(
      this.mlBackendService.get(url).pipe(
        map((x) => vParsePretty(v.array(CollectionDto), x)),
        this.errorService.handleStreamErrors('Could not fetch collections.'),
      ),
    );

    collections.sort((a, b) => {
      if (a.creationTimestamp > b.creationTimestamp) {
        return 1;
      }
      if (a.creationTimestamp < b.creationTimestamp) {
        return -1;
      }
      return 0;
    });

    return collections;
  }

  getCollectionsFrames(
    collectionId: number,
    page?: number,
  ): Promise<FrameDto[]> {
    const { limit, offset } = pageToLimitOffset(page, PAGE_SIZE);
    const url = `/collections/${collectionId}/frames?limit=${limit}&offset=${offset}`;

    return firstValueFrom(
      this.mlBackendService.get(url).pipe(
        map((x) => vParsePretty(v.array(FrameDto), x)),
        this.errorService.handleStreamErrors(
          'Could not fetch collection frames.',
        ),
      ),
    );
  }

  async getSegmentsAiSyncConfigByCollectionId(
    collectionId: number,
  ): Promise<DatasetSyncConfigDto[]> {
    const url = `/labeling-providers/segments-ai/sync-configs?collection-id=${collectionId}`;
    return firstValueFrom(
      this.mlBackendService.get(url).pipe(
        map((x) => vParsePretty(v.array(DatasetSyncConfigDto), x)),
        this.errorService.handleStreamErrors(
          `Could not Segments.ai sync configs for collection.id=${collectionId}.`,
        ),
      ),
    );
  }

  async getLabelTypes(): Promise<LabelTypeDto[]> {
    const url = `/label-types`;
    const labelsTypes = this.mlBackendService.get(url).pipe(
      map((x) => vParsePretty(v.array(LabelTypeDto), x)),
      this.errorService.handleStreamErrors('Could not fetch label types.'),
    );
    return firstValueFrom(labelsTypes);
  }
}
