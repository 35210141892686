import { effect } from '@angular/core';

function activeElementIsTextElement() {
  return ['input', 'textarea'].includes(
    document.activeElement?.tagName.toLowerCase() ?? '',
  );
}

export function keyboardShortcuts(
  shortcuts: Record<
    string,
    {
      down?: (event: KeyboardEvent) => void;
      up?: (event: KeyboardEvent) => void;
    }
  >,
) {
  const onKeyDown = (keyboardEvent: KeyboardEvent) => {
    // Suppress the key binding if input fields are active
    if (activeElementIsTextElement()) {
      return;
    }

    shortcuts[keyboardEvent.code]?.down?.(keyboardEvent);
  };

  const onKeyUp = (keyboardEvent: KeyboardEvent) => {
    // Suppress the key binding if input fields are active
    if (activeElementIsTextElement()) {
      return;
    }

    shortcuts[keyboardEvent.code]?.up?.(keyboardEvent);
  };

  effect((onCleanup) => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    onCleanup(() => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    });
  });
}
