<app-toolbar>
  <ng-content ngProjectAs="menu">
    <button mat-menu-item [routerLink]="''">Home</button>
  </ng-content>
</app-toolbar>

<div class="robot-filters">
  <h5 style="width: 10vw">
    {{ robotsGroupedByCrossing.length }} crossings active
  </h5>
  <mat-form-field style="width: 20vw">
    <mat-label>Set min standing duration in seconds</mat-label>
    <input
      matInput
      type="number"
      placeholder="Seconds"
      [(ngModel)]="standingDuration"
      (change)="refetch()"
    />
  </mat-form-field>
  <mat-form-field appearance="fill" class="operation-id-selection-form">
    <mat-label>Operations</mat-label>
    <mat-select
      multiple
      [(value)]="selectedOperationIds"
      (selectionChange)="refetch()"
    >
      @for (operationId of operationIds | async; track operationId) {
        <mat-option [value]="operationId">{{ operationId }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>

<div class="page-center">
  @if (focusOnCrossingId) {
    <div class="map">
      <map
        [mapOptions]="googleMapOptions"
        (mapReady)="onGoogleMap($event)"
        class="inner-map"
      ></map>
    </div>
  }
  <div class="groups-content">
    @for (robotGroup of robotsGroupedByCrossing; track $index) {
      <div>
        @if (
          !focusOnCrossingId || focusOnCrossingId === robotGroup.crossingId
        ) {
          <div class="crossing">
            <div class="crossing-title">
              <button
                mat-mini-fab
                color="primary"
                style="margin-left: 16px"
                (click)="emitFocusChange(robotGroup)"
                [matTooltip]="
                  focusOnCrossingId
                    ? 'Crossing overview'
                    : 'Focus on single crossing'
                "
              >
                <mat-icon>zoom_in_map</mat-icon>
              </button>
              <h1>
                Crossing
                <a
                  mat-flat-button
                  href="/robots/supervise/{{
                    getRobotIdList(robotGroup.robots)
                  }}"
                  target="_blank"
                >
                  {{ robotGroup.crossingId }}
                </a>
                at {{ robotGroup.operationId }}
                @if (
                  robotGroup.maxStandstillDuration &&
                  robotGroup.maxStandstillDuration > 0
                ) {
                  <span>
                    with a max of
                    {{ robotGroup.maxStandstillDuration | number: '1.0-0' }} sec
                    standstill</span
                  >
                }
              </h1>
            </div>

            <div class="robots">
              @for (robot of robotGroup.robots; track robot.id) {
                <div>
                  <div class="robot">
                    <a href="/robots/supervise/{{ robot.id }}" target="_blank">
                      @if (robot.robotView) {
                        <img
                          [src]="transform(robot.robotView)"
                          class="robot-view"
                        />
                      }
                    </a>
                    <div class="robot-item-text">
                      {{ robot.displayName }}
                    </div>
                    <div class="robot-item-text">
                      {{ getDistanceToCrossingText(robot) }}
                    </div>

                    <div class="robot-item-text">
                      Supervised by {{ getOperatorDisplayName(robot) }}
                    </div>
                    @if (getStandStillDuration(robot); as standStill) {
                      <div class="robot-item-text">
                        Stands since {{ standStill | number: '1.0-0' }} sec
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
  </div>
</div>
