import { Component, WritableSignal } from '@angular/core';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  standalone: true,
  templateUrl: './reachable-edges-sidebar.component.html',
  styleUrl: './reachable-edges-sidebar.component.sass',
  imports: [
    MatCard,
    MatCardTitle,
    MatCardContent,
    MatInput,
    MatFormField,
    MatSlideToggle,
    MatLabel,
  ],
})
export class ReachableEdgesSidebarComponent {
  autonomyOnly!: WritableSignal<boolean>;
  maxDistanceMeters!: WritableSignal<number>;
}
