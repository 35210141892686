import { Component } from '@angular/core';
import {
  MatCardContent,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import { EdgePropertiesComponent } from './edge-properties.component';
import { OperationRegionPropertiesComponent } from './operation-region-properties.component';
import { AprilTagPropertiesComponent } from './april-tag-properties.component';
import { InfrastructurePropertiesComponent } from './infrastructure-properties.component';
import { HandoverLocationPropertiesComponent } from './handover-location-properties.component';
import { PolePropertiesComponent } from './pole-properties.component';
import { MapElementManager } from '../map-elements/map-element-manager';
import { isEdge, MapElement } from '@cartken/map-types';

@Component({
  selector: 'app-properties-container',
  standalone: true,
  templateUrl: './properties-container.component.html',
  styleUrls: ['./properties-container.component.sass'],
  imports: [
    MatCardContent,
    MatCardSubtitle,
    MatCardTitle,
    EdgePropertiesComponent,
    OperationRegionPropertiesComponent,
    AprilTagPropertiesComponent,
    InfrastructurePropertiesComponent,
    HandoverLocationPropertiesComponent,
    PolePropertiesComponent,
  ],
})
export class PropertiesContainerComponent {
  mapElementManager!: MapElementManager;

  isEdge = isEdge;

  addChange(mapElement: MapElement) {
    this.mapElementManager?.history.addChange([mapElement]);
  }
}
