<div class="container">
  <mat-form-field class="full-width">
    <mat-label>Display Name</mat-label>
    <input matInput [(ngModel)]="order.displayName" cdkFocusInitial />
  </mat-form-field>

  @if (data.useExternalId) {
    <mat-form-field class="full-width">
      <mat-label>External Order Id</mat-label>
      <input matInput [(ngModel)]="order.externalId" cdkFocusInitial />
    </mat-form-field>
  }

  <div class="divider-with-text-container">
    <div class="divider-with-text-line"><mat-divider /></div>
    <div class="divider-with-text-text mat-typography">Enter Pickup</div>
    <div class="divider-with-text-line"><mat-divider /></div>
  </div>
  <div class="handover-container">
    @if (pickups.length > 1) {
      <selection-dropbox
        [selected]="selectedPickup"
        [options]="pickupNames"
        (selectedChange)="selectPickup($event)"
        label="Select Pickup"
      />
    }

    @if (pickups.length > 1 && allowCustomPickupLocationsWithinBounds) {
      <p class="handover-text">or</p>
    }
    @if (allowCustomPickupLocationsWithinBounds) {
      <mat-form-field class="full-width">
        <input #pickupLocation matInput placeholder="Enter Pickup Address" />
      </mat-form-field>
    }
  </div>

  <div class="divider-with-text-container">
    <div class="divider-with-text-line"><mat-divider /></div>
    <div class="divider-with-text-text mat-typography">Enter Dropoff</div>
    <div class="divider-with-text-line"><mat-divider /></div>
  </div>
  <div class="handover-container">
    @if (dropoffs.length > 1) {
      <selection-dropbox
        [selected]="selectedDropoff"
        [options]="dropoffNames"
        (selectedChange)="selectDropoff($event)"
        label="Select Dropoff"
      />
    }
    @if (dropoffs.length > 1 && allowCustomDropoffLocationsWithinBounds) {
      <p class="handover-text">or</p>
    }
    @if (allowCustomDropoffLocationsWithinBounds) {
      <mat-form-field>
        <input #dropoffLocation matInput placeholder="Enter Dropoff Location" />
      </mat-form-field>
    }
  </div>

  <mat-divider class="full-width" style="margin: 10px" />

  @if (data.usePhoneNumber) {
    <form>
      @if (preferredCountryCodes.length > 0) {
        <mat-form-field class="full-width">
          <ngx-mat-intl-tel-input
            matInput
            [formControl]="phoneFormControl"
            inputPlaceholder="Phone number"
            [preferredCountries]="preferredCountryCodes"
            [enableSearch]="true"
          >
          </ngx-mat-intl-tel-input>
          <mat-icon matSuffix>phone</mat-icon>
          <mat-error> Please provide a valid phone number </mat-error>
        </mat-form-field>
      }
    </form>
  }

  @if (phoneFormControl.value) {
    <mat-form-field class="full-width">
      <mat-label>Select Phone Notification Method</mat-label>
      <mat-select [(ngModel)]="phoneNotificationMethod">
        @for (method of phoneNotificationMethods; track method) {
          <mat-option [value]="method">
            {{ method }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  <mat-form-field
    class="full-width"
    appearance="fill"
    [hideRequiredMarker]="true"
  >
    <mat-label>Email</mat-label>
    <input
      type="email"
      matInput
      [formControl]="emailFormControl"
      placeholder="Ex. mail@cartken.com"
    />
    <mat-icon matSuffix>mail</mat-icon>
    @if (emailFormControl.hasError('email')) {
      <mat-error> Please enter a valid email address </mat-error>
    }
  </mat-form-field>

  @if (robots.length > 0) {
    <mat-form-field class="full-width">
      <mat-label>Select Robot</mat-label>
      <mat-select [(ngModel)]="order.assignedRobotId">
        <mat-option [value]="undefined"> Auto Assign </mat-option>
        @for (robot of robots; track robot) {
          <mat-option [value]="robot.id">
            {{ robot.shortName || robot.displayName }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  @if (order.assignedRobotId && pickupImmediatelyEnabled) {
    <mat-slide-toggle [(ngModel)]="order.pickupImmediately">
      Load order immediately
    </mat-slide-toggle>
    <br />
  }

  <mat-slide-toggle [(ngModel)]="testOrder">Test Order</mat-slide-toggle>

  <div>
    <mat-divider class="full-width" style="margin: 10px" />
    <form class="form">
      <div mat-dialog-actions class="confirm-cancel-button">
        <button mat-button (click)="onCancelClick()">Cancel</button>
        <button
          mat-button
          [disabled]="!checkIfCanBeConfirmed()"
          (click)="onCreateClick()"
        >
          Confirm
        </button>
      </div>
    </form>
  </div>
</div>
