@let aprilTag_ = aprilTag();

<mat-form-field appearance="fill">
  <mat-label>Tag Id</mat-label>
  <input
    matInput
    type="number"
    placeholder="0"
    min="0"
    step="1"
    [value]="aprilTag_.properties.id"
    (change)="setId(aprilTag_, $any($event.target).value)"
  />
</mat-form-field>
