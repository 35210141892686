<app-toolbar>
  <ng-content ngProjectAs="menu">
    @for (page of accessibleRoutes | async; track page.path) {
      <button mat-menu-item [routerLink]="page.path">
        {{ page.displayName }}
      </button>
    }
  </ng-content>
</app-toolbar>

<button mat-raised-button (click)="switchSite()" id="site-switch-button">
  Switch to {{ switchSiteName }} <mat-icon>sync_alt</mat-icon>
</button>

<div class="flex-container">
  @for (page of accessibleRoutes | async; track page.path) {
    <button mat-stroked-button [routerLink]="page.path">
      {{ page.displayName }}
    </button>
  } @empty {
    <mat-card class="span-columns">
      <mat-card-content>
        You have no privileges. Please contact Cartken if this is incorrect.
      </mat-card-content>
    </mat-card>
  }
</div>
