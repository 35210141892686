<div class="mat-elevation-z8 ops-overview">
  <div class="overview-title">
    <mat-icon color="primary">location_on</mat-icon>

    <button mat-button [routerLink]="'/operations/' + operationId">
      {{ displayName }} | {{ operationId }}
    </button>
  </div>
  <div class="emergency-stop">
    <div
      [ngClass]="{
        'emergency-stop-active-text': emergencyStopActive,
        'emergency-text': !emergencyStopActive,
      }"
    >
      Emergency<br />
      Stop
      @if (emergencyStopActive) {
        <span>Active</span>
      }
    </div>
    <div
      [ngClass]="{
        'emergency-stop-active': emergencyStopActive,
        'emergency-button': !emergencyStopActive,
      }"
    >
      <button mat-icon-button (click)="onEmergencyButtonClicked()">
        <mat-icon>warning</mat-icon>
      </button>
    </div>
  </div>

  <hr class="splitter-line" />

  @let robots = robots$ | async;
  @let orders = orders$ | async;
  @let finishedOrders = finishedOrders$ | async;
  @let activeWaitingQueueNames = activeWaitingQueueNames$ | async;
  @let availableWaitingQueues = availableWaitingQueues$ | async;

  @if (robots) {
    <app-operation-robots-stats [robots]="robots" />
  }

  @if (orders && finishedOrders) {
    <app-operation-orders-stats
      [orders]="orders"
      [finishedOrders]="finishedOrders"
      [rejectedOrdersWarningThreshold]="rejectedOrdersWarningThreshold"
    />
  }
  @if (robots && activeWaitingQueueNames && availableWaitingQueues) {
    <app-waiting-queue-management
      [robots]="robots"
      [storageMapElement]="storageMapElement$ | async"
      [activeWaitingQueueNames]="activeWaitingQueueNames"
      [availableWaitingQueues]="availableWaitingQueues"
    />
  }
</div>
