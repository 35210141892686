import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { RobotSystemStatus } from '@/app/core/robots-service/backend/types';
import { RobotConnectionStats } from '@/app/core/robots-service/webrtc/types';
import { DecimalPipe } from '@angular/common';
import { BatteryStatusComponent } from '@/app/core/battery-status/battery-status.component';
import { MatIcon } from '@angular/material/icon';

type RobotSystemStatusText = {
  type: string;
  text: string;
  originalMessage: string;
};

@Component({
  selector: 'robot-text-layout',
  templateUrl: './robot-text-layout.component.html',
  styleUrls: ['./robot-text-layout.component.sass'],
  standalone: true,
  imports: [BatteryStatusComponent, MatIcon, DecimalPipe],
})
export class RobotTextLayoutComponent {
  @Input()
  batteryPercentage?: number;

  @Input()
  isCharging?: boolean;

  @Input()
  active = false;

  @Input()
  robotStateReport?: string;

  @Input()
  userClaimReport?: string;

  @Input()
  supervisionState?: string;

  @Input()
  operationEmergencyStopActive?: boolean;

  attentionStatusListTexts?: RobotSystemStatusText[];

  @Output()
  onStatusClick: EventEmitter<string> = new EventEmitter();

  @Input()
  set attentionStatusList(attentionStatusList: RobotSystemStatus[]) {
    const attentionStatusListTexts = attentionStatusList.map(
      (status): RobotSystemStatusText => ({
        text: `${(status.type ?? '')?.toLocaleUpperCase()} [${status.name}] ${
          status.message
        }`,
        originalMessage: status.message,
        type: status.type ?? 'attention',
      }),
    );
    this.attentionStatusListTexts = this.sortAttentionStatusList(
      attentionStatusListTexts,
    );
  }

  @Input()
  isOrderDetected?: boolean;

  @Input()
  statusStrings?: string[];

  @Input()
  robotState?: Robot;

  @Input()
  connectionStats?: RobotConnectionStats;

  @Input()
  robotAttentionReport?: string;

  private sortAttentionStatusList(
    attentionStatusList: RobotSystemStatusText[],
  ): RobotSystemStatusText[] {
    return attentionStatusList.sort(
      (s1: RobotSystemStatusText, s2: RobotSystemStatusText) => {
        const str1 = s1.text;
        const str2 = s2.text;
        return str1.localeCompare(str2);
      },
    );
  }
}
