import {
  getPickedExistingEditHandle,
  getHandlesForPick,
  getUnderlyingFeaturePick,
} from '../visualization/utils';
import {
  ModeProps,
  ClickEvent,
  GuideFeatureCollection,
  Color,
} from '../visualization/types';
import { MapElementManager } from '../map-elements/map-element-manager';
import { InteractiveMode } from '../visualization/interactive-mode';
import { removePosition } from './geometry-manipulation-utils';
import { generateChange } from '../map-elements/generate-change';
import { HIGHLIGHT_DELETE_COLOR } from '../visualization/visualization-styles';
import { MapElement } from '@cartken/map-types';
import { produce } from 'immer';

export class DeleteMode extends InteractiveMode {
  constructor(private readonly mapElementManager: MapElementManager) {
    super();
  }

  override getCursor(): string {
    return this.mapElementManager.hoveredElement() ? 'pointer' : 'grab';
  }

  override getMapElementColor(m: MapElement): Color {
    if (this.mapElementManager.hoveredElementId() === m.id) {
      return HIGHLIGHT_DELETE_COLOR;
    }
    return super.getMapElementColor(m);
  }

  override getGuides(props: ModeProps): GuideFeatureCollection {
    const hoveredPick = getUnderlyingFeaturePick(
      props.lastHoverEvent?.picks[0],
      props,
    );
    return {
      type: 'FeatureCollection',
      features: getHandlesForPick(hoveredPick),
    };
  }

  override onLeftClick(event: ClickEvent, props: ModeProps) {
    const el = event.picks[0];
    if (!el) {
      return;
    }
    const pickedExistingHandle = getPickedExistingEditHandle([el]);
    let changedElement: MapElement | undefined;
    if (pickedExistingHandle) {
      const { elementId, positionIndexes } = pickedExistingHandle.properties;
      if (!elementId) {
        return;
      }
      const element = this.mapElementManager.getMapElement(elementId);
      if (!element) {
        return;
      }
      if (positionIndexes) {
        changedElement = structuredClone(element);
        changedElement.geometry = removePosition(
          element.geometry,
          positionIndexes,
        ) as typeof element.geometry;
      }
    } else {
      const featureId = el.object?.id;
      const element = this.mapElementManager.getMapElement(featureId);
      if (!element) {
        return;
      }
      changedElement = produce(element, (draft) => {
        draft.deleted = true;
      });
    }
    if (!changedElement) {
      return;
    }

    generateChange(this.mapElementManager, changedElement)
      .then((changes) => {
        this.mapElementManager.history.addChange(changes);
      })
      .catch((e) => console.error(e));
  }
}
