import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { firstValueFrom, takeUntil, takeWhile, timer } from 'rxjs';
import { Location } from '@angular/common';
import { RobotIssue, RobotIssueSeverity } from '@/app/core/robot-issues';
import { focusLost$ } from '@/utils/page-visibility';
import { BackendActions } from '@/app/core/robots-service/backend/backend-actions';
import { ErrorService } from '@/app/core/error-system/error.service';
import { ToolbarComponent } from '@/app/core/toolbar/toolbar.component';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatTable,
  MatColumnDef,
  MatCellDef,
  MatCell,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import { DefaultPipe } from '@/app/core/pipes/default.pipe';
import { RobotsBackendService } from '@/app/core/robots-service/robots-backend.service';
import { BackendService } from '@/app/core/backend.service';

const issueReportUrl =
  'https://forms.clickup.com/9004035169/f/8caxb31-18284/FXS8PQO5NSAHF2H9FX?';

@Component({
  selector: 'app-field-ops-view',
  templateUrl: './field-ops-view.component.html',
  styleUrls: ['./field-ops-view.component.sass'],
  standalone: true,
  imports: [
    ToolbarComponent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatIconButton,
    MatSuffix,
    MatIcon,
    MatButton,
    MatSlideToggle,
    MatProgressSpinner,
    MatTable,
    MatColumnDef,
    MatCellDef,
    MatCell,
    MatRowDef,
    MatRow,
    DefaultPipe,
  ],
})
export class FieldOpsViewComponent implements OnDestroy {
  displayedColumns = ['id', 'name'];

  serialNumber?: number;
  robots: Robot[] = [];
  selectedRobot?: Robot;
  freewheeling = false;
  robotBackendActions?: BackendActions;

  constructor(
    private backendService: BackendService,
    private robotBackendService: RobotsBackendService,
    private readonly errorService: ErrorService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    firstValueFrom(this.robotBackendService.getRobots()).then((robots) => {
      this.robots = robots;
      this.updateSerialNumber(this.serialNumber?.toString());
    });

    this.route.queryParams.subscribe(async (params) => {
      this.updateSerialNumber(params['serial-number']);
    });
  }

  ngOnDestroy(): void {
    this.freewheeling = false;
  }

  updateSerialNumber(serialNumberString?: string) {
    const serialNumber = Number(serialNumberString);
    this.serialNumber = Number.isInteger(serialNumber)
      ? serialNumber
      : undefined;
    this.location.replaceState(`field-ops?serial-number=${this.serialNumber}`);
    this.selectedRobot = this.robots.find(
      (robot) => robot.serialNumber === this.serialNumber,
    );
    this.freewheeling = false;
    this.robotBackendActions = undefined;
    if (this.selectedRobot) {
      this.robotBackendActions = new BackendActions(
        this.selectedRobot?.id,
        this.backendService,
        this.errorService,
      );
    }
  }

  superviseRobot() {
    if (!this.selectedRobot) {
      return;
    }
    this.router.navigate(['/robots/supervise/', this.selectedRobot.id], {
      queryParams: { active: this.selectedRobot.id },
    });
  }

  openIssue(issue: RobotIssue) {
    const issueId = issue.customId || issue.id;
    window.location.href = 'https://app.clickup.com/t/9004035169/' + issueId;
  }

  reportIssue() {
    if (!this.selectedRobot) {
      return;
    }

    const operationParams = this.selectedRobot.assignedOperationId
      ? `Operation%20ID=${this.selectedRobot.assignedOperationId}&`
      : '';
    const serialNumber = `Cart%20%23=${this.selectedRobot.serialNumber}&`;
    window.location.href = issueReportUrl + serialNumber + operationParams;
  }

  async unlockRobot() {
    this.robotBackendActions?.openAllCompartments();
  }

  async startFreewheeling() {
    timer(0, 500)
      .pipe(
        takeUntil(focusLost$),
        takeWhile(() => this.freewheeling && !!this.robotBackendActions),
      )
      .subscribe({
        next: () => this.robotBackendActions?.freewheel(),
        complete: () => {
          this.freewheeling = false;
        },
      });
  }

  getIssueColor(issue: RobotIssue): string {
    switch (issue.severity) {
      case RobotIssueSeverity.BREAKING:
        return 'red';
      case RobotIssueSeverity.DEGRADED:
        return 'orange';
      case RobotIssueSeverity.INFORMATIONAL:
        return 'green';
      default:
        return 'magenta';
    }
  }
}
