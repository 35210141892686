<app-toolbar>
  @if (currentChangeset(); as changeset) {
    <div>
      {{ changeset.title }}
      <span [class.visually-hidden]="!mapElementManager?.history?.hasChanges()">
        *
      </span>
    </div>
  } @else if (mapElementManager?.history?.hasChanges()) {
    <button mat-raised-button (click)="saveChangeset()">
      Create Changeset
    </button>
  }
  @if (mapElementManager?.history?.hasChanges() || currentChangeset()) {
    <button
      mat-icon-button
      color="primary"
      (pointerdown)="startShowingBaseMap($event)"
      (pointerup)="stopShowingBaseMap()"
      matTooltip="Temporarily show base map. (B)"
    >
      @if (showBaseMap()) {
        <mat-icon>visibility_off</mat-icon>
      } @else {
        <mat-icon>visibility</mat-icon>
      }
    </button>
  } @else {
    <button
      mat-icon-button
      (click)="viewOperationRegion()"
      matTooltip="View Operation Region"
    >
      <mat-icon>search</mat-icon>
    </button>
  }

  <ng-content ngProjectAs="menu">
    <button mat-menu-item (click)="viewOperationRegion()">
      View Operation Region
    </button>
    <button mat-menu-item (click)="viewLocation()">View Location</button>
    <button
      mat-menu-item
      (click)="changeMapVersion()"
      [disabled]="currentChangeset()"
    >
      Change Map Version
    </button>
    <mat-divider />
    <button mat-menu-item (click)="loadChangeset()">Load Changeset</button>
    <button
      mat-menu-item
      (click)="saveChangeset()"
      [disabled]="!mapElementManager?.history?.numChanges()"
    >
      Save Changeset
    </button>
    <button
      mat-menu-item
      (click)="rebaseChangeset()"
      [disabled]="
        !currentChangeset() ||
        currentChangeset()?.basedOnVersion === latestMapVersion() ||
        rebaseMode
      "
    >
      Rebase Changeset
    </button>
    <button
      mat-menu-item
      (click)="commitChangeset()"
      [disabled]="
        !currentChangeset() ||
        latestMapVersion() !== currentChangeset()?.basedOnVersion
      "
    >
      Commit Changeset
    </button>
    <button
      mat-menu-item
      (click)="closeChangeset()"
      [disabled]="!currentChangeset()"
    >
      Close Changeset
    </button>
    <button
      mat-menu-item
      (click)="deleteChangeset()"
      [disabled]="!currentChangeset()"
    >
      Delete Changeset
    </button>

    <mat-divider />
    <button mat-menu-item (click)="modeManager?.setMapEditorMode('routing')">
      Route Testing
    </button>
    <button
      mat-menu-item
      (click)="modeManager?.setMapEditorMode('reachability')"
    >
      Analyze Reachability
    </button>
    <button
      mat-menu-item
      (click)="deployCurrentMapVersion()"
      [disabled]="
        mapElementManager?.baseMapElementsVersion() === deployedMapVersion()
      "
    >
      Deploy Current Map Version
    </button>
    <mat-divider />
    <button mat-menu-item (click)="importMapElements(true)">
      Import As New Map Elements
    </button>
    <button mat-menu-item (click)="importMapElements(false)">
      Import And Update Map Elements
    </button>
    <button mat-menu-item (click)="exportAllCurrentMapElements()">
      Export All Current Map Elements
    </button>
    <button mat-menu-item (click)="exportChangedMapElements()">
      Export Changed Map Elements
    </button>
  </ng-content>
</app-toolbar>

<mat-drawer-container>
  <mat-drawer-content>
    <div #mapContainer id="mapContainer"></div>

    @if (mapElementManager?.loading()) {
      <mat-spinner matSuffix [diameter]="200" class="loading-spinner" />
    }

    <div class="map-display-elements-container">
      <div class="map-style-buttons">
        <mat-button-toggle-group
          value="light"
          (change)="setMapStyle($event.value)"
          [hideSingleSelectionIndicator]="true"
          aria-label="Map Style"
          matTooltip="Pick map style"
        >
          <mat-button-toggle value="light">Light</mat-button-toggle>
          <mat-button-toggle value="roadmap">Roadmap</mat-button-toggle>
          <mat-button-toggle value="satellite">Satellite</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-slide-toggle
          (change)="enableAltitudeFlattening(!$event.checked)"
          matTooltip="Show 3D map or squash to 2D"
        >
          <b>3D</b>
        </mat-slide-toggle>
      </div>
      <mat-slider
        [min]="mapElementManager?.altitudeRange()?.min"
        [max]="mapElementManager?.altitudeRange()?.max"
        step="0.1"
        discrete
        class="altitude-range-picker"
        matTooltip="Altitude display range"
      >
        <input
          matSliderStartThumb
          [value]="minDisplayAltitude()"
          (input)="minDisplayAltitude.set($any($event.target).valueAsNumber)"
        />
        <input
          matSliderEndThumb
          [value]="maxDisplayAltitude()"
          (input)="maxDisplayAltitude.set($any($event.target).valueAsNumber)"
        />
      </mat-slider>
    </div>

    @if (modeManager?.modeChangesEnabled()) {
      <mat-button-toggle-group
        #group="matButtonToggleGroup"
        class="toolbar"
        vertical
        [hideSingleSelectionIndicator]="true"
        [value]="modeManager?.currentModeId()"
        (change)="modeManager?.setMapEditorMode($event.value)"
      >
        <mat-button-toggle
          value="select"
          matTooltip="Select and edit existing elements [esc]"
          matTooltipPosition="after"
        >
          <mat-icon>edit</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="delete"
          matTooltip="Delete elements [d]"
          matTooltipPosition="after"
        >
          <mat-icon>delete</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="diff"
          matTooltip="Diff view"
          matTooltipPosition="after"
        >
          <mat-icon>compare</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="createEdge"
          matTooltip="Add edges and nodes [e]"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="edge" />
        </mat-button-toggle>
        <mat-button-toggle
          value="splitEdge"
          matTooltip="Split edges [s]"
          matTooltipPosition="after"
        >
          <mat-icon>content_cut</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="createHandoverLocation"
          matTooltip="Add handover location [h]"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="handover-location" />
        </mat-button-toggle>
        <mat-button-toggle
          value="createOperationRegion"
          matTooltip="Add operation region [r]"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="operation-region" />
        </mat-button-toggle>
        <mat-button-toggle
          value="createMutex"
          matTooltip="Add a mutex [m]"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="mutex" />
        </mat-button-toggle>
        <mat-button-toggle
          value="createInfrastructure"
          matTooltip="Add infrastructure"
          matTooltipPosition="after"
        >
          <mat-icon>info_outline</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    }

    <button
      class="visibility-button"
      mat-mini-fab
      color="primary"
      (click)="onVisibilityClick()"
    >
      <mat-icon>visibility</mat-icon>
    </button>

    <img
      class="google-maps-button"
      src="assets/google_maps_icon.svg"
      alt="Google Maps"
      matTooltip="Open location in Google Maps"
      (click)="onGoogleMapsClick()"
    />

    @if (!rebaseMode) {
      <div class="undo-redo-buttons">
        <button
          mat-mini-fab
          color="primary"
          (click)="mapElementManager?.history?.undo()"
          [disabled]="!mapElementManager?.history?.undoAvailable()"
        >
          <mat-icon>undo</mat-icon>
        </button>

        <button
          mat-mini-fab
          color="primary"
          (click)="mapElementManager?.history?.redo()"
          [disabled]="!mapElementManager?.history?.redoAvailable()"
        >
          <mat-icon>redo</mat-icon>
        </button>
      </div>
    }

    @if (rebaseMode) {
      <div class="rebase-actions-container">
        <div class="rebase-action-buttons">
          <button
            mat-mini-fab
            color="primary"
            (click)="rebaseMode.previousConflict()"
            [disabled]="
              (rebaseMode.previousConflictAvailable$ | async) === false
            "
            matTooltip="Got to previous conflict"
            matTooltipPosition="above"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="rebaseMode.nextConflict()"
            [disabled]="(rebaseMode.nextConflictAvailable$ | async) === false"
            matTooltip="Got to next conflict"
            matTooltipPosition="above"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="exitRebaseMode()"
            matTooltip="Abort rebase"
            matTooltipPosition="above"
          >
            <mat-icon>close</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="saveRebaseToChangesetAndExit()"
            [disabled]="(rebaseMode.allConflictsResolved$ | async) === false"
            matTooltip="Save rebase to changeset and exit"
            matTooltipPosition="above"
          >
            <mat-icon>done</mat-icon>
          </button>
        </div>
        @if (rebaseMode.currentConflict$ | async; as currentConflict) {
          <div>
            <mat-button-toggle-group
              [value]="currentConflict.choice"
              [hideSingleSelectionIndicator]="true"
              (change)="rebaseMode.resolveCurrentConflict($event.value)"
              class="rebase-choice-buttons"
            >
              <mat-button-toggle
                value="ChangesetVersion"
                matTooltip="Take the current change from the changeset"
                matTooltipPosition="below"
                class="rebase-choice-button"
              >
                Mine
                <div class="choose-mine-circle"></div>
              </mat-button-toggle>
              <mat-button-toggle
                value="LatestVersion"
                matTooltip="Take the latest version from the map history and drop the current change"
                matTooltipPosition="below"
                class="rebase-choice-button"
              >
                Latest
                <div class="choose-latest-circle"></div>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        }
      </div>
    }
  </mat-drawer-content>

  @if (modeManager) {
    <mat-drawer
      mode="side"
      position="end"
      [opened]="modeManager.currentMode().shouldRenderSidebar()"
      style="padding: 12px; min-width: 280px"
    >
      <ng-container #sidebarContent />
    </mat-drawer>
  }
</mat-drawer-container>
