import { Injectable } from '@angular/core';
import * as v from 'valibot';
import { BackendService } from '../backend.service';
import { Robot } from './backend/robot.dto';
import { RouteDto } from './backend/types';
import { Order } from '@/app/core/order/order';
import { Observable, firstValueFrom, map, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Cache } from '@/utils/cache.decorator';
import { vParsePretty } from '@/utils/valibot-parse-pretty';
import { ErrorService } from '../error-system/error.service';

const VALIDATION_ERROR_MESSAGE =
  'Corrupted data in backend response. Validation failed.';

@Injectable()
export class RobotsBackendService {
  constructor(
    private readonly backendService: BackendService,
    private readonly errorService: ErrorService,
  ) {}

  getRobots(): Observable<Robot[]> {
    return this.backendService.get('/robots').pipe(
      map((x) => vParsePretty(v.array(Robot), x)),
      this.errorService.handleStreamErrors(VALIDATION_ERROR_MESSAGE),
    );
  }

  getRobot(robotId: string): Observable<Robot> {
    return this.backendService.get(`/robots/${robotId}`).pipe(
      map((x) => vParsePretty(Robot, x)),
      this.errorService.handleStreamErrors(VALIDATION_ERROR_MESSAGE),
    );
  }

  getRobotRoute(robotId: string): Observable<RouteDto> {
    return this.backendService.get<RouteDto>(
      `/robots/${robotId}/route`,
      undefined,
      (error: HttpErrorResponse): Observable<RouteDto> => {
        if (error.status === 404) {
          return of({ geometry: [], distance: 0, duration: 0 });
        }

        return throwError(() => error);
      },
    );
  }

  getRobotOrders(robotId: string): Observable<Order[]> {
    return this.backendService.get(`/orders/robot/${robotId}`);
  }

  getCrossingRobots(): Observable<Robot[]> {
    return this.backendService.get('/robots?crossing=true').pipe(
      map((x) => vParsePretty(v.array(Robot), x)),
      this.errorService.handleStreamErrors(VALIDATION_ERROR_MESSAGE),
    );
  }

  getRobotsAssignedToOperation(operationId: string) {
    return this.backendService
      .get(`/robots?assigned_operation_id=${operationId}`)
      .pipe(
        map((x) => vParsePretty(v.array(Robot), x)),
        this.errorService.handleStreamErrors(VALIDATION_ERROR_MESSAGE),
      );
  }

  async getCrossingRobotsForOperations(
    operationIds: string[],
  ): Promise<Robot[]> {
    const robotsPerOperation = await Promise.all(
      operationIds.map((operationId) =>
        firstValueFrom(
          this.backendService
            .get(`/robots?crossing=true&assigned-operation-id=${operationId}`)
            .pipe(
              map((x) => vParsePretty(v.array(Robot), x)),
              this.errorService.handleStreamErrors(VALIDATION_ERROR_MESSAGE),
            ),
        ),
      ),
    );
    return robotsPerOperation.flat();
  }

  getRobotsReadyForOrders(): Observable<Robot[]> {
    return this.backendService.get('/robots?is-ready-for-orders=true').pipe(
      map((x) => vParsePretty(v.array(Robot), x)),
      this.errorService.handleStreamErrors(VALIDATION_ERROR_MESSAGE),
    );
  }
}
