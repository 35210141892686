export async function saveJSONFile(filename: string, content: unknown) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(content)),
  );
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
