import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { lastValueFrom, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BackendService } from '@/app/core/backend.service';
import { Operation } from '@/app/operations/operation';
import {
  OperationQuickAddDialogComponent,
  OperationsQuickAddDialogData,
} from './operation-quick-add-dialog.component';
import { isDefined } from '@/utils/typeGuards';
import { ToolbarComponent } from '@/app/core/toolbar/toolbar.component';
import { MatMenuItem } from '@angular/material/menu';

import { OperationOverviewComponent } from './operation-overview.component';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { RobotsBackendService } from '../core/robots-service/robots-backend.service';

function isNotEmptyString(str: string) {
  return str !== '';
}

@Component({
  selector: 'app-operation-management',
  templateUrl: './operation-management.component.html',
  styleUrls: ['./operation-management.component.sass'],
  standalone: true,
  imports: [
    ToolbarComponent,
    MatMenuItem,
    RouterLink,
    OperationOverviewComponent,
    MatIconButton,
    MatIcon,
  ],
})
export class OperationManagementComponent implements OnDestroy {
  private destroyed$ = new Subject<void>();

  operations: Operation[] = [];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private backendService: BackendService,
    private robotsBackendService: RobotsBackendService,
    private router: Router,
  ) {
    this.route.paramMap
      .pipe(
        takeUntil(this.destroyed$),
        map((params) => {
          return (params.get('operation-ids') ?? '')
            .split(',')
            .filter(isNotEmptyString);
        }),
      )
      .subscribe(async (operationIds) => {
        if (operationIds.length === 0) {
          await this.selectActiveOperations();
        } else {
          this.updateOperations(operationIds);
        }
      });
  }

  async selectActiveOperations() {
    const robots = await lastValueFrom(
      this.robotsBackendService.getRobotsReadyForOrders(),
    );
    const operationsIds = new Set(
      robots.map((robot) => robot.assignedOperationId).filter(isDefined),
    );
    this.updateOperationsUrl(Array.from(operationsIds.values()));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(undefined);
  }

  openAddOperationsDialog() {
    const operationIds = this.operations.map(({ id }) => id);

    this.dialog
      .open<
        OperationQuickAddDialogComponent,
        OperationsQuickAddDialogData,
        string[]
      >(OperationQuickAddDialogComponent, {
        data: {
          selectedOperationIds: operationIds.length > 0 ? operationIds : null,
        },
      })
      .afterClosed()
      .subscribe(async (newlySelectedOperationIds) => {
        if (newlySelectedOperationIds) {
          this.updateOperationsUrl(newlySelectedOperationIds);
        }
      });
  }

  async updateOperations(selectedOperationIds: string[]) {
    const selectedOperationIdsSet = new Set(selectedOperationIds);
    const allOperations = await lastValueFrom(
      this.backendService.get<Operation[]>('/operations'),
    );
    this.operations = allOperations.filter((operation) =>
      selectedOperationIdsSet.has(operation.id),
    );
    this.updateOperationsUrl(selectedOperationIds);
  }

  private updateOperationsUrl(selectedOperationIds: string[]) {
    this.router.navigateByUrl(
      `/operation-management/${selectedOperationIds.join(',')}`,
    );
  }
}
