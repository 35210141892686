import { MapElementManager } from '../map-elements/map-element-manager';
import type { MapEditorMode } from './mode-manager';
import { ElementType, PointGeometryElement } from '@cartken/map-types';
import {
  ClickEvent,
  ModeProps,
  TentativeFeature,
} from '../visualization/types';
import { InteractiveMode } from '../visualization/interactive-mode';
import { ViewContainerRef } from '@angular/core';
import { PropertiesContainerComponent } from '../properties/properties-container.component';

function getElementType(mode?: MapEditorMode) {
  switch (mode) {
    case 'createHandoverLocation':
      return ElementType.HANDOVER_LOCATION;
    case 'createInfrastructure':
      return ElementType.INFRASTRUCTURE;
    case 'createMutex':
      return ElementType.MUTEX;
    default:
      return undefined;
  }
}

export class CreatePointFeatureMode extends InteractiveMode {
  private active = false;
  private elementType?: ReturnType<typeof getElementType>;

  constructor(private mapElementManager: MapElementManager) {
    super();
  }

  override shouldRenderSidebar(): boolean {
    return this.mapElementManager.selectedMapElement() !== undefined;
  }

  override getCursor(): string {
    return 'crosshair';
  }

  override renderSidebar(ref: ViewContainerRef) {
    const componentRef = ref.createComponent(PropertiesContainerComponent);
    componentRef.instance.mapElementManager = this.mapElementManager;
    return componentRef;
  }

  override setActive(active: boolean, mode?: MapEditorMode) {
    this.active = active;
    this.elementType = getElementType(mode);
    if (this.active) {
      this.mapElementManager.selectElementById(undefined);
    }
  }

  override createTentativeFeature(
    props: ModeProps,
  ): TentativeFeature | undefined {
    const lastCoords = props.lastHoverEvent?.mapCoords;
    if (!lastCoords) {
      return undefined;
    }
    return {
      type: 'Feature',
      properties: {
        guideType: 'tentative',
      },
      geometry: {
        type: 'Point',
        coordinates: lastCoords,
      },
    };
  }

  override onLeftClick({ mapCoords }: ClickEvent, props: ModeProps): void {
    if (!this.elementType) {
      return;
    }
    const pointFeature: PointGeometryElement = {
      id: this.mapElementManager.generateMapElementId(),
      version: this.mapElementManager.baseMapElementsVersion() ?? 0,
      elementType: this.elementType,
      geometry: { type: 'Point', coordinates: mapCoords },
      // TODO: use properly initialized properties
      properties: {} as any,
    };
    this.mapElementManager.history.addChange([pointFeature]);
    this.mapElementManager.selectElementById(pointFeature.id);
  }
}
