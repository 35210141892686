import { Order, OrderStatus } from '@/app/core/order/order';
import { filterOnlineRobots } from '@/app/robots/robot-operation/robot-operator-view/robot-quick-add-dialog.component';
import {
  HazardLightsState,
  Compartment,
  Robot,
  CompartmentState,
} from '@/app/core/robots-service/backend/robot.dto';

function lidStatusToIconName(compartments: Compartment[]) {
  if (compartments.some((c) => c.state === CompartmentState.OPEN)) {
    return 'arrow_upward';
  }

  if (
    compartments.some((c) => c.state === CompartmentState.CLOSED_AND_UNLOCKED)
  ) {
    return 'lock_open';
  }

  if (
    compartments.every((c) => c.state === CompartmentState.CLOSED_AND_LOCKED)
  ) {
    return 'lock';
  }

  return 'no_encryption';
}

export class RobotInfo {
  robot: Robot;
  isOnline!: boolean;
  robotId: string;
  batteryPercentage!: number;
  isCharging!: boolean;
  hasOrders = false;
  name!: string;
  robotReadyForOrders!: boolean;
  displayColor!: string;
  handoverInProgress!: boolean;
  hazardLightsState!: HazardLightsState;
  isVirtualRobot?: boolean;
  isWaitingForHandover = false;
  compartments: Compartment[];
  lidStatusIconName: string = 'lock';

  constructor(robot: Robot) {
    this.robotId = robot.id;
    this.robot = robot;
    this.compartments = robot.compartments ?? [];
    this.updateRobot(robot);
  }

  updateRobot(robot: Robot, orders: Order[] = []) {
    this.name = robot.shortName ?? 'Nobody';
    this.hazardLightsState = robot.hazardLightsState || HazardLightsState.AUTO;
    this.isVirtualRobot = robot.isVirtualRobot;
    this.batteryPercentage = robot.batteryPercentage ?? 0;
    this.isCharging = robot.isCharging ?? false;
    this.robotReadyForOrders = !!robot.readyForOrders;

    const ordersForThisRobot = orders.filter(
      (order) => order.assignedRobotId === this.robotId,
    );
    this.hasOrders = robot.assignedOrderIds.length > 0;
    this.isWaitingForHandover = ordersForThisRobot.some(
      (order) => order.status === OrderStatus.WAITING_FOR_HANDOVER,
    );

    this.isOnline = filterOnlineRobots([robot]).includes(robot);
    this.compartments = robot.compartments ?? [];
    this.robot = robot;

    this.lidStatusIconName = lidStatusToIconName(this.compartments);
  }
}
