import { Component, OnDestroy } from '@angular/core';
import { DataSortingOrder, SORTING_ORDER } from '../ml-data.service';
import { DataItem, DataViewerService } from './data-viewer.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  MassActionFrameDialog,
  MassActionFrameDialogData,
} from '../shared/mass-action-frame-dialog/mass-action-frame-dialog.component';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { FilterSelectorComponent } from './filter-selector.component';
import { PickLabelComponent } from '../shared/pick-label.component';
import { MatButton } from '@angular/material/button';
import { SortControlComponent } from '../shared/sort-control.component';
import { MatDivider } from '@angular/material/divider';
import { DataGridComponent } from '../shared/data-grid.component';
import { CAsyncPipe } from '@/utils/c-async-pipe';

@Component({
  selector: 'app-data-viewer',
  templateUrl: './data-viewer.component.html',
  styleUrls: ['./data-viewer.component.sass'],
  standalone: true,
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    FilterSelectorComponent,
    PickLabelComponent,
    MatButton,
    SortControlComponent,
    MatDivider,
    CAsyncPipe,
    DataGridComponent,
  ],
})
export class DataViewerComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();
  selectedItemIndex = 10;

  constructor(
    readonly dataViewerService: DataViewerService,
    private matDialog: MatDialog,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  sortingOptions = SORTING_ORDER;

  updateSortingOrder(sortingOrder: DataSortingOrder) {
    this.dataViewerService.setSortingOrder(sortingOrder);
  }

  openMassActionFramesDialog() {
    const items = this.dataViewerService.items$.value;
    this.matDialog.open<MassActionFrameDialog, MassActionFrameDialogData>(
      MassActionFrameDialog,
      {
        data: {
          frames: items.filter((item) =>
            this.dataViewerService.isItemPicked(item.id),
          ),
        },
      },
    );
  }

  selectItem(item: DataItem) {
    this.dataViewerService.selectItemId(item);
  }

  pickItem(item: DataItem) {
    this.dataViewerService.toggleItemPick(item);
  }

  async nextPage() {
    this.dataViewerService.getNextPage();
  }
}
