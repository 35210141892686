import {
  MapElement,
  isNode,
  isEdge,
  ElementType,
  NodeElement,
  GeoPoint,
  RoadEdge,
  CachedRoadEdge,
} from '@cartken/map-types';
import { updateEdgeLength } from './edge';
import { MapElementManager } from './map-element-manager';
import { computeDrivingDirections } from './computeDrivingDirections';
import { hasAtLeastTwoElements } from '@/utils/typeGuards';

export type MapElementManagerForGenerateChange = Pick<
  MapElementManager,
  'connectedEdges' | 'getMapElement'
>;

function createDeletedNodeIfOrphan(
  manager: MapElementManagerForGenerateChange,
  nodeId: number,
): MapElement | undefined {
  const node = manager.getMapElement(nodeId);
  if (!node || !isNode(node)) {
    return undefined;
  }
  if (manager.connectedEdges(nodeId).length <= 1) {
    const deletedNode = structuredClone(node);
    deletedNode.deleted = true;
    return deletedNode;
  }
  return undefined;
}

async function updateRoadEdge(
  mapElementManager: MapElementManagerForGenerateChange,
  roadEdge: RoadEdge | CachedRoadEdge,
) {
  const props = roadEdge.properties;
  props.estimatedDuration = -1;
  if (roadEdge.elementType === ElementType.CACHED_ROAD_EDGE) {
    return;
  }
  try {
    const directions = await computeDrivingDirections(
      roadEdge,
      mapElementManager,
    );
    if (directions) {
      props.estimatedDuration = directions.duration;
      props.length = directions.distance;
      const coords = directions.path.map(
        (latLng): GeoPoint => [latLng.lng(), latLng.lat()],
      );
      if (!hasAtLeastTwoElements(coords)) {
        // prettier-ignore
        throw new Error(`Expected directions.path to have at least two points, got ${coords.length}.`)
      }
      roadEdge.geometry.coordinates = coords;
    }
  } catch (error) {
    console.warn(error);
  }
}

async function generateNodeDerivedChanges(
  manager: MapElementManagerForGenerateChange,
  node: NodeElement,
): Promise<MapElement[]> {
  const derivedChanges: MapElement[] = [];
  const coords = node.geometry.coordinates;
  for (const clonedEdge of manager.connectedEdges(node.id)) {
    if (node.deleted) {
      clonedEdge.deleted = true;
    }
    if (clonedEdge.properties.startNodeId === node.id) {
      clonedEdge.geometry.coordinates[0] = coords;
      const derivedChange = await generateChange(manager, clonedEdge);
      derivedChanges.push(...derivedChange);
    }
    if (clonedEdge.properties.endNodeId === node.id) {
      clonedEdge.geometry.coordinates[
        clonedEdge.geometry.coordinates.length - 1
      ] = coords;
      const derivedChange = await generateChange(manager, clonedEdge);
      derivedChanges.push(...derivedChange);
    }
  }
  return derivedChanges;
}

export async function generateChange(
  mapElementManager: MapElementManagerForGenerateChange,
  mapElement: MapElement,
): Promise<MapElement[]> {
  const clonedMapElement = structuredClone(mapElement);
  const change = [clonedMapElement];

  if (isNode(clonedMapElement)) {
    change.push(
      ...(await generateNodeDerivedChanges(
        mapElementManager,
        clonedMapElement,
      )),
    );
  }

  if (isEdge(clonedMapElement)) {
    updateEdgeLength(clonedMapElement);
    if (
      clonedMapElement.elementType === ElementType.ROAD_EDGE ||
      clonedMapElement.elementType === ElementType.CACHED_ROAD_EDGE
    ) {
      await updateRoadEdge(mapElementManager, clonedMapElement);
    }

    if (clonedMapElement.deleted) {
      const deletedStartNode = createDeletedNodeIfOrphan(
        mapElementManager,
        clonedMapElement.properties.startNodeId,
      );
      if (deletedStartNode) {
        change.push(deletedStartNode);
      }
      const deletedEndNode = createDeletedNodeIfOrphan(
        mapElementManager,
        clonedMapElement.properties.endNodeId,
      );
      if (deletedEndNode) {
        change.push(deletedEndNode);
      }
    }
  }
  return change;
}
