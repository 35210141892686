import { Component, OnDestroy } from '@angular/core';
import { MlDataService } from '../ml-data.service';
import { DataItem, DataViewerService, ItemType } from './data-viewer.service';
import { Subject, combineLatest, debounceTime, takeUntil } from 'rxjs';

import { MatDivider } from '@angular/material/divider';
import { SelectedItemComponent } from '../shared/selected-item.component';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { FrameDto } from '../ml-data-types';

@Component({
  selector: 'app-data-viewer-selection',
  templateUrl: './data-viewer-selection.component.html',
  styleUrls: ['./data-viewer-selection.component.sass'],
  standalone: true,
  imports: [MatDivider, SelectedItemComponent, MatIconButton, MatIcon],
})
export class DataViewerSelectionComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();

  selectedItem?: DataItem = undefined;
  metadataItem?: DataItem = undefined;
  previewImage?: string = undefined;
  previewVideo?: string = undefined;

  snippetFrames: FrameDto[] = [];

  private selectedSnippetId: number | undefined = undefined;

  constructor(
    private mlDataService: MlDataService,
    readonly dataViewerService: DataViewerService,
  ) {
    combineLatest([
      dataViewerService.selectedItemId$,
      dataViewerService.selectedDataViewerTab$,
    ])
      .pipe(takeUntil(this._destroy$), debounceTime(100))
      .subscribe(async ([selectedItemId, selectedDataViewerTab]) => {
        if (!selectedItemId) {
          this.selectedItem = undefined;
          this.snippetFrames = [];
          this.previewImage = undefined;
          this.previewVideo = undefined;
          return;
        }

        switch (selectedDataViewerTab) {
          case 'frames': {
            this.snippetFrames = [];
            const frame = await this.mlDataService.getFrameById(selectedItemId);
            this.selectedItem = frame;
            this.metadataItem = frame;
            this.previewVideo = undefined;
            this.previewImage = frame.url;
            break;
          }
          case 'snippets':
            this.setSnippetId(selectedItemId);
            break;
        }
      });
  }

  private async setSnippetId(snippetId: number): Promise<void> {
    this.selectedSnippetId = snippetId;
    this.snippetFrames = [];
    await this.setSnippetMetadata(snippetId);

    //This takes the longest so we do it last
    const snippetFrames =
      await this.mlDataService.getAllSnippetFrames(snippetId);
    // Since the above query can take a bit, we do a check here to ensure
    // that the user didnt switch snippets
    if (this.selectedSnippetId == snippetId) {
      this.snippetFrames = snippetFrames.map((frame) => ({
        ...frame,
        itemType: ItemType.FRAME,
      }));
    }
  }

  private async setSnippetMetadata(snippetId: number): Promise<void> {
    const snippet = await this.mlDataService.getSnippetById(snippetId);
    this.selectedItem = snippet;
    this.metadataItem = this.selectedItem;
    this.previewImage = undefined;
    this.previewVideo = this.selectedItem?.videoUrl;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  async selectItem(item: DataItem): Promise<void> {
    if (item.id === this.metadataItem?.id) {
      this.metadataItem = undefined;
      this.previewImage = undefined;
      this.previewVideo = undefined;

      this.selectedSnippetId &&
        (await this.setSnippetMetadata(this.selectedSnippetId));
      return;
    }
    this.metadataItem = item;
    // hacky way to distinguish between snippet and frame
    if ('videoUrl' in item) {
      this.previewImage = undefined;
      this.previewVideo = item.videoUrl as string;
    } else if ('url' in item) {
      this.previewImage = item.url;
      this.previewVideo = undefined;
    }
  }
}
