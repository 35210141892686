import { ElementType, OperationRegion } from '@cartken/map-types';
import { MapElementManager } from '../map-elements/map-element-manager';
import { DrawPolygonMode } from './draw-polygon-mode';
import { FeatureOf, Polygon } from '../visualization/geojson-types';
import { ViewContainerRef } from '@angular/core';
import { PropertiesContainerComponent } from '../properties/properties-container.component';
import { VisualizationManager } from '../visualization/visualization-manager';

export class CreateOperationRegionMode extends DrawPolygonMode {
  constructor(
    private mapElementManager: MapElementManager,
    visualizationManager: VisualizationManager,
  ) {
    super(visualizationManager);
  }

  override shouldRenderSidebar(): boolean {
    return this.mapElementManager.selectedMapElement() !== undefined;
  }

  override renderSidebar(ref: ViewContainerRef) {
    const componentRef = ref.createComponent(PropertiesContainerComponent);
    componentRef.instance.mapElementManager = this.mapElementManager;
    return componentRef;
  }

  override setActive(active: boolean) {
    super.setActive(active);
    if (active) {
      this.mapElementManager.selectElementById(undefined);
    }
  }

  protected override addPolygon(polygon: FeatureOf<Polygon>) {
    const operationRegion: OperationRegion = {
      id: this.mapElementManager.generateMapElementId(),
      elementType: ElementType.OPERATION_REGION,
      version: this.mapElementManager.baseMapElementsVersion() ?? 0,
      geometry: {
        type: 'Polygon',
        coordinates: polygon.geometry.coordinates,
      },
      properties: {
        operationId: '', // TODO: generate proper id here at some point
      },
    };

    this.mapElementManager.history.addChange([operationRegion]);
    this.mapElementManager.selectElementById(operationRegion.id);
  }
}
