import {
  RobotCardData,
  UserCardData,
} from './robot-operator-info-card.component';
import { User, UserSession } from '@/app/core/user';
import { millisBetween } from '@/utils/millis-between';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { determineRobotDrivingState } from '@/utils/robots';
import { ViewName } from '@/app/core/user-session/user-session.utils';

const DEFAULT_ROBOT_IMG_URL = 'assets/robot/model-c-profile-picture.png';
const MAX_ROBOT_SLOT_COUNT = 4;

export type OperatorStatus =
  | 'DisplayFocusedViewOperators'
  | 'DisplaySupervisionViewOperators'
  | 'DisplayMaintenanceViewOperators'
  | 'DisplayClockedInOperators'
  | 'DisplayOfflineOperators';

export function createUsersFilter(operatorStatusSet: Set<OperatorStatus>) {
  return (user: UserCardData) => {
    if (
      user.clockedInAt &&
      operatorStatusSet.has('DisplayClockedInOperators')
    ) {
      return true;
    }
    if (!isUserCardOnline(user)) {
      return operatorStatusSet.has('DisplayOfflineOperators');
    }
    switch (user.viewName) {
      case ViewName.FOCUSED_SUPERVISION:
        return operatorStatusSet.has('DisplayFocusedViewOperators');
      case ViewName.MANAGED_SUPERVISION:
        return operatorStatusSet.has('DisplaySupervisionViewOperators');
      case ViewName.CLASSIC_SUPERVISION:
        return operatorStatusSet.has('DisplayMaintenanceViewOperators');
      default:
        return false;
    }
  };
}

export function isUserCardOnline(user: UserCardData) {
  return (
    user.robots.filter((robot) => robot.robotId).length > 0 ||
    user.latencyMillis !== undefined
  );
}

export function getSpeedSum(session: UserSession) {
  if (session.traveledDistance === undefined || session.startAt === undefined) {
    return 0;
  }
  const sessionDurationMillis = millisBetween(
    new Date(session.startAt),
    new Date(session.lastUpdateAt),
  );
  const sessionDurationSeconds = sessionDurationMillis / 1000;
  const sessionDurationMinutes = sessionDurationSeconds / 60;
  const sessionDurationHours = sessionDurationMinutes / 60;
  const traveledDistanceInKm = session.traveledDistance / 1000;
  return traveledDistanceInKm / sessionDurationHours;
}

export function createRobotPlaceholder(isEnabled: boolean) {
  return {
    displayName: 'Cart ?',
    pictureUrl: DEFAULT_ROBOT_IMG_URL,
    isEnabledSlot: isEnabled,
    drivingState: 'UNKNOWN',
  };
}

function createSlotsForUnknownRobots(
  existingSlotCount: number,
  expectedRobotCount: number,
  enabledRobotSlotCount: number,
) {
  const unknownRobotSlotCount = expectedRobotCount - existingSlotCount;

  return unknownRobotSlotCount > 0
    ? Array(unknownRobotSlotCount)
        .fill(null)
        .map((_, index) => {
          const slotIndex = index + existingSlotCount;
          const isEnabled = slotIndex < enabledRobotSlotCount;
          return createRobotPlaceholder(isEnabled);
        })
    : [];
}

function createEmptyRobotSlots(
  existingSlotCount: number,
  isManagedSupervision: boolean,
  enabledRobotSlotCount: number,
) {
  const emptyRobotSlotsCount = isManagedSupervision
    ? MAX_ROBOT_SLOT_COUNT - existingSlotCount
    : 0;
  return emptyRobotSlotsCount > 0
    ? Array(emptyRobotSlotsCount)
        .fill(null)
        .map((_, index) => {
          const slotIndex = index + existingSlotCount;
          const isEnabled = slotIndex < enabledRobotSlotCount;
          return { isEnabledSlot: isEnabled };
        })
    : [];
}

export function createRobotCardData(
  user: User,
  robotToControllingUserMap: Map<string | undefined, Robot[]>,
  robotIdToRobotMap: Map<string, Robot>,
) {
  const enabledRobotSlotCount = user.session?.enabledRobotSlotCount ?? 0;
  const expectedRobotCount = user.session?.assignedRobots?.length ?? 0;

  const userRobots =
    user.session?.viewName === ViewName.MANAGED_SUPERVISION
      ? (user.session.assignedRobots
          ?.map((robotId) => robotIdToRobotMap.get(robotId))
          ?.filter((robot) => !!robot) ?? [])
      : (robotToControllingUserMap.get(user.id) ?? []);

  const controlledRobotsSlots = userRobots.map(
    (robot, index): RobotCardData => {
      return {
        displayName: `Cart ${robot.serialNumber}`,
        pictureUrl: robot.pictureUrl ?? DEFAULT_ROBOT_IMG_URL,
        isEnabledSlot: index < enabledRobotSlotCount,
        robotId: robot.id,
        drivingState: determineRobotDrivingState(robot, user),
        operationId: robot.assignedOperationId,
      };
    },
  );
  const unknownRobotSlots = createSlotsForUnknownRobots(
    controlledRobotsSlots.length,
    expectedRobotCount,
    enabledRobotSlotCount,
  );
  const emptyRobotSlotsCount = createEmptyRobotSlots(
    controlledRobotsSlots.length + unknownRobotSlots.length,
    user.session?.viewName === ViewName.MANAGED_SUPERVISION,
    enabledRobotSlotCount,
  );

  return [
    ...controlledRobotsSlots,
    ...unknownRobotSlots,
    ...emptyRobotSlotsCount,
  ];
}

export function compareOnlineTime(user1: UserCardData, user2: UserCardData) {
  const since1 = user1.since?.getTime() ?? 0;
  const since2 = user2.since?.getTime() ?? 0;
  return since2 - since1;
}

const DOWNTIME_ONLINE_THRESHOLD_MILLIS = 1000 * 60;

export function createUserCardFactory(
  robotToUserMap: Map<string | undefined, Robot[]>,
  robotIdToRobot: Map<string, Robot>,
  now: Date,
) {
  return (user: User): UserCardData => {
    const since = user.session?.startAt
      ? new Date(user.session.startAt)
      : undefined;
    const lastUpdateAt = new Date(user.session?.lastUpdateAt ?? 0);
    const millisBetweenUpdate = millisBetween(lastUpdateAt, now);
    const robotsControlledByUser = createRobotCardData(
      user,
      robotToUserMap,
      robotIdToRobot,
    );
    const viewName = user.session?.viewName;
    const version = user.session?.commitHash?.slice(0, 4) ?? 'old';
    if (
      viewName !== undefined &&
      millisBetweenUpdate < DOWNTIME_ONLINE_THRESHOLD_MILLIS
    ) {
      return {
        viewName,
        pictureUrl: user.pictureUrl,
        since,
        latencyMillis: user.session?.latencyMillis,
        displayName: user.displayName,
        robots: robotsControlledByUser!,
        speedSum: user.session ? getSpeedSum(user.session) : 0,
        selectedAccessGroups: user.session?.selectedAccessGroups ?? [],
        accessGroups: user.accessGroups,
        version,
        userId: user.id,
      };
    } else {
      return {
        pictureUrl: user.pictureUrl,
        since: robotsControlledByUser.length > 0 ? since : lastUpdateAt,
        viewName: undefined,
        displayName: user.displayName,
        robots: robotsControlledByUser,
        selectedAccessGroups: user.session?.selectedAccessGroups ?? [],
        version,
        accessGroups: user.accessGroups,
        userId: user.id,
      };
    }
  };
}

function isRobotDriving(robot: Robot): boolean {
  return robot.arrivedAtStop === false;
}

export function isRobotIdle(robot: Robot): boolean {
  return (
    robot?.scheduledStops?.length === 0 ||
    (!robot.readyForOrders &&
      !isRobotDriving(robot) &&
      !robot.assignedOrderIds.length)
  );
}
