<app-toolbar>
  <ng-content ngProjectAs="menu">
    <button mat-menu-item [routerLink]="''">Home</button>
  </ng-content>
</app-toolbar>

<div class="ops-grid">
  @for (operation of operations; track operation.id) {
    <app-operation-overview [operation]="operation" />
  }
</div>

<button class="add-button" mat-icon-button (click)="openAddOperationsDialog()">
  <mat-icon>add_task</mat-icon>
</button>
