import { inject, Injectable } from '@angular/core';
import { DataSortingOrder, MlDataService } from '../ml-data.service';
import {
  DataViewerStateService,
  DataViewerTabName,
} from './data-viewer-state.service';
import { BehaviorSubject, merge, throttleTime } from 'rxjs';
import { FrameDto, SnippetDto } from '../ml-data-types';
import { PageFetcher } from './page-fetchers/page-fetcher';
import { ConsistentRandomPageFetcher } from './page-fetchers/consistent-random-page-fetcher';
import { InOrderPageFetcher } from './page-fetchers/in-order-page-fetcher';

export enum ItemType {
  SNIPPET = 'snippet',
  FRAME = 'frame',
}

export type DataItem = FrameDto | SnippetDto;

function composeSearchString(
  searchString: string,
  modality: DataViewerTabName,
  loopedAround: boolean,
  initialRandomOffset: number,
  nextRandomOffset: number,
): string {
  let orderingFieldName: string;
  if (modality === 'frames') {
    orderingFieldName = 'frames.random_ordering_value';
  } else if (modality === 'snippets') {
    orderingFieldName = 'snippets.random_ordering_value';
  } else {
    throw Error(`Got unsupported modality: '${modality}'`);
  }

  const suffixCondition = loopedAround
    ? `${orderingFieldName} < ${initialRandomOffset} AND ${orderingFieldName} > ${nextRandomOffset}`
    : `${orderingFieldName} > ${nextRandomOffset}`;

  searchString =
    searchString.length > 0
      ? `(${searchString}) AND (${suffixCondition})`
      : suffixCondition;

  return searchString;
}

@Injectable()
export class DataViewerService {
  private dataViewerStateService = inject(DataViewerStateService);
  private readonly _canFetchMore$ = new BehaviorSubject<boolean>(true);
  private items: (SnippetDto | FrameDto)[] = [];

  private _isAnyItemsPicked$ = new BehaviorSubject<boolean>(false);
  readonly isAnyItemsPicked$ = this._isAnyItemsPicked$.asObservable();

  private pickedItems = new Set<number>();

  canFetchMore$ = this._canFetchMore$.asObservable();
  readonly items$ = new BehaviorSubject<DataItem[]>([]);

  private _selectedDataViewerTab$ = new BehaviorSubject<DataViewerTabName>(
    this.dataViewerStateService.getTabName(),
  );
  selectedDataViewerTab$ = this._selectedDataViewerTab$.asObservable();

  private _selectedSortingOrder$ = new BehaviorSubject<DataSortingOrder>(
    'newest',
  );
  selectedSortingOrder$ = this._selectedSortingOrder$.asObservable();

  private _selectedItemId$ = new BehaviorSubject<number | undefined>(undefined);
  selectedItemId$ = this._selectedItemId$.asObservable();

  private _searchString$ = new BehaviorSubject<string>('');
  searchString$ = this._searchString$.asObservable();

  pageFetcher: PageFetcher<DataItem> | undefined = undefined;

  constructor(mlDataService: MlDataService) {
    merge(
      this.searchString$,
      this.selectedDataViewerTab$,
      this.selectedSortingOrder$,
    )
      .pipe(throttleTime(100))
      .subscribe(async () => {
        const modality = this._selectedDataViewerTab$.value;
        const sortOrder = this._selectedSortingOrder$.value;
        this.items = [];
        this._canFetchMore$.next(true);
        if (sortOrder === 'random') {
          this.pageFetcher = new ConsistentRandomPageFetcher<DataItem>(
            async (loopedAround, initialRandomOffset, nextRandomOffset) => {
              const searchString = composeSearchString(
                this._searchString$.value,
                modality,
                loopedAround,
                initialRandomOffset,
                nextRandomOffset,
              );
              if (modality === 'frames') {
                return await mlDataService.getFrames(0, searchString, 'random');
              }
              if (modality === 'snippets') {
                return await mlDataService.getSnippets(
                  0,
                  searchString,
                  'random',
                );
              }
              throw new Error(`Unknown modality ${modality}`);
            },
          );
        } else {
          this.pageFetcher = new InOrderPageFetcher<DataItem>(async (page) => {
            if (modality === 'frames') {
              return await mlDataService.getFrames(
                page,
                this._searchString$.value,
                sortOrder,
              );
            }
            if (modality === 'snippets') {
              return await mlDataService.getSnippets(
                page,
                this._searchString$.value,
                sortOrder,
              );
            }
            throw new Error(`Unknown modality ${modality}`);
          });
        }

        this.getNextPage();
        this.pickedItems.clear();

        this._isAnyItemsPicked$.next(false);
      });
  }

  selectDataViewerTab(tabName: DataViewerTabName) {
    if (this._selectedDataViewerTab$.value !== tabName) {
      this.dataViewerStateService.setTabName(tabName);
      this._selectedDataViewerTab$.next(tabName);
      this._selectedItemId$.next(undefined);
      this._searchString$.next('');
    }
  }

  selectItemId(item: DataItem) {
    if (this._selectedItemId$.value === item.id) {
      this._selectedItemId$.next(undefined);
      return;
    }
    this._selectedItemId$.next(item.id);
  }

  setSortingOrder(order: DataSortingOrder) {
    this._selectedSortingOrder$.next(order);
  }

  setSearchString(searchString: string) {
    if (this._searchString$.value !== searchString) {
      this._searchString$.next(searchString);
    }
  }

  async getNextPage() {
    if (this.pageFetcher == undefined) {
      throw new Error('PageFetcher is undefined even though it shouldnt be');
    }

    const newItems = await this.pageFetcher?.step();
    if (newItems.length === 0) {
      this._canFetchMore$.next(false);
    }
    this.items = [...this.items, ...newItems];
    this.items$.next(this.items);
  }

  toggleItemPick(item: DataItem) {
    if (!this.pickedItems.has(item.id)) {
      this.pickedItems.add(item.id);
    } else {
      this.pickedItems.delete(item.id);
    }
    this._isAnyItemsPicked$.next(this.pickedItems.size > 0);
  }

  pickAllItems() {
    for (const item of this.items) {
      this.pickedItems.add(item.id);
    }
    this._isAnyItemsPicked$.next(true);
  }

  unpickAllItems() {
    this.pickedItems.clear();
    this._isAnyItemsPicked$.next(false);
  }

  isItemPicked(id: number) {
    return this.pickedItems.has(id);
  }
}
